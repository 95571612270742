export default function ModalLine({
  title,
  first,
  second,
  double = false,
  resumo = false,
}) {
  const col = double ? 'hc-col-full' : 'hc-col-content';
  const wd_title = resumo ? 'hc-col-title-resumo' : 'hc-col-title';
  return (
    <div className='m-0 p-0 d-flex flex-row align-items-center'>
      <p className={`m-0 p-0 ${wd_title}`}>{title}</p>
      <p className={`m-0 p-0 ${col}`}>{first}</p>
      {second ? <p className='m-0 p-0 hc-col-content'>{second}</p> : ''}
    </div>
  );
}
