import axios from 'axios';
import conf, { brand } from '../config/conf';

export const defaults = {
  headers: {
    Authorization: '',
  },
};

export async function post(endpoint, body) {
  const api = axios.create({
    baseURL: conf.ORA,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  let response;

  const token = sessionStorage.getItem('@App:token');
  api.defaults.headers.Authorization = token;
  body.token = token;
  body.brand = brand;
  response = await api.post(`${endpoint}${conf.slash}`, body);
  return response;
}

export async function postAPI(endpoint, body) {
  const api = axios.create({
    baseURL: conf.BWAPI,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  let response;

  const token = sessionStorage.getItem('@App:token');
  api.defaults.headers.Authorization = token;
  body.token = token;
  body.brand = brand;
  response = await api.post(`${endpoint}${conf.slash}`, body);
  return response;
}

export async function get(endpoint) {
  const api = axios.create({
    baseURL: conf.ORA,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  let response;

  const token = sessionStorage.getItem('@App:token');
  if (token) {
    api.defaults.headers.Authorization = token;
  }
  response = await api.get(`${endpoint}${conf.slash}`);
  return response;
}

export const postFiles = async (endpoint, body) => {
  const token = sessionStorage.getItem('@App:token');
  const apiFiles = axios.create({
    baseURL: conf.ORA,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  apiFiles.defaults.headers.Authorization = token;
  const response = await apiFiles.post(`${endpoint}${conf.slash}`, body);
  return response;
};
