import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/base/root.css';
import './assets/css/base/color.css';
import './assets/css/base/font.css';
import './assets/css/base/size.css';
import './assets/css/base/svg.css';
import './assets/css/style.css';

import {
  GlobalProvider,
  conf,
  Home,
  IconsExample,
  Login,
  Logout,
  Page404,
  Page500,
  Processo,
  Public,
  Recover,
  Register,
  Simulador,
  Telegram,
  TelegramApp,
  Thanks,
  User,
} from './config/imports';

function App() {
  useEffect(() => {
    const favicon =
      document.querySelector('link[rel="icon"]') ||
      document.createElement('link');
    favicon.rel = 'icon';
    favicon.type = 'image/x-icon';
    favicon.href = conf.favicon;
    document.head.appendChild(favicon);

    const favicon192 =
      document.querySelector('link[rel="apple-touch-icon"]') ||
      document.createElement('link');
    favicon192.rel = 'apple-touch-icon';
    favicon192.type = 'image/x-icon';
    favicon192.href = `/assets/img/brand/${conf.brand}/logo192.png`;
    document.head.appendChild(favicon192);

    document.title = conf.tenant;
  }, []);

  return (
    <GlobalProvider>
      <Routes>
        <Route
          path='/icons'
          element={<IconsExample />}
        />
        <Route
          path='/login'
          element={<Login />}
        />
        <Route
          path='/logout'
          element={<Logout />}
        />
        <Route
          path='/processo/:codigo'
          element={<Processo />}
        />
        <Route
          path='/recover'
          element={<Recover />}
        />
        <Route
          path='/register'
          element={<Register />}
        />
        <Route
          path='/thanks'
          element={<Thanks />}
        />
        <Route
          path='/botapp'
          element={<TelegramApp />}
        />
        <Route
          path='/bot/:renda'
          element={<Telegram />}
        />
        <Route
          path='/simulador'
          element={<Simulador />}
        />
        <Route
          path='/simule'
          element={<Simulador full={false} />}
        />
        <Route
          path='/user/:codigo'
          element={<User />}
        />
        <Route
          exact
          path='/app'
          element={<Public />}
        />
        <Route
          exact
          path='/'
          element={<Home />}
        />
        <Route
          exact
          path='/404'
          name='Page 404'
          element={<Page404 />}
        />
        <Route
          exact
          path='/500'
          name='Page 500'
          element={<Page500 />}
        />
        <Route
          path='*'
          element={<Page404 />}
        />
      </Routes>
    </GlobalProvider>
  );
}

export default App;
