const base = {
  processos: '/processos',
  andamentos: '/processo_andamentos',
  tasks: '/tasks',
  sim: '/simulacoes',
  proc_etapa: '/processo_etapa',
  support: '/support',
  users: '/users',
  filiais: '/filiais',
  cidades: '/cidades',
  operacoes: '/operacoes',
  sim_docs: '/simdocs',
  users_roles: '/users_roles',
  tenants: '/tenants',
};

const baseBWAPI = {
  mail: '/habita/mail',
  telegram: '/habita/telegram',
  wookie: '/habita/wookie',
}

export const auth = {
  login: '/auth/login',
  logout: '/auth/logout',
  authorized: '/auth/authorized',
  register: '/auth/register',
  recover: '/auth/recover',
  reset: '/auth/reset',
  user: '/auth/user',
  roles: '/auth/roles',
};

export const and = {
  create: {
    andamento: `${base.andamentos}/c/andamento`,
    doc: `${base.andamentos}/c/docs`,
    task: `${base.andamentos}/c/tasks`,
    agenda: `${base.andamentos}/c/agenda`,
    mensagem: `${base.andamentos}/c/mensagem`,
  },
  read: {
    all: `${base.andamentos}/r`,
    andamento: `${base.andamentos}/r/andamento`,
    docs: `${base.andamentos}/r/docs`,
    tasks: `${base.andamentos}/r/tasks`,
    agenda: `${base.andamentos}/r/agenda`,
    mensagem: `${base.andamentos}/r/mensagem`,
  },
  delete: `${base.andamentos}/d`,
  update: {
    title: `${base.andamentos}/u/title`,
    andamento: `${base.andamentos}/u/andamento`,
    nota: `${base.andamentos}/u/nota`,
    prazo: `${base.andamentos}/u/prazo`,
    prioridade: `${base.andamentos}/u/prioridade`,
    status: `${base.andamentos}/u/status`,
    visivel: `${base.andamentos}/u/visivel`,
    notificar: `${base.andamentos}/u/notificar`,
  },
};

export const proc = {
  create: `${base.processos}/c`,
  delete: `${base.processos}/d`,
  read: {
    codigo: `${base.processos}/r/codigo`,
    user: `${base.processos}/r/user`,
    id: `${base.processos}/r/id`,
    full: `${base.processos}/r/full`,
    corretor: `${base.processos}/r/corretor`,
    imobiliaria: `${base.processos}/r/imobiliaria`,
    correspondente: `${base.processos}/r/correspondente`,
  },
  update: {},
  find: `${base.processos}/find`,
};

export const user = {
  create: `${base.users}/c`,
  delete: `${base.users}/d`,
  read: {
    user: `${base.users}/r/codigo`,
    gestao: `${base.users}/r/gestao`,
    roles: `${base.users}/r/roles/codigo`,
  },
  update: {
    role: `${base.users}/u/role`,
    status: `${base.users}/u/status`,
    password: `${base.users}/u/password`,
    active: `${base.users}/u/active`,
    telefone: `${base.users}/u/telefone`,
    email: `${base.users}/u/email`,
    nome: `${base.users}/u/nome`,
    cpf: `${base.users}/u/cpf`,
  },
};

export const users_roles = {
  create: `${base.users_roles}/c`,
  delete: `${base.users_roles}/d`,
  update: {
    active: `${base.users_roles}/u/active`,
  },
  read: `${base.users_roles}/r/user_codigo`,
};

export const cidades = {
  create: `${base.cidades}/c`,
  delete: `${base.cidades}/d`,
  read: `${base.cidades}/r`,
  update: {},
};

export const operacoes = {
  create: `${base.operacoes}/c`,
  delete: `${base.operacoes}/d`,
  read: `${base.operacoes}/r`,
  update: {},
};

export const proc_et = {
  read: `${base.proc_etapa}/r`,
  update: {
    done: `${base.proc_etapa}/u/done`,
  },
};

export const filiais = {
  create: `${base.filiais}/c`,
  delete: `${base.filiais}/d`,
  read: `${base.filiais}/r`,
  update: {
    active: `${base.filiais}/u/active`,
  },
};

export const sim = {
  create: `${base.sim}/c`,
  delete: `${base.sim}/d`,
  read: `${base.sim}/r`,
  update: {
    anotacoes: `${base.sim}/u/anotacoes`,
    active: `${base.sim}/u/active`,
  },
  mail: `${base.sim}/mail`,
  find: `${base.sim}/find`,
  all: `${base.sim}/all`,
};

export const simBWAPI = {
  mail: `${baseBWAPI.mail}/simulacao`,
};

export const telegramAPI = {
  notify_admin: `${baseBWAPI.telegram}/api/notify_admin`,
};

export const sim_docs = {
  create: `${base.sim_docs}/c`,
  delete: `${base.sim_docs}/d`,
  read: {
    doc: `${base.sim_docs}/r/doc`,
    all: `${base.sim_docs}/r/all`,
  },
};

export const task = {
  create: `${base.tasks}/c`,
  read: `${base.tasks}/r`,
  delete: `${base.tasks}/d`,
  update: {
    title: `${base.tasks}/u/title`,
    andamento: `${base.tasks}/u/andamento`,
    prazo: `${base.tasks}/u/prazo`,
    prioridade: `${base.tasks}/u/prioridade`,
    status: `${base.tasks}/u/status`,
  },
};

export const support = {
  create: `${base.support}/c`,
  email: `${base.support}/ticket`,
  read: `${base.support}/r`,
  delete: `${base.support}/d`,
  update: {
    title: `${base.support}/u/title`,
    andamento: `${base.support}/u/andamento`,
    prazo: `${base.support}/u/prazo`,
    prioridade: `${base.support}/u/prioridade`,
    status: `${base.support}/u/status`,
  },
};

export const tenants = {
  logo_link: `${base.tenants}/logo_link`,
};

export const apoio = '/v/apoio';
export const latest = '/v/latest';
export const monthes = '/v/monthes';
export const numbs = '/v/numbers';
