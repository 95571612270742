import { BiSolidMessageRoundedEdit } from 'react-icons/bi';
import { FaCalendarPlus } from 'react-icons/fa';
import { GoTasklist } from 'react-icons/go';
import { MdOutlineUploadFile } from 'react-icons/md';
import { RiMenuAddLine } from 'react-icons/ri';

function AndamentoIcon({ and }) {
  switch (and) {
    case 1:
      return <RiMenuAddLine className='svg12' />;
    case 2:
      return <MdOutlineUploadFile className='svg12' />;
    case 3:
      return <GoTasklist className='svg12' />;
    case 4:
      return <BiSolidMessageRoundedEdit className='svg12' />;
    case 5:
      return <FaCalendarPlus className='svg12' />;
    default:
      return <RiMenuAddLine className='svg12' />;
  }
}

export default AndamentoIcon;
