export const numberFormat = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);

export const numberReFormat = (value) => {
  let newValue = value.toString().replace('R$ ', '');
  let noDots = newValue.replaceAll('.', '');
  let noComma = noDots.replace(',', '.');
  return Number(noComma);
};

export const mascaraMoeda = (value) => {
  const reversed = (val) => val.split('').reverse().join('');
  let valor = reversed(value.toString().replace(/[^\d]+/gi, ''));
  let resultado = '';
  let mascara = reversed('###.###.###.###,##');
  for (let x = 0, y = 0; x < mascara.length && y < valor.length; ) {
    if (mascara.charAt(x) !== '#') {
      resultado += mascara.charAt(x);
      x++;
    } else {
      resultado += valor.charAt(y);
      y++;
      x++;
    }
  }
  value = reversed(resultado);
  return `R$ ${value}`;
};

export function meses_70(dataNascimento) {
  let partesData = dataNascimento.split('/');
  let diaNascimento = parseInt(partesData[0]);
  let mesNascimento = parseInt(partesData[1]) - 1;
  let anoNascimento = parseInt(partesData[2]);

  dataNascimento = new Date(anoNascimento, mesNascimento, diaNascimento);
  let dataAtual = new Date();

  let diferencaMilissegundos = dataAtual - dataNascimento;

  let anos = Math.floor(
    diferencaMilissegundos / (365.25 * 24 * 60 * 60 * 1000),
  );
  let meses = Math.floor(
    (diferencaMilissegundos % (365.25 * 24 * 60 * 60 * 1000)) /
      (30.4375 * 24 * 60 * 60 * 1000),
  );

  let mesesPara70 = 70 * 12 - (anos * 12 + meses);

  return mesesPara70;
}

export function not_blank(value) {
  let res = value.trim();
  return res !== '' && res !== null;
}

export function is_valid_email(email) {
  let re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function is_valid_cpf(cpf) {
  let soma;
  let resto;
  soma = 0;
  cpf = cpf.replace(/\D/g, '');
  if (cpf === '00000000000') return false;

  for (let i = 1; i <= 9; i++)
    soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;
  return true;
}

export function is_compound_name(name) {
  let re =
    /(^[A-Za-z]{3,16})([ ]{0,1})([A-Za-z]{3,16})?([ ]{0,1})?([A-Za-z]{3,16})?([ ]{0,1})?([A-Za-z]{3,16})/;
  return re.test(name);
}

export const valid_ddd = {
  11: 'São Paulo',
  12: 'São José dos Campos',
  13: 'Santos',
  14: 'Bauru',
  15: 'Sorocaba',
  16: 'Ribeirão Preto',
  17: 'São José do Rio Preto',
  18: 'Presidente Prudente',
  19: 'Campinas',
  21: 'Rio de Janeiro',
  22: 'Campos dos Goytacazes',
  24: 'Volta Redonda',
  27: 'Vitória',
  28: 'Cachoeiro de Itapemirim',
  31: 'Belo Horizonte',
  32: 'Juiz de Fora',
  33: 'Governador Valadares',
  34: 'Uberlândia',
  35: 'Poços de Caldas',
  37: 'Divinópolis',
  38: 'Montes Claros',
  41: 'Curitiba',
  42: 'Ponta Grossa',
  43: 'Londrina',
  44: 'Maringá',
  45: 'Foz do Iguaçu',
  46: 'Cascavel',
  47: 'Joinville',
  48: 'Florianópolis',
  49: 'Chapecó',
  51: 'Porto Alegre',
  53: 'Pelotas',
  54: 'Caxias do Sul',
  55: 'Santa Maria',
  61: 'Brasília',
  62: 'Goiânia',
  63: 'Palmas',
  64: 'Rio Verde',
  65: 'Cuiabá',
  66: 'Rondonópolis',
  67: 'Campo Grande',
  68: 'Rio Branco',
  69: 'Porto Velho',
  71: 'Salvador',
  73: 'Ilhéus',
  74: 'Juazeiro',
  75: 'Feira de Santana',
  77: 'Barreiras',
  79: 'Aracaju',
  81: 'Recife',
  82: 'Maceió',
  83: 'João Pessoa',
  84: 'Natal',
  85: 'Fortaleza',
  86: 'Teresina',
  87: 'Petrolina',
  88: 'Juazeiro do Norte',
  89: 'Picos',
  91: 'Belém',
  92: 'Manaus',
  93: 'Santarém',
  94: 'Marabá',
  95: 'Macapá',
  96: 'Porto Trombetas',
  97: 'Coari',
  98: 'São Luís',
  99: 'Imperatriz',
};

export function is_valid_phone(phone) {
  let ddd = phone.substring(1, 3);
  if (!valid_ddd[ddd]) return false;
  let re = /\(\d{2}\) \d{4,5}-\d{4}/;
  return re.test(phone);
}

export function is_valid_nascimento(nascimento) {
  let partesData = nascimento.split('/');
  let diaNascimento = parseInt(partesData[0]);
  let mesNascimento = parseInt(partesData[1]);
  let anoNascimento = parseInt(partesData[2]);
  let anoAtual = new Date().getFullYear();
  if (anoNascimento < 1900 || anoNascimento > anoAtual - 18) return false;
  if (mesNascimento < 1 || mesNascimento > 12) return false;
  if (diaNascimento < 1 || diaNascimento > 31) return false;
  if (mesNascimento === 2 && diaNascimento > 29) return false;
  if (
    (mesNascimento === 4 ||
      mesNascimento === 6 ||
      mesNascimento === 9 ||
      mesNascimento === 11) &&
    diaNascimento > 30
  )
    return false;
  if (meses_70(nascimento) < 0) return false;
  return true;
}
