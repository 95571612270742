import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { IMaskInput } from 'react-imask';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import '../assets/css/app/simulacao.css';

import {
  api,
  end,
  conf,
  FormHeader,
  utils,
  LoadingDots,
  FormInvalidFeedback,
  helpers,
} from '../config/imports';

function Simulador({ full = true, showlogo = true }) {
  const {
    is_valid_phone,
    is_compound_name,
    is_valid_cpf,
    is_valid_email,
    is_valid_nascimento,
    mascaraMoeda,
    numberReFormat,
  } = helpers;
  const { cidades, operacoes, sim, tenants, simBWAPI } = end;
  const { get, post } = api;
  const { data_int } = utils;
  const imovel_descricao = {
    1: 'Aquisição de Imóvel Novo',
    2: 'Construção',
    3: 'Reforma ou Ampliação',
    4: 'Aquisição de Imóvel Usado',
    6: 'Aquisição de Terreno',
    7: 'Empréstimo Garantido por Imóvel',
    11: 'Imóveis CAIXA',
  };

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [simulacao, setSimulacao] = useState({
    simulacao_completa: full ? 1 : 0,
    imovel_valor: '',
    imovel_cidade: 'TAUBATE',
    imovel_codigo: '',
    imovel_descricao: '',
    beneficiado: '',
    servidor_publico: '',
    anos_fgts: '',
    dependentes: '',
    clt: '',
    possui_imovel: '',
    possui_imovel_cidade: '',
    corretor: '',
    imobiliaria: '',
    compor: 0,
    relacionamento: '',
    whatsapp: '',
    estado_civil: '',
    email: '',
    nome: '',
    nascimento: '',
    cpf: '',
    renda: '',
    fgts: '',
    recursos: '',
    comprovar: [],
    outro_nome: '',
    outro_nascimento: '',
    outro_cpf: '',
    outro_renda: '',
    outro_fgts: '',
    outro_recursos: '',
    outro_comprovar: [],
    informacoes: '',
    termos: 0,
  });

  const [simulacaoHid, setSimulacaoHid] = useState({
    imovel_valor: 0,
    renda: 0,
    fgts: 0,
    recursos: 0,
    outro_renda: 0,
    outro_fgts: 0,
    outro_recursos: 0,
  });

  const [cities, setCities] = useState([]);
  const [operations, setOperations] = useState([]);

  const get_info = async () => {
    try {
      const [citiesResponse, operationsResponse] = await Promise.all([
        get(cidades.read, {}),
        get(operacoes.read, {}),
      ]);

      const { data: citiesData } = citiesResponse;
      const { data: operationsData } = operationsResponse;

      setCities(citiesData.items);
      setOperations(operationsData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    get_info();
    // eslint-disable-next-line
  }, []);

  const updateSimulacaoState = (key, value) => {
    setSimulacao({ ...simulacao, [key]: value });
  };

  const handleValor = (event) => {
    const element = document.getElementById(event.target.id);
    element.value = mascaraMoeda(element.value);
    setSimulacao({
      ...simulacao,
      [event.target.id]: mascaraMoeda(element.value),
    });
    setSimulacaoHid({
      ...simulacaoHid,
      [event.target.id]: numberReFormat(element.value),
    });
  };

  const handleImovelTipo = (event) => {
    setSimulacao({
      ...simulacao,
      [event.target.name]: parseInt(event.target.value),
      [event.target.dataset.descr]: event.target.dataset.text,
    });
  };

  const handleEstadoCivil = (event) => {
    const compor = event.target.value === 'Casado(a)' ? 1 : 0;
    setSimulacao({
      ...simulacao,
      [event.target.id]: event.target.value,
      compor,
    });
    document.getElementById('compor').disabled = compor;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    setSimulacao({
      ...simulacao,
      anos_fgts: parseInt(simulacao.anos_fgts),
      dependentes: parseInt(simulacao.dependentes),
      clt: parseInt(simulacao.clt),
      possui_imovel: parseInt(simulacao.possui_imovel),
      beneficiado: parseInt(simulacao.beneficiado),
      servidor_publico: parseInt(simulacao.servidor_publico),
      imovel_descricao: imovel_descricao[simulacao.imovel_codigo],
    });

    try {
      const [simulacaoCreate, tenantLogoLink] = await Promise.all([
        await post(sim.create, {
          ...simulacao,
          nascimento: data_int(simulacao.nascimento),
          outro_nascimento:
            full && simulacao.compor
              ? data_int(simulacao.outro_nascimento)
              : null,
          imovel_descricao: imovel_descricao[simulacao.imovel_codigo],
          comprovar: simulacao.comprovar.toString(),
          outro_comprovar: simulacao.outro_comprovar.toString() || null,
          imovel_valor: simulacaoHid.imovel_valor,
          renda: simulacaoHid.renda,
          fgts: simulacaoHid.fgts,
          recursos: simulacaoHid.recursos,
          outro_renda: simulacaoHid.outro_renda,
          outro_fgts: simulacaoHid.outro_fgts,
          outro_recursos: simulacaoHid.outro_recursos,
        }),

        await post(tenants.logo_link, {
          brand: conf.brand,
        }),
      ]);

      const { data: response } = simulacaoCreate;
      const { data: logoLink } = tenantLogoLink;

      const formData = new FormData();

      // add all the data to the formData

      Object.keys(simulacao).forEach((key) => {
        formData.append(key, simulacao[key]);
      });
     
      formData.set('imovel_valor', simulacaoHid.imovel_valor);
      formData.set('renda', simulacaoHid.renda);
      formData.set('fgts', simulacaoHid.fgts);
      formData.set('recursos', simulacaoHid.recursos);
      formData.set('outro_renda', simulacaoHid.outro_renda);
      formData.set('outro_fgts', simulacaoHid.outro_fgts);
      formData.set('outro_recursos', simulacaoHid.outro_recursos);

      formData.append('logo_link', logoLink);
      formData.append('brand', conf.brand);

      await api.postAPI(simBWAPI.mail, formData);

      const { error, error_message } = response;

      if (!error) {
        navigate('/thanks');
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error_message,
        });
      }
    } catch (error) {
      console.log(error);
    }
    // }

    setLoading(false);
  };

  if (loading) return <LoadingDots class_op='mt-5' />;

  return (
    <div className='wrapper my-5'>
      <header className='d-flex flex-column text-center'>
        {showlogo ? (
          <FormHeader
            formname={'Preencha os dados abaixo para realizar sua simulação'}
          />
        ) : (
          ''
        )}
        <p className='f08 softxt wp-400 m-auto fc-blue text-center'>
          Os dados abaixo serão enviados para a nossa equipe, que realizará as
          simulações internamente e entrará em contato pelo Whatsapp para lhe
          enviar as informações e tirar suas dúvidas. Preencha corretamente
          todos os campos.
        </p>
        <p className='mt-3 f09'>
          Os campos marcados com <span className='lab-sp-required'>*</span> são
          obrigatórios
        </p>
      </header>
      <div className='w80 mw600'>
        <Form
          // validated={validated}
          needs-validation
          onSubmit={handleSubmit}
          autoComplete='off'
        >
          <p className='mt-5 mb-2 f09 fc-blue softxt'>Sobre Você</p>
          {/* nome */}
          <Form.Group className='mb-3'>
            <Form.Label
              htmlFor='nome'
              className='input-label'
            >
              Nome completo <span className='lab-sp-required'>*</span>
            </Form.Label>
            <Form.Control
              type='text'
              id='nome'
              name='nome'
              // pattern='(^[A-Za-z]{3,16})([ ]{0,1})([A-Za-z]{3,16})?([ ]{0,1})?([A-Za-z]{3,16})?([ ]{0,1})?([A-Za-z]{3,16})'
              // autoComplete='name'
              required
              value={simulacao.nome}
              aria-describedby='nome'
              onChange={(e) => {
                if (!is_compound_name(e.target.value)) {
                  e.target.setCustomValidity('Informe seu nome completo');
                  e.target.classList.add('is-invalid');
                } else {
                  e.target.setCustomValidity('');
                  e.target.classList.remove('is-invalid');
                }
                updateSimulacaoState(e.target.id, e.target.value);
              }}
              onBlur={(e) => {
                if (!is_compound_name(e.target.value)) {
                  e.target.setCustomValidity('Informe seu nome completo');
                  e.target.classList.add('is-invalid');
                } else {
                  e.target.setCustomValidity('');
                  e.target.classList.remove('is-invalid');
                }
              }}
            />
            <FormInvalidFeedback />
          </Form.Group>
          {/* email */}
          <Form.Group className='mb-3'>
            <Form.Label
              htmlFor='email'
              className='input-label'
            >
              E-mail <span className='lab-sp-required'>*</span>
            </Form.Label>
            <Form.Control
              type='email'
              id='email'
              name='email'
              // autoComplete='email'
              required
              value={simulacao.email}
              aria-describedby='email'
              onChange={(e) => {
                if (!is_valid_email(e.target.value)) {
                  e.target.setCustomValidity('Informe seu e-mail corretamente');
                  e.target.classList.add('is-invalid');
                } else {
                  e.target.setCustomValidity('');
                  e.target.classList.remove('is-invalid');
                }
                updateSimulacaoState(e.target.id, e.target.value);
              }}
            />
            <FormInvalidFeedback />
          </Form.Group>

          {/* whatsapp */}
          <Form.Group className='mb-3 '>
            <Form.Label
              htmlFor='whatsapp'
              className='input-label'
            >
              WhatsApp <span className='lab-sp-required'>*</span>
            </Form.Label>
            <Form.Control
              type='text'
              as={IMaskInput}
              mask='(00) 00000-0000'
              id='whatsapp'
              name='whatsapp'
              required
              value={simulacao.whatsapp}
              onChange={(e) => {
                if (!is_valid_phone(e.target.value)) {
                  e.target.setCustomValidity(
                    'Informe um número de WhatsApp válido',
                  );
                  e.target.classList.add('is-invalid');
                } else {
                  e.target.setCustomValidity('');
                  e.target.classList.remove('is-invalid');
                }
                updateSimulacaoState(e.target.id, e.target.value);
              }}
              onAccept={(value) => {
                updateSimulacaoState('whatsapp', value);
              }}
            />
            <FormInvalidFeedback />
          </Form.Group>
          <Form.Group className='mb-3 '>
            <Form.Label
              htmlFor='cpf'
              className='input-label'
            >
              CPF <span className='lab-sp-required'>*</span>
            </Form.Label>
            <Form.Control
              type='text'
              as={IMaskInput}
              mask='000.000.000-00'
              id='cpf'
              // autoComplete='cpf'
              name='cpf'
              required
              value={simulacao.cpf}
              minLength={14}
              maxLength={14}
              aria-describedby='cpf'
              onChange={(e) => {
                if (!is_valid_cpf(e.target.value)) {
                  e.target.setCustomValidity('Informe um CPF válido');
                  e.target.classList.add('is-invalid');
                } else {
                  e.target.setCustomValidity('');
                  e.target.classList.remove('is-invalid');
                }
                updateSimulacaoState(e.target.id, e.target.value);
              }}
              onAccept={(value) => {
                let e = document.getElementById('cpf');
                if (!is_valid_cpf(e.value)) {
                  e.setCustomValidity('Informe um CPF válido');
                  e.classList.add('is-invalid');
                } else {
                  e.setCustomValidity('');
                  e.classList.remove('is-invalid');
                }
                updateSimulacaoState('cpf', value);
              }}
            />
            <FormInvalidFeedback />
          </Form.Group>

          {/* nascimento */}
          <Form.Group className='mb-3'>
            <Form.Label
              htmlFor='nascimento'
              className='input-label'
            >
              {full
                ? 'Data de nascimento'
                : 'Data de nascimento do participante mais velho, caso haja mais de uma pessoa na simulação'}
              <span className='lab-sp-required'>*</span>
            </Form.Label>
            <Form.Control
              type='text'
              as={IMaskInput}
              mask='00/00/0000'
              id='nascimento'
              name='nascimento'
              required
              value={simulacao.nascimento}
              onChange={(e) => {
                if (!is_valid_nascimento(e.target.value)) {
                  e.target.setCustomValidity(
                    'Informe a data de nascimento corretamente',
                  );
                  e.target.classList.add('is-invalid');
                } else {
                  e.target.setCustomValidity('');
                  e.target.classList.remove('is-invalid');
                }
                updateSimulacaoState(e.target.id, e.target.value);
              }}
              onAccept={(value) => {
                let e = document.getElementById('nascimento');
                if (!is_valid_nascimento(value)) {
                  e.setCustomValidity(
                    'Informe a data de nascimento corretamente',
                  );
                  e.classList.add('is-invalid');
                } else {
                  e.setCustomValidity('');
                  e.classList.remove('is-invalid');
                }
                updateSimulacaoState('nascimento', value);
              }}
            />
            <FormInvalidFeedback />
          </Form.Group>

          {/* estado_civil */}
          <Form.Group className='mb-3'>
            <Form.Label
              htmlFor='estado_civil'
              className='input-label'
            >
              Estado Civil <span className='lab-sp-required'>*</span>
            </Form.Label>
            <Form.Select
              id='estado_civil'
              name='estado_civil'
              required
              value={simulacao.estado_civil}
              onChange={(e) => handleEstadoCivil(e)}
            >
              <option value=''>Selecione</option>
              <option value='Solteiro(a)'>Solteiro(a)</option>
              <option value='Casado(a)'>Casado(a)</option>
              <option value='Divorciado(a)'>Divorciado(a)</option>
              <option value='Viúvo(a)'>Viúvo(a)</option>
              <option value='União Estável'>União Estável</option>
            </Form.Select>
            <FormInvalidFeedback />
          </Form.Group>

          <p className='mt-5 mb-2 f09 fc-blue softxt'>
            Sobre o seu futuro imóvel
          </p>
          {/* valor do imovel */}
          <Form.Group className='mb-3'>
            <Form.Label
              htmlFor='imovel_valor'
              className='input-label'
            >
              Valor do imóvel <span className='lab-sp-required'>*</span>
            </Form.Label>
            <Form.Control
              // type="number"
              step={'0.1'}
              id='imovel_valor'
              name='imovel_valor'
              type='text'
              required
              onChange={(e) => handleValor(e)}
              onKeyUp={(e) => handleValor(e)}
              // value={simulacao.imovel_valor}
              // onChange={(e) =>
              //   updateSimulacaoState(e.target.id, e.target.value)
              // }
            />
            <FormInvalidFeedback />
          </Form.Group>
          {/* imovel_cidade */}
          {cities.length > 0 ? (
            <Form.Group className='mb-3'>
              <Form.Label
                htmlFor='imovel_cidade'
                className='input-label'
              >
                Cidade do imóvel <span className='lab-sp-required'>*</span>
              </Form.Label>
              <Form.Select
                id='imovel_cidade'
                name='imovel_cidade'
                required
                value={simulacao.imovel_cidade}
                onChange={(e) => {
                  if (e.target.value === '') {
                    e.target.setCustomValidity('Informe a cidade do imóvel');
                    e.target.classList.add('is-invalid');
                  } else {
                    e.target.setCustomValidity('');
                    e.target.classList.remove('is-invalid');
                  }

                  updateSimulacaoState(e.target.id, e.target.value);
                }}
              >
                <option value=''>Selecione</option>
                {cities.map((city) => (
                  <option
                    key={city.codigo_cef}
                    value={city.nome}
                  >
                    {city.nome}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback
                type='invalid'
                className='mt-0 f08'
              >
                Informe a cidade do imóvel
              </Form.Control.Feedback>
            </Form.Group>
          ) : (
            ''
          )}

          {operations.length > 0 ? (
            <Form.Group className='mb-3'>
              <Form.Label className='input-label'>
                Tipo de Financiamento <span className='lab-sp-required'>*</span>
              </Form.Label>
              {operations.map((operation) => (
                <Form.Check
                  key={`${operation.C}_${operation.OPERACAO}`}
                  type='radio'
                  id={operation.C}
                  name='imovel_codigo'
                  data-descr='imovel_descricao'
                  data-text={operation.OPERACAO}
                  label={operation.OPERACAO}
                  value={operation.C}
                  required
                  onChange={(e) => handleImovelTipo(e)}

                  // updateSimulacaoState(e.target.name, e.target.value);
                  // updateSimulacaoState('imovel_descricao', e.target.dataset.text);
                />
              ))}

              <Form.Control.Feedback
                type='invalid'
                className='mt-0 f08'
              >
                Informe o tipo de imóvel
              </Form.Control.Feedback>
            </Form.Group>
          ) : (
            ''
          )}

          <Form.Group className='mb-3'>
            <Form.Label
              htmlFor='corretor'
              className='input-label'
            >
              Corretor
            </Form.Label>
            <Form.Control
              type='text'
              id='corretor'
              name='corretor'
              value={simulacao.corretor}
              onChange={(e) =>
                updateSimulacaoState(e.target.id, e.target.value)
              }
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label
              htmlFor='imobiliaria'
              className='input-label'
            >
              Imobiliária
            </Form.Label>
            <Form.Control
              type='text'
              id='imobiliaria'
              name='imobiliaria'
              value={simulacao.imobiliaria}
              onChange={(e) =>
                updateSimulacaoState(e.target.id, e.target.value)
              }
            />
          </Form.Group>

          <p className='mt-5 mb-2 f09 fc-blue softxt'>
            Perfil de Financiamento
          </p>

          <Form.Group className='mb-3'>
            <Form.Label
              htmlFor='renda'
              className='input-label'
            >
              Renda bruta mensal <span className='lab-sp-required'>*</span>
            </Form.Label>
            <Form.Control
              // type="number"
              step={'0.1'}
              id='renda'
              name='renda'
              type='text'
              required
              onChange={(e) => handleValor(e)}
              onKeyUp={(e) => handleValor(e)}
            />
            <Form.Control.Feedback
              type='invalid'
              className='mt-0 f08'
            >
              Renda bruta mensal (para composição de renda, somar as rendas
              brutas)
            </Form.Control.Feedback>
          </Form.Group>

          {/* comprovar */}
          <Form.Group className='mb-3'>
            <Form.Label className='input-label'>
              Comprovação de renda <span className='lab-sp-required'>*</span>{' '}
              (selecione uma ou mais opções)
            </Form.Label>
            <Form.Check
              type='checkbox'
              id='comprovar_1'
              name='comprovar'
              label='Holerite'
              value='Holerite'
              checked={simulacao.comprovar.includes('Holerite')}
              onChange={(e) => {
                let comprovar = simulacao.comprovar;
                if (e.target.checked ? 1 : 0) {
                  comprovar.push(e.target.value);
                  updateSimulacaoState('comprovar', comprovar);
                } else {
                  comprovar = comprovar.filter(
                    (item) => item !== e.target.value,
                  );
                }
                updateSimulacaoState('comprovar', comprovar);
              }}
            />
            <Form.Check
              type='checkbox'
              id='comprovar_2'
              name='comprovar'
              label='Extrato bancário'
              value='Extrato bancário'
              checked={simulacao.comprovar.includes('Extrato bancário')}
              onChange={(e) => {
                let comprovar = simulacao.comprovar;
                if (e.target.checked ? 1 : 0) {
                  comprovar.push(e.target.value);
                  updateSimulacaoState('comprovar', comprovar);
                } else {
                  comprovar = comprovar.filter(
                    (item) => item !== e.target.value,
                  );
                }
                updateSimulacaoState('comprovar', comprovar);
              }}
            />
            <Form.Check
              type='checkbox'
              id='comprovar_3'
              name='comprovar'
              label='Declaração de Imposto de Renda'
              value='Declaração de Imposto de Renda'
              checked={simulacao.comprovar.includes(
                'Declaração de Imposto de Renda',
              )}
              onChange={(e) => {
                let comprovar = simulacao.comprovar;
                if (e.target.checked ? 1 : 0) {
                  comprovar.push(e.target.value);
                  updateSimulacaoState('comprovar', comprovar);
                } else {
                  comprovar = comprovar.filter(
                    (item) => item !== e.target.value,
                  );
                }
                updateSimulacaoState('comprovar', comprovar);
              }}
            />
            <Form.Check
              type='checkbox'
              id='comprovar_4'
              name='comprovar'
              label='Pró Labore'
              value='Pró Labore'
              checked={simulacao.comprovar.includes('Pró Labore')}
              onChange={(e) => {
                let comprovar = simulacao.comprovar;
                if (e.target.checked ? 1 : 0) {
                  comprovar.push(e.target.value);
                  updateSimulacaoState('comprovar', comprovar);
                } else {
                  comprovar = comprovar.filter(
                    (item) => item !== e.target.value,
                  );
                }
                updateSimulacaoState('comprovar', comprovar);
              }}
            />
            <Form.Check
              type='checkbox'
              id='comprovar_5'
              name='comprovar'
              label='Não tenho como comprovar'
              value='Não tenho como comprovar'
              checked={simulacao.comprovar.includes('Não tenho como comprovar')}
              onChange={(e) => {
                let comprovar = simulacao.comprovar;
                if (e.target.checked ? 1 : 0) {
                  comprovar.push(e.target.value);
                  updateSimulacaoState('comprovar', comprovar);
                } else {
                  comprovar = comprovar.filter(
                    (item) => item !== e.target.value,
                  );
                }
                updateSimulacaoState('comprovar', comprovar);
              }}
            />
          </Form.Group>
          {/* fgts */}
          <Form.Group className='mb-3'>
            <Form.Label
              htmlFor='fgts'
              className='input-label'
            >
              Informe o saldo aproximado de FGTS que pretende utilizar{' '}
              <span className='lab-sp-required'>*</span>
              <br />
              <span className='f09'>Caso não possua, informe 0</span>
            </Form.Label>
            <Form.Control
              // type="number"
              // step={'0.1'}
              id='fgts'
              name='fgts'
              type='text'
              required
              onChange={(e) => handleValor(e)}
              onKeyUp={(e) => handleValor(e)}
            />
            <Form.Control.Feedback
              type='invalid'
              className='mt-0 f08'
            >
              Informe o saldo aproximado de FGTS que pretende utilizar
            </Form.Control.Feedback>
          </Form.Group>

          {/* recursos */}
          <Form.Group className='mb-3'>
            <Form.Label className='input-label'>
              Informe o saldo de outros recursos próprios (exceto FGTS){' '}
              <span className='lab-sp-required'>*</span>
              <br />
              <span className='f09'>Caso não possua, informe 0</span>
            </Form.Label>
            <Form.Control
              // type="number"
              // step={'0.1'}
              id='recursos'
              name='recursos'
              type='text'
              required
              onChange={(e) => handleValor(e)}
              onKeyUp={(e) => handleValor(e)}
            />
          </Form.Group>

          {/* compor */}
          <Form.Group className='mb-3'>
            <Form.Check
              type='checkbox'
              id='compor'
              required={simulacao.estado_civil.includes('Casado') ? 1 : 0}
              name='compor'
              checked={
                simulacao.estado_civil.includes('Casado') || simulacao.compor
              }
              className='mb-0'
              label={
                simulacao.estado_civil.includes('Casado') ? (
                  <span>
                    Deseja compor renda com mais uma pessoa?
                    <span className='lab-sp-required'>
                      {' '}
                      * (obrigatório para casados)
                    </span>
                  </span>
                ) : (
                  'Deseja compor renda com mais uma pessoa?'
                )
              }
              onChange={(e) =>
                updateSimulacaoState(e.target.id, e.target.checked ? 1 : 0)
              }
            />
          </Form.Group>

          {full &&
          (simulacao.compor || simulacao.estado_civil.includes('Casado')) ? (
            <div>
              <p className='mt-5 mb-2 f09 fc-blue softxt'>
                Sobre a pessoa que irá compor renda com você
              </p>

              {full ? (
                <>
                  {simulacao.estado_civil.includes('Casado') ? (
                    <p className='mt-3 mb-2 f08'>
                      Como você informou ser casado(a), a composição de renda é
                      obrigatória, mesmo que a outra pessoa não tenha renda.
                    </p>
                  ) : (
                    ''
                  )}

                  {/* relacionamento */}
                  {simulacao.compor ||
                  simulacao.estado_civil.includes('Casado') ? (
                    <Form.Group className='mb-3'>
                      <Form.Label className='input-label'>
                        Relacionamento com a pessoa que irá compor renda{' '}
                        <span className='lab-sp-required'>*</span>
                      </Form.Label>
                      <Form.Select
                        id='relacionamento'
                        name='relacionamento'
                        value={simulacao.relacionamento}
                        onChange={(e) => {
                          if (e.target.value === '') {
                            e.target.setCustomValidity(
                              'Informe o relacionamento com a pessoa que irá compor renda',
                            );
                            e.target.classList.add('is-invalid');
                          } else {
                            e.target.setCustomValidity('');
                            e.target.classList.remove('is-invalid');
                          }
                          updateSimulacaoState(e.target.id, e.target.value);
                        }}
                      >
                        <option value=''>Selecione</option>
                        <option value='Cônjuge'>Cônjuge</option>
                        <option value='Companheiro(a)'>Companheiro(a)</option>
                        <option value='Pai/Mãe'>Pai/Mãe</option>
                        <option value='Filho(a)'>Filho(a)</option>
                        <option value='Irmão/Irmã'>Irmão/Irmã</option>
                        <option value='Outro'>Outro</option>
                      </Form.Select>
                      <Form.Control.Feedback
                        type='invalid'
                        className='mt-0 f08'
                      >
                        Informe o relacionamento com a pessoa que irá compor
                        renda
                      </Form.Control.Feedback>
                    </Form.Group>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                ''
              )}

              {/* outro_nome */}
              <Form.Group className='mb-3'>
                <Form.Label
                  htmlFor='outro_nome'
                  className='input-label'
                >
                  Nome <span className='lab-sp-required'>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  id='outro_nome'
                  name='outro_nome'
                  required
                  value={simulacao.outro_nome}
                  onChange={(e) => {
                    if (!is_compound_name(e.target.value)) {
                      e.target.setCustomValidity(
                        'Informe o nome completo da pessoa que irá compor renda com você',
                      );
                      e.target.classList.add('is-invalid');
                    } else {
                      e.target.setCustomValidity('');
                      e.target.classList.remove('is-invalid');
                    }
                    updateSimulacaoState(e.target.id, e.target.value);
                  }}
                />
                <Form.Control.Feedback
                  type='invalid'
                  className='mt-0 f08'
                ></Form.Control.Feedback>
              </Form.Group>
              {/* outro_cpf */}
              <Form.Group className='mb-3'>
                <Form.Label
                  htmlFor='outro_cpf'
                  className='input-label'
                >
                  CPF <span className='lab-sp-required'>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  as={IMaskInput}
                  mask='000.000.000-00'
                  id='outro_cpf'
                  name='outro_cpf'
                  required
                  value={simulacao.outro_cpf}
                  minLength={14}
                  maxLength={14}
                  onChange={(e) =>
                    updateSimulacaoState(e.target.id, e.target.value)
                  }
                  onAccept={(value) => {
                    updateSimulacaoState('outro_cpf', value);
                  }}
                />
              </Form.Group>

              {/* outro_nascimento */}
              <Form.Group className='mb-3'>
                <Form.Label
                  htmlFor='outro_nascimento'
                  className='input-label'
                >
                  Data de nascimento da outra pessoa{' '}
                  <span className='lab-sp-required'>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  as={IMaskInput}
                  mask='00/00/0000'
                  id='outro_nascimento'
                  name='outro_nascimento'
                  required
                  value={simulacao.outro_nascimento}
                  onChange={(e) => {
                    if (!is_valid_nascimento(e.target.value)) {
                      e.target.setCustomValidity(
                        'Informe a data de nascimento corretamente',
                      );
                      e.target.classList.add('is-invalid');
                    } else {
                      e.target.setCustomValidity('');
                      e.target.classList.remove('is-invalid');
                    }
                    updateSimulacaoState(e.target.id, e.target.value);
                  }}
                  onAccept={(value) => {
                    let e = document.getElementById('outro_nascimento');
                    if (!is_valid_nascimento(e.value)) {
                      e.setCustomValidity(
                        'Informe a data de nascimento corretamente',
                      );
                      e.classList.add('is-invalid');
                    } else {
                      e.setCustomValidity('');
                      e.classList.remove('is-invalid');
                    }
                    updateSimulacaoState('outro_nascimento', value);
                  }}
                />
                <FormInvalidFeedback />
              </Form.Group>

              {/* outro_renda */}
              <Form.Group className='mb-3'>
                <Form.Label
                  htmlFor='outro_renda'
                  className='input-label'
                >
                  Renda bruta mensal <span className='lab-sp-required'>*</span>
                  <br />
                  <span className='f09'>Caso não possua, informe 0</span>
                </Form.Label>
                <Form.Control
                  // type="number"
                  // step={'0.1'}
                  id='outro_renda'
                  name='outro_renda'
                  type='text'
                  required
                  onChange={(e) => handleValor(e)}
                  onKeyUp={(e) => handleValor(e)}
                />
              </Form.Group>
              {/* outro_fgts */}
              <Form.Group className='mb-3'>
                <Form.Label
                  htmlFor='outro_fgts'
                  className='input-label'
                >
                  Informe o saldo aproximado de FGTS que pretende utilizar{' '}
                  <span className='lab-sp-required'>*</span>
                  <br />
                  <span className='f09'>Caso não possua, informe 0</span>
                </Form.Label>
                <Form.Control
                  // type="number"
                  // step={'0.1'}
                  id='outro_fgts'
                  name='outro_fgts'
                  type='text'
                  required
                  onChange={(e) => handleValor(e)}
                  onKeyUp={(e) => handleValor(e)}
                />
              </Form.Group>
              {/* outro_recursos */}
              <Form.Group className='mb-3'>
                <Form.Label className='input-label'>
                  Informe o saldo de outros recursos próprios (exceto FGTS){' '}
                  <span className='lab-sp-required'>*</span>
                  <br />
                  <span className='f09'>Caso não possua, informe 0</span>
                </Form.Label>
                <Form.Control
                  // type="number"
                  // step={'0.1'}
                  id='outro_recursos'
                  name='outro_recursos'
                  type='text'
                  required
                  onChange={(e) => handleValor(e)}
                  onKeyUp={(e) => handleValor(e)}
                />
              </Form.Group>
              {/* outro_comprovar */}
              <Form.Group className='mb-3'>
                <Form.Label className='input-label'>
                  Comprovação de renda{' '}
                  <span className='lab-sp-required'>*</span> (selecione uma ou
                  mais opções)
                </Form.Label>
                <Form.Check
                  type='checkbox'
                  id='outro_comprovar_1'
                  name='outro_comprovar'
                  label='Holerite'
                  value='Holerite'
                  checked={simulacao.outro_comprovar.includes('Holerite')}
                  onChange={(e) => {
                    let comprovar = simulacao.outro_comprovar;
                    if (e.target.checked ? 1 : 0) {
                      comprovar.push(e.target.value);
                      updateSimulacaoState('outro_comprovar', comprovar);
                    } else {
                      comprovar = comprovar.filter(
                        (item) => item !== e.target.value,
                      );
                    }
                    updateSimulacaoState('outro_comprovar', comprovar);
                  }}
                />
                <Form.Check
                  type='checkbox'
                  id='outro_comprovar_2'
                  name='outro_comprovar'
                  label='Extrato bancário'
                  value='Extrato bancário'
                  checked={simulacao.outro_comprovar.includes(
                    'Extrato bancário',
                  )}
                  onChange={(e) => {
                    let comprovar = simulacao.outro_comprovar;
                    if (e.target.checked ? 1 : 0) {
                      comprovar.push(e.target.value);
                      updateSimulacaoState('outro_comprovar', comprovar);
                    } else {
                      comprovar = comprovar.filter(
                        (item) => item !== e.target.value,
                      );
                    }
                    updateSimulacaoState('outro_comprovar', comprovar);
                  }}
                />
                <Form.Check
                  type='checkbox'
                  id='outro_comprovar_3'
                  name='outro_comprovar'
                  label='Declaração de Imposto de Renda'
                  value='Declaração de Imposto de Renda'
                  checked={simulacao.outro_comprovar.includes(
                    'Declaração de Imposto de Renda',
                  )}
                  onChange={(e) => {
                    let comprovar = simulacao.outro_comprovar;
                    if (e.target.checked ? 1 : 0) {
                      comprovar.push(e.target.value);
                      updateSimulacaoState('outro_comprovar', comprovar);
                    } else {
                      comprovar = comprovar.filter(
                        (item) => item !== e.target.value,
                      );
                    }
                    updateSimulacaoState('outro_comprovar', comprovar);
                  }}
                />
                <Form.Check
                  type='checkbox'
                  id='outro_comprovar_4'
                  name='outro_comprovar'
                  label='Pró Labore'
                  value='Pró Labore'
                  checked={simulacao.outro_comprovar.includes('Pró Labore')}
                  onChange={(e) => {
                    let comprovar = simulacao.outro_comprovar;
                    if (e.target.checked ? 1 : 0) {
                      comprovar.push(e.target.value);
                      updateSimulacaoState('outro_comprovar', comprovar);
                    } else {
                      comprovar = comprovar.filter(
                        (item) => item !== e.target.value,
                      );
                    }
                    updateSimulacaoState('outro_comprovar', comprovar);
                  }}
                />
                <Form.Check
                  type='checkbox'
                  id='outro_comprovar_5'
                  name='outro_comprovar'
                  label='Não tem como comprovar'
                  value='Não tem como comprovar'
                  checked={simulacao.outro_comprovar.includes(
                    'Não tem como comprovar',
                  )}
                  onChange={(e) => {
                    let comprovar = simulacao.outro_comprovar;
                    if (e.target.checked ? 1 : 0) {
                      comprovar.push(e.target.value);
                      updateSimulacaoState('outro_comprovar', comprovar);
                    } else {
                      comprovar = comprovar.filter(
                        (item) => item !== e.target.value,
                      );
                    }
                    updateSimulacaoState('outro_comprovar', comprovar);
                  }}
                />
              </Form.Group>
            </div>
          ) : (
            ''
          )}

          <p className='mt-5 mb-2 f09 softxt fc-blue'>
            {simulacao.compor || simulacao.estado_civil.includes('Casado')
              ? 'Marque abaixo se as opções se aplicam a você ou a quem irá compor renda com você'
              : 'Marque abaixo se as opções se aplicam a você'}
          </p>
          {/* possui_imovel */}
          <Form.Group className='mb-3'>
            <p className='mb-0'>
              Possui Imóvel Registrado{' '}
              <span className='lab-sp-required'>*</span>
            </p>
            <div className='mt-0 d-flex flex-row'>
              <Form.Check
                required
                type='radio'
                id='possui_imovel_sim'
                className='me-2'
                name='possui_imovel'
                checked={simulacao.possui_imovel === '1'}
                label='Sim'
                value={'1'}
                onChange={(e) =>
                  updateSimulacaoState(e.target.name, e.target.value)
                }
              />
              <Form.Check
                type='radio'
                id='possui_imovel_nao'
                checked={simulacao.possui_imovel === '0'}
                className='ms-3'
                name='possui_imovel'
                label='Não'
                value={'0'}
                onChange={(e) =>
                  updateSimulacaoState(e.target.name, e.target.value)
                }
              />
            </div>
          </Form.Group>
          {/* <Form.Group className='mb-3'>
            <Form.Check
              type='checkbox'
              id='possui_imovel'
              name='possui_imovel'
              checked={simulacao.possui_imovel}
              label='Possui Imóvel Registrado'
              onChange={(e) =>
                updateSimulacaoState(e.target.id, e.target.checked ? 1 : 0)
              }
            />
          </Form.Group> */}
          {simulacao.possui_imovel === '1' ? (
            <Form.Group className='mb-3'>
              <Form.Label
                htmlFor='possui_imovel_cidade'
                className='input-label'
              >
                Cidade do imóvel <span className='lab-sp-required'>*</span>
              </Form.Label>
              <Form.Control
                type='text'
                id='possui_imovel_cidade'
                name='possui_imovel_cidade'
                required
                value={simulacao.possui_imovel_cidade}
                onChange={(e) =>
                  updateSimulacaoState(e.target.id, e.target.value)
                }
              />
            </Form.Group>
          ) : (
            ''
          )}
          {/* servidor_publico */}
          <Form.Group className='mb-3'>
            <p className='mb-0'>
              É servidor público? <span className='lab-sp-required'>*</span>
            </p>
            <div className='mt-0 d-flex flex-row'>
              <Form.Check
                required
                type='radio'
                id='servidor_publico_sim'
                name='servidor_publico'
                className='me-2'
                label='Sim'
                checked={simulacao.servidor_publico === '1'}
                value={'1'}
                onChange={(e) =>
                  updateSimulacaoState(e.target.name, e.target.value)
                }
              />{' '}
              <Form.Check
                type='radio'
                id='servidor_publico_nao'
                name='servidor_publico'
                className='ms-3'
                label='Não'
                checked={simulacao.servidor_publico === '0'}
                value={'0'}
                onChange={(e) =>
                  updateSimulacaoState(e.target.name, e.target.value)
                }
              />
            </div>
          </Form.Group>
          {/* anos_fgts */}
          <Form.Group className='mb-3'>
            <p className='mb-0'>
              Possui 3 anos de trabalho registrado, considerando todos os
              empregos que já teve? (mesmo se atualmente não estiver registrado){' '}
              <span className='lab-sp-required'>*</span>
            </p>
            <div className='mt-0 d-flex flex-row'>
              <Form.Check
                required
                type='radio'
                id='anos_fgts_sim'
                className='me-2'
                name='anos_fgts'
                checked={simulacao.anos_fgts === '1'}
                label='Sim'
                value={'1'}
                onChange={(e) =>
                  updateSimulacaoState(e.target.name, e.target.value)
                }
              />
              <Form.Check
                type='radio'
                id='anos_fgts_nao'
                checked={simulacao.anos_fgts === '0'}
                className='ms-3'
                name='anos_fgts'
                label='Não'
                value={'0'}
                onChange={(e) =>
                  updateSimulacaoState(e.target.name, e.target.value)
                }
              />
            </div>
          </Form.Group>
          {/* dependentes */}
          <Form.Group className='mb-3'>
            <p className='mb-0'>
              Possui dependentes (filhos ou cônjuge)?{' '}
              <span className='lab-sp-required'>*</span>
            </p>
            <div className='mt-0 d-flex flex-row'>
              <Form.Check
                required
                type='radio'
                id='dependentes_sim'
                className='me-2'
                name='dependentes'
                checked={simulacao.dependentes === '1'}
                label='Sim'
                value={'1'}
                onChange={(e) =>
                  updateSimulacaoState(e.target.name, e.target.value)
                }
              />
              <Form.Check
                type='radio'
                id='dependentes_nao'
                checked={simulacao.dependentes === '0'}
                className='ms-3'
                name='dependentes'
                label='Não'
                value={'0'}
                onChange={(e) =>
                  updateSimulacaoState(e.target.name, e.target.value)
                }
              />
            </div>
          </Form.Group>
          {/* <Form.Group className='mb-3'>
            <Form.Check
              type='checkbox'
              id='dependentes'
              name='dependentes'
              label='Possui Dependentes'
              checked={simulacao.dependentes}
              onChange={(e) =>
                updateSimulacaoState(e.target.id, e.target.checked ? 1 : 0)
              }
            />
          </Form.Group> */}
          {/* clt */}
          <Form.Group className='mb-3'>
            <p className='mb-0'>
              Atualmente registrado CLT?{' '}
              <span className='lab-sp-required'>*</span>
            </p>
            <div className='mt-0 d-flex flex-row'>
              <Form.Check
                required
                type='radio'
                id='clt_sim'
                className='me-2'
                name='clt'
                checked={simulacao.clt === '1'}
                label='Sim'
                value={'1'}
                onChange={(e) =>
                  updateSimulacaoState(e.target.name, e.target.value)
                }
              />
              <Form.Check
                type='radio'
                id='clt_nao'
                checked={simulacao.clt === '0'}
                className='ms-3'
                name='clt'
                label='Não'
                value={'0'}
                onChange={(e) =>
                  updateSimulacaoState(e.target.name, e.target.value)
                }
              />
            </div>
          </Form.Group>
          {/* <Form.Group className='mb-3'>
            <Form.Check
              type='checkbox'
              id='clt'
              name='clt'
              label='Atualmente registrado CLT'
              checked={simulacao.clt}
              onChange={(e) =>
                updateSimulacaoState(e.target.id, e.target.checked ? 1 : 0)
              }
            />
          </Form.Group> */}
          {/* beneficiado */}
          <Form.Group className='mb-3'>
            <p className='mb-0'>
              Foi beneficiado por algum programa habitacional?{' '}
              <span className='lab-sp-required'>*</span>
            </p>
            <div className='mt-0 d-flex flex-row'>
              <Form.Check
                required
                type='radio'
                id='beneficiado_sim'
                className='me-2'
                name='beneficiado'
                checked={simulacao.beneficiado === '1'}
                label='Sim'
                value={'1'}
                onChange={(e) =>
                  updateSimulacaoState(e.target.name, e.target.value)
                }
              />
              <Form.Check
                type='radio'
                id='beneficiado_nao'
                checked={simulacao.beneficiado === '0'}
                className='ms-3'
                name='beneficiado'
                label='Não'
                value={'0'}
                onChange={(e) =>
                  updateSimulacaoState(e.target.name, e.target.value)
                }
              />
            </div>
          </Form.Group>

          {/* informacoes */}

          <Form.Group className='mb-3'>
            <Form.Label
              htmlFor='informacoes'
              className='input-label'
            >
              Informações adicionais
            </Form.Label>
            <Form.Control
              as='textarea'
              id='informacoes'
              name='informacoes'
              value={simulacao.informacoes}
              onChange={(e) =>
                updateSimulacaoState(e.target.id, e.target.value)
              }
            />
          </Form.Group>

          <Form.Group className='mb-3 mt-3'>
            <Form.Check
              required
              id='termos'
              name='termos'
              checked={simulacao.termos}
              label={
                <span>
                  Concordo com os termos e condições{' '}
                  <span className='lab-sp-required'>*</span>
                </span>
              }
              feedback='É necessário concordar com os termos antes de prosseguir.'
              feedbackType='invalid'
              onChange={(e) =>
                updateSimulacaoState(e.target.id, e.target.checked ? 1 : 0)
              }
            />
          </Form.Group>
          {loading ? (
            <LoadingDots />
          ) : (
            <>
              {/* {validated ? (
                ''
              ) : (
                <p className="mt-3 mb-2 f08 softxt fc-red">
                  Preencha todos os campos
                </p>
              )} */}
              <Button type='submit'>Enviar simulação</Button>
            </>
          )}
        </Form>
      </div>
    </div>
  );
}

export default Simulador;
