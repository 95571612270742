import * as d3 from 'd3';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { utils } from '../config/imports';

const RendaGraph = ({
  defineRenda,
  defineImovel,
  maxRenda = 10000,
  maxImovel = 800000,
}) => {
  const { curr } = utils;
  const svgRef = useRef();
  // const [tip, setTip] = useState('');
  const [rendaValue, setRendaValue] = useState(0); // Valor do input [Renda]
  const [imovelValue, setImovelValue] = useState(0); // Valor do input [Imóvel]
  const [tipRenda, setTipRenda] = useState('');
  const [tipParcela, setTipParcela] = useState('');
  const [tipImovel, setTipImovel] = useState('');
  const [change, setChange] = useState(false);

  const handleChanges = useCallback(
    (renda, imovel) => {
      setRendaValue(renda);
      defineRenda(renda);
      setImovelValue(imovel);
      defineImovel(imovel);
    },
    [defineRenda, defineImovel],
  );

  useEffect(() => {
    // Clear previous graph
    d3.select(svgRef.current).selectAll('*').remove();

    // Set dimensions
    const width = window.innerWidth > 991 ? 650 : 420;
    const height = 400;
    const margin = { top: 30, right: 20, bottom: 20, left: 30 };
    const padding = 20;

    // Create SVG canvas
    const svg = d3
      .select('#div_svg_graph')
      .append('svg')
      .attr('id', 'svg-graph')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Define scales
    const xScale = d3
      .scaleLinear()
      .domain([0, maxRenda])
      .range([0, width - margin.left - margin.right - padding]);

    const yScale = d3
      .scaleLinear()
      .domain([0, maxImovel])
      .nice()
      .range([height - margin.top - margin.bottom - padding, 0]);

    const xAxis = d3.axisBottom().tickFormat(d3.format('~s')).scale(xScale);
    const yAxis = d3.axisLeft().tickFormat(d3.format('~s')).scale(yScale);

    const evalRenda = (x) => {
      if (x > 0 && x < 18000) return true;
      return false;
    };

    const evalImovel = (y) => {
      if (y > 10000 && y < 1500000) return true;
      return false;
    };

    const createTip = (event) => {
      const mouseX = d3.pointer(event, svg.node())[0];
      const mouseY = d3.pointer(event, svg.node())[1];
      const rendaValue = parseFloat(
        parseFloat(xScale.invert(mouseX)).toFixed(2),
      );
      const imovelVal = parseFloat(
        parseFloat(yScale.invert(mouseY)).toFixed(2),
      );
      const parcelaValue = parseFloat((0.3 * rendaValue).toFixed(2));
      let res = {};
      res.tip = {};
      res.val = { rendaValue, parcelaValue, imovelVal };
      res.tip.renda = `Renda: ${curr(rendaValue)}`;
      res.tip.parc = `Parcela: ${curr(parcelaValue)}`;
      res.tip.imovel = `Imóvel: ${curr(imovelVal)}`;
      res.tip.full = `Renda: ${curr(rendaValue)}\nParcela: ${curr(
        parcelaValue,
      )}\nImóvel: ${curr(imovelVal)}`;

      res.positionX = event.pageX + 10 + 'px';
      res.positionY = event.pageY - 20 + 'px';
      res.mouse = { mouseX, mouseY };
      return res;
    };

    svg
      .append('g')
      .attr(
        'transform',
        `translate(0,${height - margin.top - margin.bottom - padding})`,
      )
      .attr('color', 'blue')
      .call(xAxis);

    svg
      .append('g')
      .attr('transform', `translate(${-margin.left + margin.right + 10}, 0)`)
      .attr('color', '#f76400')
      .call(yAxis);

    svg
      .append('text')
      .attr('x', 580)
      .attr('y', 360)
      .style('font-size', '11px')
      .style('font-weight', 'bold')
      .text('Renda');

    svg
      .append('text')
      .attr('x', -30)
      .attr('y', -10)
      .style('font-size', '11px')
      .style('font-weight', 'bold')
      .text('Imóvel');

    if (!change) {
      svg
        .append('circle')
        .attr('cx', xScale(rendaValue))
        .attr('cy', yScale(imovelValue))
        .attr('r', 5)
        .attr('fill', 'red')
        .append('title')
        .text(`Renda: ${curr(rendaValue)}\nImóvel: ${curr(imovelValue)}`);
    }

    d3.select('#div_svg_graph')
      .on('mousemove', function (event) {
        const res = createTip(event);
        if (evalRenda(res.val.rendaValue) && evalImovel(res.val.imovelVal)) {
          setTipRenda(res.tip.renda);
          setTipParcela(res.tip.parc);
          setTipImovel(res.tip.imovel);
          if (change) {
            handleChanges(res.val.rendaValue, res.val.imovelVal);
          }
        }
      })
      .on('click', function (event) {
        const res = createTip(event);
        if (evalRenda(res.val.rendaValue) && evalImovel(res.val.parcelaValue)) {
          handleChanges(res.val.rendaValue, res.val.imovelVal);
        }
        const changeState = !change;
        setChange(changeState);
        // setMouseClick({ x: res.mouse.mouseX, y: res.mouse.mouseY });

        // svg
        //   .append('circle')
        //   .attr('cx', res.mouse.mouseX)
        //   .attr('cy', res.mouse.mouseY)
        //   .attr('r', 5)
        //   .attr('fill', 'red')
        //   .append('title')
        //   .text(res.tip.full);

        //   console.log('Circle appended at:', { x: res.mouse.mouseX, y: res.mouse.mouseY });
      })

      .on('mouseout', function () {
        setTipRenda('');
        setTipParcela('');
      });
  }, [
    handleChanges,
    change,
    setChange,
    maxImovel,
    maxRenda,
    imovelValue,
    rendaValue,
    curr,
  ]);

  return (
    <>
      <div className='mt-2'>
        <p className='fc-regular fw500'>
          {change ? (
            <span className='fc-green'>Atualização automática</span>
          ) : (
            <span className='fc-red'>Atualização manual</span>
          )}
        </p>
      </div>
      <div
        id='div_svg_graph'
        ref={svgRef}
        className='bg-div-svg-graph'
      ></div>

      <div className='d-flex flex-row f09 my-2 fc-regular'>
        <span className='mx-2'>{change && tipRenda && tipRenda}</span>
        <span className='mx-2'>{change && tipParcela && tipParcela}</span>
        <span className='mx-2'>{change && tipImovel && tipImovel}</span>
      </div>
      <div className='d-flex flex-column fc-regular'>
        <span className='d-flex flex-row mx-2'>
          <span className='wp-150'>Renda definida:</span>
          <span className='wp-150'>{curr(rendaValue)}</span>
        </span>
        <span className='d-flex flex-row mx-2'>
          <span className='wp-150'>Imóvel definido:</span>
          <span className='wp-150'>{curr(imovelValue)}</span>
        </span>
        <span className='d-flex flex-row mx-2'>
          <span className='wp-150'>Parcela máxima:</span>
          <span className='wp-150'>{curr(0.3 * rendaValue)}</span>
        </span>
      </div>
    </>
  );
};

export default RendaGraph;
