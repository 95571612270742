export default function SelectOptions({ docs, prop, id }) {
  return (
    <>
      <option
        key='default_option'
        value=''
        name='default_option'
        id='default_option'
      ></option>

      {docs.map((doc) => (
        <option
          key={doc[prop]}
          value={doc[prop]}
          name={id}
          id={id}
        >
          {doc[prop]}
        </option>
      ))}
    </>
  );
}
