import React, { useState } from 'react';
import { Badge, Dropdown } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaCircle } from 'react-icons/fa';
import { api, end } from '../config/imports';
const PriorityDropdown = ({
  prioridades,
  prior,
  processo_etapa_id,
  onPrioridadeChange,
  showName,
  codigo,
  tipo,
  upd,
}) => {
  const { post } = api;
  const { task, and } = end;
  const [priorityColor, setPriorityColor] = useState(
    prioridades[prior - 1].CODIGO,
  );
  const [priorityName, setPriorityName] = useState(prioridades[prior - 1].NOME);

  const handlePrioritySelect = async (priority) => {
    setPriorityColor(prioridades.find((item) => item.ID === priority).CODIGO);
    setPriorityName(prioridades.find((item) => item.ID === priority).NOME);
    onPrioridadeChange(priority);
    if (codigo) {
      const endpoint =
        tipo === 'task' ? task.update.prioridade : and.update.prioridade;
      try {
        await post(endpoint, {
          codigo,
          prioridade_id: priority,
        });
        // if (response.status === 200) {
        //   console.log('Prioridade atualizada');
        // } else {
        //   console.error('Erro ao atualizar prioridade');
        // }
      } catch (error) {
        console.error('Erro ao atualizar prioridade', error);
      }
      upd();
    }
  };

  return (
    <Dropdown className='m-0 mx-1'>
      <Dropdown.Toggle
        variant=''
        size='sm'
        id={`drop_prioridades_${processo_etapa_id}`}
        className='drop-prioridades'
      >
        <span
          variant={priorityColor}
          size='sm'
          className='softxt f08 d-flex flex-row align-items-center '
        >
          <FaCircle className={`fc-${priorityColor} svg12`} />
          {showName ? (
            <OverlayTrigger
              placement='top'
              id={`tt_drop_prioridade_${processo_etapa_id}`}
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip>Prioridade</Tooltip>}
            >
              <span className='mx-1'>{priorityName}</span>
            </OverlayTrigger>
          ) : (
            ''
          )}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu
        size='sm'
        className='drop-menu-out'
      >
        {prioridades.map((priority, index) => (
          <Dropdown.Item
            key={`drop_prioridades_${processo_etapa_id}_${priority.ID}_${index}`}
          >
            <Badge
              bg={priority.CODIGO}
              key={`drop_prioridades_${processo_etapa_id}_${priority.ID}_${index}_bad`}
              onClick={() => handlePrioritySelect(priority.ID)}
            >
              {priority.NOME}
            </Badge>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default PriorityDropdown;
