import { IconsUI, ui} from '../../config/imports';

function EtapaIcon({ etapa, clsnm = '' }) {
  switch (etapa) {
    case 'simulação':
      return <IconsUI info={ui.etapas.simulacao} clsnm={clsnm} />;
    case 'documentação do comprador':
      return <IconsUI info={ui.etapas.documentacao_comprador} clsnm={clsnm} />;
    case 'aprovação de crédito':
      return <IconsUI info={ui.etapas.aprovacao_credito} clsnm={clsnm} />;
    case 'documentação do vendedor':
      return <IconsUI info={ui.etapas.documentacao_vendedor} clsnm={clsnm} />;
    case 'documentação do imóvel':
      return <IconsUI info={ui.etapas.documentacao_imovel} clsnm={clsnm} />;
    case 'avaliação de engenharia':
      return <IconsUI info={ui.etapas.avaliacao_engenharia} clsnm={clsnm} />;
    case 'confirmação de valores':
      return <IconsUI info={ui.etapas.confirmacao_valores} clsnm={clsnm} />;
    case 'assinatura de formulários':
      return <IconsUI info={ui.etapas.assinatura_formularios} clsnm={clsnm} />;
    case 'análise jurídica':
      return <IconsUI info={ui.etapas.analise_juridica} clsnm={clsnm} />;
    case 'assinatura do contrato':
      return <IconsUI info={ui.etapas.assinatura_contrato} clsnm={clsnm} />;
    case 'prefeitura':
      return <IconsUI info={ui.etapas.prefeitura} clsnm={clsnm} />;
    case 'cartório':
      return  <IconsUI info={ui.etapas.cartorio} clsnm={clsnm} />;
    case 'finalizado':
      return <IconsUI info={ui.etapas.finalizado} clsnm={clsnm} />;
    case 'mindmap':
      return <IconsUI info={ui.etapas.mindmap} clsnm={clsnm} />;
    default:
      return '';
  }
}

export default EtapaIcon;