import React from 'react';
import {
  Account,
  Admin,
  Authorize,
  Clientes,
  Financiamentos,
  News,
  Processos,
  Simulacoes,
  Support,
  Tasks,
} from '../config/imports';

function RenderSection({ section }) {
  const role_id = sessionStorage.getItem('@App:ri');

  switch (section) {
    case 'account':
      return (
        <div className='mb-5'>
          <hr className='mt-5 mb-4' />
          <Account />
        </div>
      );
    case 'news':
      if (Authorize(role_id, 3))
        return (
          <div className='mb-5'>
            <hr className='mt-5 mb-4' />
            <News />
          </div>
        );
      break;

    case 'simulacoes':
      if (Authorize(role_id, 5))
        return (
          <div className='mb-5'>
            <hr className='mt-5 mb-4' />
            <Simulacoes />
          </div>
        );
      break;
    case 'financiamentos':
      if (Authorize(role_id, 5))
        return (
          <div className='mb-5'>
            <hr className='mt-5 mb-4' />
            <Financiamentos />
          </div>
        );
      break;
    case 'tasks':
      if (Authorize(role_id, 5))
        return (
          <div className='mb-5'>
            <hr className='mt-5 mb-4' />
            <Tasks />
          </div>
        );
      break;
    case 'processos':
      if (Authorize(role_id, 5))
        return (
          <div className='mb-5'>
            <hr className='mt-5 mb-4' />
            <Processos />
          </div>
        );
      break;
    case 'clientes':
      if (Authorize(role_id, 5))
        return (
          <div className='mb-5'>
            <hr className='mt-5 mb-4' />
            <Clientes />
          </div>
        );
      break;

    case 'admin':
      if (Authorize(role_id, 3))
        return (
          <div className='mb-5'>
            <hr className='mt-5 mb-4' />
            <Admin />
          </div>
        );
      break;
    case 'support':
      return (
        <div className='mb-5'>
          <hr className='mt-5 mb-4' />
          <Support />
        </div>
      );
    default:
      return '';
  }
}

export default RenderSection;
