import React, { useContext, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Tooltip from 'react-bootstrap/Tooltip';
import { useParams } from 'react-router-dom';

import { GiSandsOfTime } from 'react-icons/gi';
import { IoCheckmarkDoneOutline } from 'react-icons/io5';

import {
  AgendamentoModal,
  AndamentosLine,
  AndamentosModal,
  ConcluidoModal,
  DocumentosModal,
  EtapaIcon,
  Footer,
  GlobalContext,
  IconsUI,
  LoadingDots,
  MensagemModal,
  NavBar,
  NavReturn,
  TarefaModal,
  api,
  end,
  helpers,
  ui,
} from '../config/imports';

function Processo() {
  const { checkLogin, documentos, navigate } = useContext(GlobalContext);
  const { numberFormat } = helpers;
  const { post } = api;
  const { proc, proc_et, and } = end;
  const { codigo } = useParams();

  const [processo, setProcesso] = useState([]);
  const [etapas, setEtapas] = useState([]);
  const [andamentos, setAndamentos] = useState([]);

  // const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [percent, setPercent] = useState(0);
  const [percentColor, setPercentColor] = useState('primary');

  const [andamentosModal, setAndamentosModal] = useState([]);
  const [documentosModal, setDocumentosModal] = useState([]);
  const [mensagemModal, setMensagemModal] = useState([]);
  const [tarefasModal, setTarefasModal] = useState([]);
  const [agendamentosModal, setAgendamentosModal] = useState([]);
  const [concluidosModal, setConcluidosModal] = useState([]);

  const [logged, setLogged] = useState(true);

  useEffect(() => {
    const status = checkLogin();
    setLogged(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleModal = (index, modal, prevStates) => {
    let newStates = [];
    switch (modal) {
      case 'andamento':
        newStates = [...prevStates];
        newStates[index] = !newStates[index];
        setAndamentosModal(newStates);
        break;
      case 'documento':
        newStates = [...prevStates];
        newStates[index] = !newStates[index];
        setDocumentosModal(newStates);
        break;
      case 'mensagem':
        newStates = [...prevStates];
        newStates[index] = !newStates[index];
        setMensagemModal(newStates);
        break;
      case 'tarefa':
        newStates = [...prevStates];
        newStates[index] = !newStates[index];
        setTarefasModal(newStates);
        break;
      case 'agendamento':
        newStates = [...prevStates];
        newStates[index] = !newStates[index];
        setAgendamentosModal(newStates);
        break;
      case 'concluido':
        newStates = [...prevStates];
        newStates[index] = !newStates[index];
        setConcluidosModal(newStates);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const get_processo = async () => {
      setLoading(true);
      try {
        const [processoResponse, etapasResponse, andamentosResponse] =
          await Promise.all([
            post(proc.read.codigo, {
              codigo,
            }),
            post(proc_et.read, {
              codigo,
            }),
            post(and.read.all, {
              codigo,
            }),
          ]);

        const { data: processoData } = processoResponse;
        const { data: etapasData } = etapasResponse;
        const { data: andamentosData } = andamentosResponse;

        const percent_concluido =
          etapasData.filter((et) => et.PROCESSO_ETAPAS_CONCLUIDA).length /
          etapasData.length;
        const perc = percent_concluido * 100;
        setPercent(perc);

        if (perc < 20) {
          setPercentColor('secondary');
        } else if (perc < 40) {
          setPercentColor('warning');
        } else if (perc < 60) {
          setPercentColor('info');
        } else if (perc < 80) {
          setPercentColor('primary');
        } else {
          setPercentColor('success');
        }

        // setEtapas(ets);
        if (processoData) setProcesso(processoData[0]);
        if (andamentosData) setAndamentos(andamentosData);
        if (etapasData) setEtapas(etapasData);

        setAgendamentosModal(etapasData.map(() => false));
        setAndamentosModal(etapasData.map(() => false));
        setDocumentosModal(etapasData.map(() => false));
        setMensagemModal(etapasData.map(() => false));
        setTarefasModal(etapasData.map(() => false));
        setConcluidosModal(etapasData.map(() => false));
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };
    get_processo();
  }, [codigo, updated, and, proc, proc_et, post]);

  const handleUpdate = () => {
    const upd = updated;
    setUpdated(!upd);
  };

  const handleChangeDataConcluido = (index) => {
    setEtapas((prevEtapas) =>
      prevEtapas.map((etapa, i) =>
        i === index
          ? {
              ...etapa,
              processo_etapas_data_inicial: document.getElementById(
                `id_modal_started_${etapa.PROCESSO_ETAPAS_ID}`,
              ).value,
              processo_etapas_data_final: document.getElementById(
                `id_modal_done_${etapa.PROCESSO_ETAPAS_ID}`,
              ).value,
            }
          : etapa,
      ),
    );
  };

  const handleSave = async (index) => {
    setLoading(true);
    try {
      await post(proc_et.update.done, {
        id: etapas[index].PROCESSO_ETAPAS_ID,
        // status: etapas[index].PROCESSO_ETAPAS_CONCLUIDA ? 'Encerrado' : 'Pendente',
        data_inicial: new Date(etapas[index].PROCESSO_ETAPAS_DATA_INICIAL)
          .toISOString()
          .split('T')[0],
        data_final: new Date(etapas[index].PROCESSO_ETAPAS_DATA_FINAL)
          .toISOString()
          .split('T')[0],
        processo_etapa_id: etapas[index].PROCESSO_ETAPAS_ID,
        concluida: etapas[index].PROCESSO_ETAPAS_CONCLUIDA,
        avancar:
          etapas.length > index + 1 ? etapas[index + 1].PROCESSO_ETAPAS_ID : 0,
      });

      setUpdated((prevUpdated) => !prevUpdated);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = async (index) => {
    setEtapas(() => {
      const newStates = [...etapas];
      newStates[index].PROCESSO_ETAPAS_CONCLUIDA = 0;
      return newStates;
    });
    handleSave(index);
  };

  const headerProcesso = () => {
    return (
      <>
        <div className='processo-header my-3'>
          <div className='d-flex flex-row align-items-center justify-content-between fc-blue '>
            <h4 className='hc-section-title'>Processo</h4>
            <IconsUI
              info={ui.action.update}
              clsnm='svg14 hov-green'
              click={handleUpdate}
              tooltip='Atualizar lista de simulações'
            />
          </div>
          <div>
            <NavReturn info={processo.CLIENTE_NOME} />
            <p>{`${processo.OPERACAO}: ${numberFormat(
              processo.VALOR_IMOVEL,
            )} (${processo.CIDADE_IMOVEL})`}</p>
            <p>
              {`Corretor: ${processo.CORRETOR_NOME}`}
              {processo.IMOBILIARIA_NOME !== null &&
              processo.IMOBILIARIA_NOME !== '' &&
              processo.IMOBILIARIA_NOME !== 'Nenhuma'
                ? ` | Imobiliária: ${processo.IMOBILIARIA_NOME}`
                : ''}
            </p>
          </div>
          <ProgressBar
            now={percent}
            variant={percentColor}
            label={`${percent}%`}
          />
        </div>
        <div className='d-flex flex-row justify-content-between mt-3 mb-2'>
          <h6 className='softxt fc-blue'>Detalhes do Processo</h6>
          <div className='toggle-button-div'>
            <IconsUI
              info={ui.action.expand}
              clsnm='hov-svg-soft hov-green'
              click={() => {
                const el = document.getElementById('dv_cont_processo');
                el.classList.toggle('div-hide');
              }}
            />
          </div>
        </div>
        <div
          id='dv_cont_processo'
          className='mb-4 div-hide'
        >
          <div>
            <p className='f09'>Em construção...</p>
          </div>
        </div>
        <h6 className='my-3 softxt fc-blue'>Etapas</h6>
      </>
    );
  };

  if (!logged) {
    navigate('/logout');
  } else {
    if (loading) {
      return (
        <>
          <LoadingDots />
        </>
      );
    }

    return (
      <div className='habita-main'>
        <NavBar />
        <div className='habita-content mb-5'>
          <div className='habita-main-content'>
            {headerProcesso()}
            {etapas &&
              etapas.map((et, index) => {
                return (
                  <div
                    key={et.PROCESSO_ETAPAS_ID}
                    className={`div-etapa${
                      et.PROCESSO_ETAPAS_CONCLUIDA
                        ? ' et-concluida'
                        : processo.ETAPA_ATUAL_ID === et.PROCESSO_ETAPAS_ID
                        ? ' et-atual'
                        : ' et-pendente'
                    }`}
                  >
                    <div className='etapa-head'>
                      <div className='etapa-head-start'>
                        <p className='softxt m-0'>
                          <span className='me-2'>
                            <EtapaIcon etapa={et.ETAPAS_NOME} />
                          </span>
                          <span
                            className={`me-3 f12 softxt ${
                              et.PROCESSO_ETAPAS_CONCLUIDA
                                ? ' fc-grey'
                                : processo.ETAPA_ATUAL_ID ===
                                  et.PROCESSO_ETAPAS_ID
                                ? ' fc-blue'
                                : ''
                            }`}
                          >
                            {et.ETAPAS_NOME}
                          </span>
                          <span className='c-green'>
                            {et.PROCESSO_ETAPAS_CONCLUIDA ? (
                              <IconsUI info={ui.misc.check.circle} />
                            ) : (
                              ''
                            )}
                          </span>
                        </p>

                        <span className='ms-2 f08'>
                          <span className='mx-1'>
                            <IconsUI
                              info={ui.action.start}
                              clsnm={`svg14 ${
                                et.PROCESSO_ETAPAS_CONCLUIDA
                                  ? ' fc-green'
                                  : processo.ETAPA_ATUAL_ID ===
                                    et.PROCESSO_ETAPAS_ID
                                  ? ' fc-blue'
                                  : ' fc-grey'
                              }`}
                            />
                          </span>
                          <span className='mx-1'>
                            {et.PROCESSO_ETAPAS_CONCLUIDA
                              ? new Date(
                                  `${et.PROCESSO_ETAPAS_DATA_FINAL}`,
                                ).toLocaleDateString('pt-BR')
                              : processo.ETAPA_ATUAL_ID ===
                                et.PROCESSO_ETAPAS_ID
                              ? new Date(
                                  `${et.PROCESSO_ETAPAS_DATA_INICIAL}`,
                                ).toLocaleDateString('pt-BR')
                              : ''}
                          </span>
                          <span className='mx-1'>
                            {et.PROCESSO_ETAPAS_CONCLUIDA ? (
                              <IoCheckmarkDoneOutline
                                className={`svg14 ${
                                  et.PROCESSO_ETAPAS_CONCLUIDA
                                    ? ' fc-green'
                                    : ' fc-grey-light'
                                }`}
                              />
                            ) : (
                              <>
                                <GiSandsOfTime
                                  className={`svg14 fc-blue mx-2`}
                                />
                                <span className='mx-1'>
                                  {et.PROCESSO_ETAPAS_CONCLUIDA
                                    ? ''
                                    : et.ETAPAS_DIAS_ESTIMATIVA === 1
                                    ? 'Hoje'
                                    : `+ ${et.ETAPAS_DIAS_ESTIMATIVA} dias`}
                                </span>
                              </>
                            )}
                          </span>
                        </span>
                      </div>
                      <div className='toggle-button-div'>
                        <IconsUI
                          info={ui.action.expand}
                          clsnm='hov-svg-soft hov-green'
                          click={() => {
                            const el = document.getElementById(
                              `ands_${et.PROCESSO_ETAPAS_ID}`,
                            );
                            el.classList.toggle('div-hide');
                          }}
                        />
                      </div>
                    </div>
                    <div
                      id={`ands_${et.PROCESSO_ETAPAS_ID}`}
                      className={
                        processo.ETAPA_ATUAL_ID === et.PROCESSO_ETAPAS_ID
                          ? 'div-atual '
                          : 'div-hide'
                      }
                    >
                      <div
                        className={`d-flex flex-row justify-content-between softxt f08 ${
                          etapas[index].PROCESSO_ETAPAS_CONCLUIDA
                            ? 'topbuttons tb-done'
                            : 'topbuttons'
                        }`}
                      >
                        <ConcluidoModal
                          key={`modal_done_${et.PROCESSO_ETAPAS_ID}`}
                          ind={index}
                          etapaNome={et.ETAPAS_NOME}
                          id_concluido={et.PROCESSO_ETAPAS_ID}
                          data_inicial={et.PROCESSO_ETAPAS_DATA_INICIAL}
                          show={concluidosModal[index]}
                          handleSave={() => handleSave(index)}
                          handleClose={() => handleClose(index)}
                          handleDate={() => handleChangeDataConcluido(index)}
                          setShow={() =>
                            handleToggleModal(
                              index,
                              'concluido',
                              concluidosModal,
                            )
                          }
                        />
                        <span>
                          <Form.Check
                            checked={etapas[index].PROCESSO_ETAPAS_CONCLUIDA}
                            type='checkbox'
                            id={`done_${et.PROCESSO_ETAPAS_ID}`}
                            key={`done_${et.PROCESSO_ETAPAS_ID}`}
                            label='Concluída'
                            onChange={(e) => {
                              setEtapas(() => {
                                const newStates = [...etapas];
                                newStates[index].PROCESSO_ETAPAS_CONCLUIDA =
                                  e.target.checked;
                                return newStates;
                              });
                              e.target.checked
                                ? handleToggleModal(
                                    index,
                                    'concluido',
                                    concluidosModal,
                                  )
                                : handleClose(index);
                            }}
                          />
                        </span>
                        <div>
                          <OverlayTrigger
                            placement='top'
                            id={`tt_modal_ands_${et.PROCESSO_ETAPAS_ID}`}
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>Adicionar Andamento</Tooltip>}
                          >
                            <span className='c-green ms-2'>
                              <IconsUI
                                info={ui.action.add.reg}
                                click={() =>
                                  handleToggleModal(
                                    index,
                                    'andamento',
                                    andamentosModal,
                                  )
                                }
                              />
                            </span>
                          </OverlayTrigger>
                          <AndamentosModal
                            key={`modal_ands_${et.PROCESSO_ETAPAS_ID}`}
                            show={andamentosModal[index]}
                            proc_etapa_id={et.PROCESSO_ETAPAS_ID}
                            updated={setUpdated}
                            setShow={() =>
                              handleToggleModal(
                                index,
                                'andamento',
                                andamentosModal,
                              )
                            }
                          />
                          <OverlayTrigger
                            placement='top'
                            id={`tt_modal_docs_${et.PROCESSO_ETAPAS_ID}`}
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>Adicionar Documento</Tooltip>}
                          >
                            <span className='c-green ms-2'>
                              <IconsUI
                                info={ui.action.upload.reg}
                                click={() =>
                                  handleToggleModal(
                                    index,
                                    'documento',
                                    documentosModal,
                                  )
                                }
                              />
                            </span>
                          </OverlayTrigger>
                          <DocumentosModal
                            key={`modal_docs_${et.PROCESSO_ETAPAS_ID}`}
                            show={documentosModal[index]}
                            updated={setUpdated}
                            docs={documentos}
                            proc_etapa_id={et.PROCESSO_ETAPAS_ID}
                            procs={andamentos[index]}
                            setShow={() =>
                              handleToggleModal(
                                index,
                                'documento',
                                documentosModal,
                              )
                            }
                          />
                          <OverlayTrigger
                            placement='top'
                            id={`tt_modal_msg_${et.PROCESSO_ETAPAS_ID}`}
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>Enviar Mensagem</Tooltip>}
                          >
                            <span className='c-green ms-2'>
                              <IconsUI
                                info={ui.social.message}
                                click={() =>
                                  handleToggleModal(
                                    index,
                                    'mensagem',
                                    mensagemModal,
                                  )
                                }
                              />
                            </span>
                          </OverlayTrigger>

                          <MensagemModal
                            key={`modal_msg_${et.PROCESSO_ETAPAS_ID}`}
                            updated={setUpdated}
                            show={mensagemModal[index]}
                            proc_etapa_id={et.PROCESSO_ETAPAS_ID}
                            procs={andamentos[index]}
                            setShow={() =>
                              handleToggleModal(
                                index,
                                'mensagem',
                                mensagemModal,
                              )
                            }
                          />
                          <OverlayTrigger
                            placement='top'
                            id={`tt_modal_agenda_${et.PROCESSO_ETAPAS_ID}`}
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>Criar um Agendamento</Tooltip>}
                          >
                            <span className='c-green ms-2'>
                              <IconsUI
                                info={ui.social.calendar.add}
                                click={() =>
                                  handleToggleModal(
                                    index,
                                    'agendamento',
                                    agendamentosModal,
                                  )
                                }
                              />
                            </span>
                          </OverlayTrigger>

                          <AgendamentoModal
                            key={`modal_agenda_${et.PROCESSO_ETAPAS_ID}`}
                            updated={setUpdated}
                            show={agendamentosModal[index]}
                            proc_etapa_id={et.PROCESSO_ETAPAS_ID}
                            procs={andamentos[index]}
                            setShow={() =>
                              handleToggleModal(
                                index,
                                'agendamento',
                                agendamentosModal,
                              )
                            }
                          />

                          <OverlayTrigger
                            placement='top'
                            id={`tt_modal_task_${et.PROCESSO_ETAPAS_ID}`}
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>Nova Tarefa</Tooltip>}
                          >
                            <span className='c-green ms-2'>
                              <IconsUI
                                info={ui.misc.tasks}
                                click={() =>
                                  handleToggleModal(
                                    index,
                                    'tarefa',
                                    tarefasModal,
                                  )
                                }
                              />
                            </span>
                          </OverlayTrigger>

                          <TarefaModal
                            updated={setUpdated}
                            show={tarefasModal[index]}
                            key={`modal_task_${et.PROCESSO_ETAPAS_ID}`}
                            proc_etapa_id={et.PROCESSO_ETAPAS_ID}
                            procs={andamentos[index]}
                            setShow={() =>
                              handleToggleModal(index, 'tarefa', tarefasModal)
                            }
                          />
                        </div>
                      </div>
                      {andamentos.map((and) => {
                        return (
                          <AndamentosLine
                            key={and.CODIGO}
                            and={and}
                            upd={handleUpdate}
                            handleModal={() =>
                              handleToggleModal(
                                index,
                                'andamento',
                                andamentosModal,
                              )
                            }
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Processo;
