import {
  IconsUI, ui,
  EmailButton,
  InstagramButton,
  setup,
  TelegramButton,
  WhatsappButton,
} from '../../config/imports';

export default function Footer() {
  const y = new Date().getFullYear();

  return (
    <footer className='footer'>
      <div className='d-flex flex-row justify-content-center align-items-baseline f08'>
        <p className='footer-sp-fale'>Fale conosco: </p>
        <InstagramButton />
        <WhatsappButton />
        <TelegramButton />
        <EmailButton />
      </div>
      <span className='text-center footer-sp-reg f08'>
        {setup.title} <IconsUI info={ui.misc.registered} /> Todos os direitos reservados{' '}
        <IconsUI info={ui.misc.copyright} /> {y}
      </span>
    </footer>
  );
}
