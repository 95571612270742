import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  api,
  end,
  Footer,
  GlobalContext,
  IconsUI,
  LoadingDots,
  NavBar,
  NavReturn,
  roles,
  ui,
} from '../config/imports';

export default function User() {
  const { checkLogin, navigate } = useContext(GlobalContext);
  const { post } = api;
  const { user, users_roles } = end;
  const { codigo } = useParams();

  const [data, setData] = useState({});
  const [dataRoles, setDataRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [logged, setLogged] = useState(true);
  const [role, setRole] = useState('');

  useEffect(() => {
    const status = checkLogin();
    setLogged(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get_user = useCallback(async () => {
    setLoading(true);
    try {
      const [userData, userRoles] = await Promise.all([
        post(user.read.user, {
          codigo,
        }),
        post(user.read.roles, {
          codigo,
        }),
      ]);
      setData(userData.data[0]);
      setDataRoles(userRoles.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [codigo, post, user]);

  const delete_role = useCallback(
    async (COD) => {
      setLoading(true);
      try {
        await post(users_roles.delete, {
          user_codigo: data.CODIGO,
          codigo: COD,
        });
        await get_user();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [data, get_user, post, users_roles],
  );

  useEffect(() => {
    get_user();
  }, [codigo, get_user]);

  const button_on = (active) => {
    if (active)
      return (
        <Badge
          className='txt-200 bad-35'
          bg='success'
        >
          ON
        </Badge>
      );
    return (
      <Badge
        className='txt-200 bad-35 fc-black'
        bg='light'
      >
        OFF
      </Badge>
    );
  };

  if (!logged) {
    navigate('/logout');
  } else {
    if (loading) {
      return (
        <>
          <LoadingDots />
        </>
      );
    }

    return (
      <div className='habita-main'>
        <NavBar />
        <div className='habita-content mb-5'>
          <div className='habita-main-content'>
            <NavReturn info={data.NOME} />
            <table className='mt-2 mb-1 f09'>
              <tbody>
                <tr>
                  <td className='py-1 px-3'>Nome</td>
                  <td className='py-1 px-3'>{data.NOME}</td>
                </tr>
                <tr>
                  <td className='py-1 px-3'>CPF</td>
                  <td className='py-1 px-3'>{data.CPF}</td>
                </tr>
                <tr>
                  <td className='py-1 px-3'>Email</td>
                  <td className='py-1 px-3'>{data.EMAIL}</td>
                </tr>
                <tr>
                  <td className='py-1 px-3'>Telefone</td>
                  <td className='py-1 px-3'>{data.TELEFONE}</td>
                </tr>
                <tr>
                  <td className='py-1 px-3'>Perfil</td>
                  <td className='py-1 px-3'>{data.ROLE_DESCRIPTION}</td>
                </tr>
              </tbody>
            </table>
            <div>
              <h6 className='mt-4 mb-1 fc-blue'>
                {dataRoles && dataRoles.length > 0
                  ? 'Perfis adicionais'
                  : 'Não há outros perfis cadastrados para o usuário'}
              </h6>
              {dataRoles &&
                dataRoles.length > 0 &&
                dataRoles.map((role) => (
                  <div
                    key={`${role.id}_${role.ROLE_DESCRIPTION}`}
                    className='d-flex justify-content-between wp-400'
                  >
                    <span className='wp-250'>{role.ROLE_DESCRIPTION}</span>
                    <span className='wp-40'>{button_on(role.ACTIVE)}</span>

                    <span>
                      <IconsUI
                        info={ui.action.delete.trash}
                        clsnm='fc-red hov-green'
                        tooltip='Remover perfil'
                        click={async () => {
                          Swal.fire({
                            title: 'Tem certeza?',
                            text: 'Você deseja deletar esse perfil do usuário?',
                            icon: 'warning',
                            showDenyButton: true,
                            showCancelButton: false,
                            confirmButtonColor: '#c41000',
                            confirmButtonText: 'Sim, deletar',
                            iconColor: '#c41000',
                            denyButtonColor: '#6cbe00',
                            denyButtonText: 'Não',
                          }).then((result) => {
                            if (result.isConfirmed) {
                              Swal.fire('Ok, deletado!', '', 'success');
                              delete_role(role.CODIGO);
                            }
                          });

                          // setLoading(true);
                          // try {
                          //   await post(users_roles.delete, {
                          //     user_codigo: data.CODIGO,
                          //     codigo: role.CODIGO
                          //   });
                          //   await get_user();
                          //   setLoading(false);
                          // } catch (error) {
                          //   console.error(error);
                          //   setLoading(false);
                          // }
                        }}
                      />
                    </span>
                    <span></span>
                  </div>
                ))}
            </div>
            <div>
              <h6 className='mt-5 mb-3'>Adicionar perfil ao usuário</h6>
              <div className='d-flex flex-row align-items-center'>
                <Form.Select
                  aria-label='perfil'
                  size='sm'
                  className='wp-250'
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value=''>Selecione um perfil</option>
                  {roles.map((role) => (
                    <option
                      key={`${role.id}_${data.CODIGO}_${role.description}`}
                      value={role.id}
                    >
                      {role.description}
                    </option>
                  ))}
                </Form.Select>
                <Button
                  variant='primary'
                  size='sm'
                  className='ms-2'
                  onClick={async () => {
                    if (!role) {
                      return;
                    }
                    setLoading(true);

                    try {
                      await post(users_roles.create, {
                        user_codigo: data.CODIGO,
                        role_id: role,
                      });
                      await get_user();
                      setLoading(false);
                    } catch (error) {
                      console.error(error);
                      setLoading(false);
                    }
                  }}
                >
                  Adicionar perfil
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
