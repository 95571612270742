import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import { EtapaIcon } from '../config/imports';

function Flow({ clsnm = '' }) {
  return (
    <div className={`cont-1300 ${clsnm}`}>
      <h3 className='w-hc-title-section softxt'>Como funciona?</h3>
      <Timeline position='alternate'>
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align='right'
            variant='body2'
            className='f13 fw500 softxt fc-grey'
          >
            1
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot className='bg-white'>
              <EtapaIcon
                etapa='simulação'
                clsnm='svg20 hov-svg'
              />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '11px', px: 2 }}>
            <Typography
              variant='h6 softxt f12 fw600 fc-blue'
              component='span'
              className=''
            >
              Simulação
            </Typography>
            <Typography>
              Tudo começa com a simulação, usando as informações do imóvel e do
              comprador, analisando os tipos de linha de crédito e as taxas
              aplicadas ao perfil.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            variant='body2'
            className='f13 fw500 softxt fc-grey'
          >
            2
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot className='bg-white'>
              <EtapaIcon
                etapa='mindmap'
                clsnm='svg20 hov-svg'
              />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '11px', px: 2 }}>
            <Typography
              variant='h6 softxt f12 fw600 fc-blue'
              component='span'
              className=''
            >
              Escolha da instituição financeira
            </Typography>
            <Typography>
              Depois de ter as simulacoes em mãos, é hora de escolher a
              instituição financeira que melhor atende as suas necessidades.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align='right'
            variant='body2'
            className='f13 fw500 softxt fc-grey'
          >
            3
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot className='bg-white'>
              <EtapaIcon
                etapa='documentação do vendedor'
                clsnm='svg20 hov-svg'
              />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '11px', px: 2 }}>
            <Typography
              variant='h6 softxt f12 fw600 fc-blue'
              component='span'
              className=''
            >
              Dossiê
            </Typography>
            <Typography>
              Com a instituição escolhida, é hora de montar o dossiê com todos
              os documentos necessários para a análise de crédito, reunindo os
              documentos de todas as partes envolvidas.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            variant='body2'
            className='f13 fw500 softxt fc-grey'
          >
            4
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot className='bg-white'>
              <EtapaIcon
                etapa='assinatura de formulários'
                clsnm='svg20 hov-svg'
              />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '11px', px: 2 }}>
            <Typography
              variant='h6 softxt f12 fw600 fc-blue'
              component='span'
              className=''
            >
              Formulários Bancários
            </Typography>
            <Typography>
              Com o dossiê pronto, é hora de preencher os formulários bancários
              e enviar para a instituição financeira, que fará a análise de
              crédito.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align='right'
            variant='body2'
            className='f13 fw500 softxt fc-grey'
          >
            5
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot className='bg-white'>
              <EtapaIcon
                etapa='aprovação de crédito'
                clsnm='svg20 hov-svg'
              />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '11px', px: 2 }}>
            <Typography
              variant='h6 softxt f12 fw600 fc-blue'
              component='span'
              className=''
            >
              Análise de Crédito
            </Typography>
            <Typography>
              Com os formulários preenchidos, a instituição financeira fará a
              análise de crédito, verificando a capacidade de pagamento do
              comprador e a viabilidade do negócio.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            variant='body2'
            className='f13 fw500 softxt fc-grey'
          >
            6
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot className='bg-white'>
              <EtapaIcon
                etapa='avaliação de engenharia'
                clsnm='svg20 hov-svg'
              />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '11px', px: 2 }}>
            <Typography
              variant='h6 softxt f12 fw600 fc-blue'
              component='span'
              className=''
            >
              Avaliação do Imóvel
            </Typography>
            <Typography>
              Com a análise de crédito aprovada, é hora de fazer a avaliação do
              imóvel, verificando o valor do bem e a documentação do imóvel,
              sendo a vistoria acompanhada geralmente pelo corretor ou vendedor.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align='right'
            variant='body2'
            className='f13 fw500 softxt fc-grey'
          >
            7
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot className='bg-white'>
              <EtapaIcon
                etapa='análise jurídica'
                clsnm='svg20 hov-svg'
              />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '11px', px: 2 }}>
            <Typography
              variant='h6 softxt f12 fw600 fc-blue'
              component='span'
              className=''
            >
              Análise Jurídica
            </Typography>
            <Typography>
              Com a avaliação do imóvel aprovada, é hora de fazer a análise
              jurídica, verificando a documentação do imóvel e do comprador,
              garantindo que tudo esteja em conformidade com a legislação
              vigente, para a emissão do contrato.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            variant='body2'
            className='f13 fw500 softxt fc-grey'
          >
            8
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot className='bg-white'>
              <EtapaIcon
                etapa='assinatura do contrato'
                clsnm='svg20 hov-svg'
              />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '11px', px: 2 }}>
            <Typography
              variant='h6 softxt f12 fw600 fc-blue'
              component='span'
              className=''
            >
              Assinatura do Contrato
            </Typography>
            <Typography>
              Com a análise jurídica aprovada, é hora de assinar o contrato de
              financiamento, formalizando a compra e venda do imóvel, com a
              presença do comprador, vendedor, e instituição financeira.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align='right'
            variant='body2'
            className='f13 fw500 softxt fc-grey'
          >
            9
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot className='bg-white'>
              <EtapaIcon
                etapa='cartório'
                clsnm='svg20 hov-svg'
              />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '11px', px: 2 }}>
            <Typography
              variant='h6 softxt f12 fw600 fc-blue'
              component='span'
              className=''
            >
              Registro em Cartório
            </Typography>
            <Typography>
              Com o contrato assinado, é hora de registrar a compra e venda do
              imóvel em cartório, formalizando a transação e garantindo a
              segurança jurídica do negócio.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            variant='body2'
            className='f13 fw500 softxt fc-grey'
          >
            Fim
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot className='bg-white'>
              <EtapaIcon
                etapa='finalizado'
                clsnm='svg20 hov-svg'
              />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '11px', px: 2 }}>
            <Typography
              variant='h6 softxt f12 fw600 fc-blue'
              component='span'
              className=''
            >
              Liberação de Recursos
            </Typography>
            <Typography>
              Com o registro em cartório, a instituição financeira libera os
              recursos para o vendedor, e a compra e venda do imóvel é
              finalizada.
            </Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </div>
  );
}

export default Flow;
