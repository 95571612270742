import React, { useContext, useEffect, useState } from 'react';

import { GlobalContext, conf, Dashboard } from '../config/imports';

function Public() {
  const { checkLogin } = useContext(GlobalContext);
  const [logged, setLogged] = useState(false);

  useEffect(() => {
    const status = checkLogin();
    setLogged(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (logged)
    return (
      <>
        <Dashboard />
      </>
    );

  return (
    <div className='App'>
      <header className='wrapper'>
        <img
          src={conf.images.logo512}
          className='home-logo m-5'
          alt='logo'
        />

        <a
          className='App-link'
          href='/login'
          target='_self'
          rel='noopener noreferrer'
        >
          Login
        </a>
      </header>
    </div>
  );
}

export default Public;
