function rev(str) {
  return str.split('').rev().join('');
}

function create_link_whatsapp(phone_number) {
  const base = 'https://api.whatsapp.com/send?phone=55';

  const phone = phone_number.length > 0 ? phone_number.replace(/\D+/g, '') : '';
  return `${base}${phone}`;
}

const curr = (valor) => {
  return valor
    ? parseFloat(valor).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    : 'R$ 0,00';
};

const float = (valor) => {
  valor = valor.toString().replace('R$', '');
  valor = valor.replace(' ', '');
  valor = valor.replace('.', '');
  valor = valor.replace(',', '.');
  valor = parseFloat(valor);
  return parseFloat(valor);
};

const data_br = (data) => {
  return data ? new Date(data).toLocaleDateString('pt-BR') : '';
};

const data_int = (dateString) => {
  let parts = dateString.split('/');
  return (
    parts[2] +
    '-' +
    parts[1].toString().padStart(2, '0') +
    '-' +
    parts[0].toString().padStart(2, '0')
  );
};

const add_space_comma = (str) => {
  return str.split(',').join(', ');
};

const in_bool = (raw) => {
  return raw ? 'sim' : (raw = null ? '' : 'não');
};

function createOption(fatherElement, value, classe) {
  let newOption = document.createElement('option');
  newOption.value = value;
  newOption.innerText = value;
  newOption.className = classe;
  fatherElement.appendChild(newOption);
}

function createSelect(arr, father) {
  for (let i = 0; i < arr.length; i++) {
    let newOption = document.createElement('option');
    newOption.value = arr[i];
    newOption.innerText = arr[i];
    document.getElementById(father).appendChild(newOption);
  }
}

function createSelectProp(
  arr,
  father,
  prop,
  value_id = false,
  value_col = 'ID',
  data = 'info',
  clsnm = 'py-1',
  addblank = true,
) {
  document.getElementById(father).innerHTML = '';
  if (addblank) {
    let newOption = document.createElement('option');
    newOption.value = '';
    newOption.innerText = '';
    newOption.className = clsnm;
    document.getElementById(father).appendChild(newOption);
  }

  for (let i = 0; i < arr.length; i++) {
    let newOption = document.createElement('option');
    newOption.className = clsnm;
    value_id
      ? (newOption.value = arr[i][value_col])
      : (newOption.value = arr[i][prop]);
    newOption.innerText = arr[i][prop];
    newOption.setAttribute('info', arr[i][data]);
    document.getElementById(father).appendChild(newOption);
  }
}

function is_true(arr = []) {
  return arr.some((element) => element === 1 || element === true);
}

function add_listeners(array_id, array_event, callback, ids = true) {
  array_id.forEach((id) => {
    array_event.forEach((item) => {
      if (ids) {
        document.getElementById(id).addEventListener(item, (event) => {
          callback(event);
        });
      } else {
        id.addEventListener(item, (event) => {
          callback(event);
        });
      }
    });
  });
}

function money_mask(field) {
  let valor = field.value.replace(/[^\d]+/gi, '').reverse();
  let resultado = '';
  let mascara = '#.###.###.###,##'.reverse();
  for (let x = 0, y = 0; x < mascara.length && y < valor.length; ) {
    if (mascara.charAt(x) !== '#') {
      resultado += mascara.charAt(x);
      x++;
    } else {
      resultado += valor.charAt(y);
      y++;
      x++;
    }
  }
  field.value = resultado.reverse();
}

function back_float(field) {
  let newInt = field.value;
  newInt = newInt.replaceAll('.', '');
  newInt = newInt.replace(',', '.');
  return newInt;
}

function calculoImovelPrice(
  valor,
  taxa,
  imovel = false,
  parcelas = 360,
  entrada = 0.2,
) {
  taxa = parseFloat(taxa) / 100;
  let A = Math.pow(1 + taxa, parcelas);
  let B = A * taxa;
  let C = A - 1;
  let D = B / C;
  let E;
  if (imovel) {
    E = valor * entrada;
    let P = (valor - E) * D;
    return { imovel: valor, parcela: P, entrada: E };
  } else {
    let imv = valor / D / (1 - entrada);
    E = imv * entrada;
    return { imovel: imv, parcela: valor, entrada: E };
  }
}

function calculoImovelSac(
  valor,
  taxa,
  imovel = false,
  parcelas = 420,
  entrada = 0.2,
) {
  taxa = parseFloat(taxa) / 100;
  if (imovel) {
    let E = valor * entrada;
    let F = valor - E;
    let A = F / parcelas;
    let P = A + taxa * F;
    return { imovel: valor, parcela: P, entrada: E };
  } else {
    let imv = valor / ((1 - entrada) * (1 / parcelas + taxa));
    let E = imv * entrada;
    return { imovel: imv, parcela: valor, entrada: E };
  }
}

export {
  add_listeners,
  add_space_comma,
  back_float,
  calculoImovelPrice,
  calculoImovelSac,
  createOption,
  createSelect,
  createSelectProp,
  create_link_whatsapp,
  curr,
  data_br,
  data_int,
  float,
  in_bool,
  is_true,
  money_mask,
  rev
};
