import Button from 'react-bootstrap/Button';
import { links, IconsUI, ui } from '../../config/imports';

export default function SimuleButton({ sm = false }) {
  if (sm)
    return (
      <a
        href={links.simule}
        target='_blank'
        rel='noreferrer'
        className='btn outline-secondary w-btn-hover'
      >
        <IconsUI info={ui.misc.adjust} />
      </a>
    );
  return (
    <a
      href={links.simule}
      target='_blank'
      rel='noreferrer'
    >
      <Button
        variant='primary'
        size='lg'
        className='w-btn-primary-simule'
      >
        <IconsUI info={ui.misc.adjust} />
        <span className='ms-2'>Faça uma simulação</span>
      </Button>
    </a>
  );
}
