import React from 'react';
import { conf } from '../config/imports';

function Page500() {
  return (
    <div className='App'>
      <header className='wrapper'>
        <a href='/'>
          <img
            src={conf.images.logo512}
            className='home-logo'
            alt='logo'
          />
        </a>
        conf.images.logo512
        <p>Não autorizado</p>
      </header>
    </div>
  );
}

export default Page500;
