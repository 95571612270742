import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { RotatingSquare } from 'react-loader-spinner';
// import DragAndDrop from '../DragAndDrop';

import { FaRegEye } from 'react-icons/fa';
import {
  IoIosAddCircleOutline,
  IoIosNotificationsOutline,
  IoIosRemoveCircleOutline,
} from 'react-icons/io';
import { IoEyeOffOutline } from 'react-icons/io5';

import {
  api,
  end,
  GlobalContext,
  PriorityDropdown,
  SelectOptions,
} from '../../config/imports';

function DocumentosModal({ proc_etapa_id, show, setShow, docs, updated }) {
  const [uploading, setUploading] = useState(false);
  const [notificar, setNotificar] = useState(false);
  const [visivel, setVisivel] = useState(false);
  const [prioridadeSelecionada, setPrioridadeSelecionada] = useState(3);
  const [childCount, setChildCount] = useState(0);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [filesName, setFilesName] = useState([]);
  const [filesType, setFilesType] = useState([]);
  const [docsType, setDocsType] = useState([]);

  const handlePrioridadeChange = (novaPrioridade) => {
    setPrioridadeSelecionada(novaPrioridade);
  };
  const { a } = useContext(GlobalContext);
  const { prioridades } = a;

  const handleAddClick = () => {
    setChildCount((prevCount) => prevCount + 1);
  };

  const handleRemoveClick = () => {
    if (childCount > 0) {
      setChildCount((prevCount) => prevCount - 1);
      setUploadFiles(() => {
        const newStates = [...uploadFiles];
        newStates.pop();
        return newStates;
      });

      setFiles(() => {
        const newStates = [...files];
        newStates.pop();
        return newStates;
      });

      setFilesName(() => {
        const newStates = [...filesName];
        newStates.pop();
        return newStates;
      });
      setFilesType(() => {
        const newStates = [...filesType];
        newStates.pop();
        return newStates;
      });
      setDocsType(() => {
        const newStates = [...docsType];
        newStates.pop();
        return newStates;
      });
    }
  };

  const handleFileChange = (e) => {
    setUploadFiles(() => {
      const newStates = [...uploadFiles];
      newStates.push(e.target.id);
      return newStates;
    });

    setFiles(() => {
      const newStates = [...files];
      newStates.push(e.target.files[0]);
      return newStates;
    });

    setFilesName(() => {
      const newStates = [...filesName];
      newStates.push(e.target.files[0].name);
      return newStates;
    });
    setFilesType(() => {
      const newStates = [...filesType];
      newStates.push(e.target.files[0].type);
      return newStates;
    });
  };

  const uploadFilesToServer = async () => {
    setUploading(true);

    try {
      const uploadPromises = [];

      for (let i = 0; i < childCount; i += 1) {
        const formData = new FormData();
        formData.append('files', files[i]);
        formData.append('processo_etapa_id', proc_etapa_id);
        formData.append('documento_type', docsType[i]);
        formData.append('files_name', filesName[i]);
        formData.append('files_type', filesType[i]);
        formData.append('prioridade_id', prioridadeSelecionada);
        formData.append('visivel_cliente', visivel ? 1 : 0);
        formData.append('notificar_escritorio', notificar ? 1 : 0);
        formData.append('andamento_id', 2);

        const uploadPromise = api.postFiles(end.and.create.doc, formData);
        uploadPromises.push(uploadPromise);
      }

      await Promise.all(uploadPromises);
      updated();
    } catch (error) {
      console.error('Erro ao fazer upload:', error);
    } finally {
      setUploading(false);
    }
    updated();
  };

  return (
    <>
      <Modal
        show={show}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        backdrop='static'
        keyboard={false}
        centered
        onHide={() => setShow(false)}
      >
        <Modal.Header>
          <Modal.Title className='softxt'>Documentos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex flex-row justify-content-end '>
            <PriorityDropdown
              prioridades={prioridades}
              prior={prioridadeSelecionada}
              processo_etapa_id={proc_etapa_id.processo_etapas_id}
              onPrioridadeChange={handlePrioridadeChange}
            />
            <OverlayTrigger
              placement='top'
              id={`tt_notificar_${proc_etapa_id.processo_etapas_id}`}
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip>{notificar ? '' : 'Não '}Notificar Escritório</Tooltip>
              }
            >
              <span className={`ms-1 ${notificar ? 'c-green' : 'c-grey'}`}>
                <IoIosNotificationsOutline
                  onClick={() => setNotificar(!notificar)}
                />
              </span>
            </OverlayTrigger>

            <OverlayTrigger
              placement='top'
              id={`tt_visivel_${proc_etapa_id.processo_etapas_id}`}
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip>{visivel ? '' : 'Não '}Visível ao Cliente</Tooltip>
              }
            >
              <span
                className={`ms-1 ${visivel ? 'c-green' : 'c-grey'}`}
                onClick={() => setVisivel(!visivel)}
              >
                {visivel ? <FaRegEye /> : <IoEyeOffOutline />}
              </span>
            </OverlayTrigger>
          </div>
          <div id={`form_files_${proc_etapa_id.processo_etapas_id}`}>
            {[...Array(childCount)].map((_, index) => (
              <div
                key={`form_file_${index + 1}`}
                className='my-3'
              >
                <label
                  className='form-label f06 my-0'
                  htmlFor={`form_files_sel_${
                    proc_etapa_id.processo_etapas_id
                  }_file_${index + 1}`}
                >
                  Que tipo de arquivo você vai carregar?
                </label>
                <select
                  size='sm'
                  name={`form_files_sel_${
                    proc_etapa_id.processo_etapas_id
                  }_file_${index + 1}`}
                  id={`form_files_sel_${
                    proc_etapa_id.processo_etapas_id
                  }_file_${index + 1}`}
                  className='form-control f08 mb-1'
                  onChange={() => {
                    const selected = document.getElementById(
                      `form_files_sel_${
                        proc_etapa_id.processo_etapas_id
                      }_file_${index + 1}`,
                    ).value;
                    setDocsType(() => {
                      const newStates = [...docsType];
                      newStates.push(selected);
                      return newStates;
                    });
                  }}
                >
                  <SelectOptions
                    docs={docs}
                    prop='nome'
                    id={`form_files_sel_${
                      proc_etapa_id.processo_etapas_id
                    }_file_op_${index + 1}`}
                  />
                </select>

                <input
                  type='file'
                  className='form-control f08'
                  onChange={handleFileChange}
                  id={`form_files_${proc_etapa_id.processo_etapas_id}_file_${
                    index + 1
                  }`}
                  name={`form_files_${proc_etapa_id.processo_etapas_id}_file_${
                    index + 1
                  }`}
                />
              </div>
            ))}
          </div>
          <div className='mt-1 d-flex flex-row justify-content-end '>
            <div>
              <Button
                variant='outline-success'
                className='me-2'
                onClick={handleAddClick}
              >
                <IoIosAddCircleOutline />
              </Button>
              <Button
                variant='outline-danger'
                className='me-2'
                onClick={handleRemoveClick}
              >
                <IoIosRemoveCircleOutline />
              </Button>
            </div>
            <div>
              {uploading ? (
                <RotatingSquare
                  visible={true}
                  height='80'
                  width='80'
                  color='#6cbe00'
                  ariaLabel='rotating-square-loading'
                  wrapperStyle={{}}
                  wrapperClass=''
                />
              ) : (
                <Button
                  variant='primary'
                  className='ms-3 softxt'
                  size='sm'
                  onClick={() => {
                    uploadFilesToServer();
                    setShow(false);
                  }}
                >
                  Enviar Arquivos
                </Button>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='light'
            onClick={() => {
              setUploadFiles([]);
              setFilesName([]);
              setFilesType([]);
              setDocsType([]);
              setChildCount(0);
              setShow(false);
            }}
          >
            Sair
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DocumentosModal;
