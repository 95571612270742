import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Spinner from 'react-bootstrap/esm/Spinner';

import {
  api,
  end,
  GlobalContext,
  PriorityDropdown,
} from '../../config/imports';

import { FaLock, FaPen, FaRegEye } from 'react-icons/fa';
import { GoBellFill } from 'react-icons/go';
import { IoEyeOffOutline } from 'react-icons/io5';

// verificar iconsui

function AndamentosModal({ proc_etapa_id, show, setShow, updated }) {
  const { a } = useContext(GlobalContext);
  const { prioridades } = a;

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [andamento, setAndamento] = useState('');
  const [notificar, setNotificar] = useState(false);
  const [visivel, setVisivel] = useState(false);

  const [nota, setNota] = useState('');

  const [prioridadeSelecionada, setPrioridadeSelecionada] = useState(3);

  const handlePrioridadeChange = (novaPrioridade) => {
    setPrioridadeSelecionada(novaPrioridade);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      setLoading(true);
      const res = await api.post(end.and.create.andamento, {
        processo_etapa_id: proc_etapa_id,
        andamento_id: 1,
        visivel_cliente: visivel,
        notificar_escritorio: notificar,
        notificacao_ativa: notificar,
        prioridade_id: prioridadeSelecionada,
        title,
        andamento,
        nota,
      });
      console.log(res);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);

    setTimeout(() => {
      setLoading(false);
      setShow(false);
    }, 1000);
    updated();
  };

  return (
    <>
      <Modal
        show={show}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        onHide={setShow}
      >
        <Modal.Header closeButton>
          <Modal.Title className='softxt'>Andamentos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form.Control
              required
              type='text'
              id='title'
              value={title}
              placeholder='Título'
              className='form-control input-cur w100 my-1'
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
            <Form.Group
              className='mb-3'
              id={`new_andamento_${proc_etapa_id}`}
            >
              <div className='d-flex flex-row justify-content-start align-items-center'>
                <span className='me-1 h15'>
                  <FaPen />
                </span>
                <span className='softxt f08'>Anotação</span>

                <OverlayTrigger
                  placement='top'
                  id={`tt_notificar_${proc_etapa_id}`}
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>
                      {notificar ? '' : 'Não '}Notificar Escritório
                    </Tooltip>
                  }
                >
                  <span className={`ms-1 ${notificar ? 'c-green' : 'c-grey'}`}>
                    <GoBellFill onClick={() => setNotificar(!notificar)} />
                  </span>
                </OverlayTrigger>

                <OverlayTrigger
                  placement='top'
                  id={`tt_visivel_${proc_etapa_id}`}
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>{visivel ? '' : 'Não '}Visível ao Cliente</Tooltip>
                  }
                >
                  <span
                    className={`ms-1 ${visivel ? 'c-green' : 'c-grey'}`}
                    onClick={() => setVisivel(!visivel)}
                  >
                    {visivel ? <FaRegEye /> : <IoEyeOffOutline />}
                  </span>
                </OverlayTrigger>
                <OverlayTrigger
                  placement='top'
                  id={`tt_prioridade_${proc_etapa_id}`}
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>Prioridade</Tooltip>}
                >
                  <PriorityDropdown
                    prioridades={prioridades}
                    prior={prioridadeSelecionada}
                    processo_etapa_id={proc_etapa_id}
                    onPrioridadeChange={handlePrioridadeChange}
                  />
                </OverlayTrigger>
              </div>
              <Form.Control
                as='textarea'
                rows={3}
                onChange={(e) => setAndamento(e.target.value)}
              />
            </Form.Group>

            <Form.Group
              className='mb-3'
              id={`new_nota_${proc_etapa_id}`}
            >
              <Form.Label>
                <div className='d-flex flex-row justify-content-around align-items-center'>
                  <div>
                    <span className='me-1 h15'>
                      <FaLock />
                    </span>
                    <span className='softxt f08'>Nota Interna</span>
                  </div>
                </div>
              </Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                onChange={(e) => setNota(e.target.value)}
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <Spinner
              animation='border'
              variant='success'
              size='sm'
            />
          ) : (
            <>
              <Button
                size='sm'
                variant='light'
                onClick={setShow}
              >
                Sair
              </Button>
              <Button
                size='sm'
                variant='primary'
                onClick={handleSave}
              >
                Adicionar Andamento
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AndamentosModal;
