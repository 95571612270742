import React, { useState } from 'react';

import { conf, Flow, Footer, Simulador } from '../config/imports';

import '../assets/css/app/botapp.css';

export default function TelegramApp() {
  const [section, setSection] = useState('home');

  const selectSection = () => {
    switch (section) {
      case 'simulador':
        return (
          <Simulador
            full={false}
            showlogo={false}
          />
        );

      // case 'register':
      //   return (
      //     <Register
      //       showlogo={false}
      //       bot={true}
      //     />
      //   );
      default:
        return <Flow clsnm=' mt-3 f08' />;
    }
  };

  const buttons = () => {
    return (
      <div className='d-flex flex-row justify-content-around align-items-center'>
        <button
          className='btn btn-outline-primary btn-sm'
          onClick={() => {
            setSection('simulador');
          }}
        >
          Simulador
        </button>
        {/* 
        <button
          className='btn btn-outline-primary btn-sm'
          onClick={() => {
            setSection('register');
          }}
        >
          Quero me cadastrar
        </button> */}
      </div>
    );
  };

  return (
    <div className='botapp'>
      <header className='header'>
        <img
          src={conf.images.logotext512}
          className='nav-logo logo-txt wp-200 my-2'
          alt={`${conf.tenant} logo`}
          onClick={() => {
            setSection('home');
          }}
        />
      </header>
      <main className=''>
        <div>{buttons()}</div>
        <div>{selectSection()}</div>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}
