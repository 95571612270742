import React from 'react';
import { conf } from '../config/imports';

function Thanks() {
  return (
    <div className='App'>
      <header className='wrapper'>
        <a
          href='/'
          className=''
        >
          <img
            src={conf.images.logo512}
            className='mb-5 w80 mw400'
            alt='logo'
          />
        </a>
        <p className=' p-2 text-center softxt'>
          Recebemos suas informações e entraremos em contato em breve.
        </p>
      </header>
    </div>
  );
}

export default Thanks;
