import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { BiSupport } from 'react-icons/bi';
import { MdOpenInNew } from 'react-icons/md';
import '../../assets/css/app/support.css';

import {
  GlobalContext,
  Loading,
  PriorityMenu,
  api,
  end,
} from '../../config/imports';

function Support() {
  const { checkLogin, categories } = useContext(GlobalContext);
  const { support } = end;
  const { post } = api;
  const [titulo, setTitulo] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [message, setMessage] = useState('');
  const [meusTickets, setMeusTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [prioridadeSelecionada, setPrioridadeSelecionada] = useState(3);
  const [categoria, setCategoria] = useState(1);

  const [isLogged, setIsLogged] = useState(false);

  const user = sessionStorage.getItem('@App:user');
  const email = sessionStorage.getItem('@App:mail');
  const codigo = sessionStorage.getItem('@App:codigo');

  useEffect(() => {
    const logged = checkLogin();
    setIsLogged(logged);
  }, [checkLogin]);

  const handlePrioridadeChange = (novaPrioridade) => {
    setPrioridadeSelecionada(novaPrioridade);
  };
  const obj = {
    section: 'support',
  };

  useEffect(() => {
    const getTickets = async () => {
      try {
        const response = await post(support.read, {
          user: user,
          email: email,
          codigo: codigo,
          scope: 'm',
        });
        const data = response.data;
        setMeusTickets(data.data);
      } catch (error) {
        setMessage(error);
        console.log(error);
      }
    };
    getTickets();
  }, [user, email, codigo, post, support]);

  const sendTicket = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await post(support.create, {
        user: user.user,
        email: user.email,
        user_code: user.codigo,
        subject: titulo,
        message: mensagem,
        priority: prioridadeSelecionada,
        category: categoria,
      });
      const response = await post(support.read, {
        user: user.user,
        email: user.email,
        scope: 'm',
      });
      const data = response.data;
      setMeusTickets(data);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }

  if (isLogged) {
    return (
      <div>
        <h4 className='d-flex flex-row align-items-center mb-5'>
          <BiSupport />
          <span className='ms-2'>Suporte</span>
        </h4>
        <h5 className='mb-3'>Olá, {user}!</h5>
        <p className='mt-2 mb-4 f09'>
          Preencha abaixo os dados para enviar uma solicitação de suporte
        </p>

        <p className='d-flex flex-row my-0'>
          <span className='sup-sp-title'>Solicitante</span>
          <span className='sup-sp-value'>{user}</span>
        </p>
        <p className='d-flex flex-row my-0'>
          <span className='sup-sp-title'>E-mail</span>
          <span className='sup-sp-value'>{email}</span>
        </p>

        <div className='d-flex flex-row align-items-center mt-2'>
          <span className='me-2'>Prioridade</span>
          <PriorityMenu
            obj={obj}
            prior={prioridadeSelecionada}
            onPrioridadeChange={handlePrioridadeChange}
            sizeFont=''
          />
        </div>

        <div className='d-flex flex-row align-items-center mt-3'>
          <span className='me-2'>Categoria</span>
          <select
            className='form-select'
            onChange={({ target: { value } }) => setCategoria(value)}
          >
            {categories.map((category) => (
              <option
                key={`key_cat_${category.c}_sup`}
                value={category.c}
              >
                {category.nome}
              </option>
            ))}
          </select>
        </div>

        <form
          action=''
          className='w-100 mt-4'
        >
          <Form.Label
            htmlFor='cpf'
            className='input-label'
          >
            Título
          </Form.Label>
          <Form.Control
            type='text'
            id='titulo'
            autoComplete='titulo'
            name='titulo'
            aria-describedby='titulo'
            onChange={({ target: { value } }) => setTitulo(value)}
            className='mb-4'
          />
          <Form.Label
            htmlFor='mensagem'
            className='input-label'
          >
            Mensagem
          </Form.Label>
          <Form.Control
            as='textarea'
            id='mensagem'
            autoComplete='mensagem'
            name='mensagem'
            aria-describedby='mensagem'
            onChange={({ target: { value } }) => setMensagem(value)}
            className='mb-4'
          />
          <p>
            <span className='text-danger'>{message}</span>
          </p>
          <Button
            variant='primary'
            type='submit'
            className='mt-4'
            onClick={sendTicket}
          >
            Enviar
          </Button>
        </form>

        <div>
          <h5 className='mt-5 mb-3'>Meus Tickets</h5>
          <div>
            {meusTickets.length > 0 ? (
              <table className='table'>
                <thead>
                  <tr>
                    <th scope='col'>Data</th>
                    <th scope='col'>Título</th>
                    <th scope='col'>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {meusTickets.map((ticket) => (
                    <tr key={ticket.codigo}>
                      <td>
                        {new Date(`${ticket.created_at}`).toLocaleString(
                          'pt-BR',
                        )}
                      </td>
                      <td>{ticket.subject}</td>
                      <td>{ticket.status}</td>
                      <td>
                        <MdOpenInNew />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className='f09'>Não há tickets cadastrados</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Support;
