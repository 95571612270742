import { IconsUI, links, ui } from '../../config/imports';

export default function EmailButton() {
  return (
    <a
      href={links.mail}
      target='_blank'
      rel='noreferrer'
      className='btn outline-secondary w-btn-hover'
    >
      <IconsUI info={ui.social.email} />
    </a>
  );
}
