import React from 'react';

import {
  Authorize,
  img_admin,
  img_clientes,
  img_financ,
  img_processos,
  img_simulacao,
  img_tasks,
} from '../config/imports';

export default function RenderSectionButton({ section, classn, clk }) {
  const role_id = sessionStorage.getItem('@App:ri');

  let render;

  switch (section) {
    case 'simulacoes':
      if (Authorize(role_id, 7))
        return (
          <div
            onClick={clk}
            className={classn}
          >
            <img
              src={img_simulacao}
              alt=''
            />
            <span>simulações</span>
          </div>
        );
      break;
    case 'tasks':
      if (Authorize(role_id, 5))
        return (
          <div
            onClick={clk}
            className={classn}
          >
            <img
              src={img_tasks}
              alt=''
            />
            <span>tasks</span>
          </div>
        );
      break;

    case 'financiamentos':
      if (Authorize(role_id, 5))
        return (
          <div
            onClick={clk}
            className={classn}
          >
            <img
              src={img_financ}
              alt=''
            />
            <span>financiamentos</span>
          </div>
        );
      break;

    case 'processos':
      if (Authorize(role_id, 7))
        return (
          <div
            onClick={clk}
            className={classn}
          >
            <img
              src={img_processos}
              alt=''
            />
            <span>processos</span>
          </div>
        );
      break;

    case 'clientes':
      if (Authorize(role_id, 5))
        return (
          <div
            onClick={clk}
            className={classn}
          >
            <img
              src={img_clientes}
              alt=''
            />
            <span>clientes</span>
          </div>
        );
      break;

    case 'admin':
      if (Authorize(role_id, 4))
        return (
          <div
            onClick={clk}
            className={classn}
          >
            <img
              src={img_admin}
              alt=''
            />
            <span>administração</span>
          </div>
        );
      break;

    default:
      return '';
  }

  return render;
}
