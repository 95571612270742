import React, { useContext } from 'react';
import { GlobalContext, IconsUI, ui } from '../../config/imports';

export default function AppButton() {
  const { navigate } = useContext(GlobalContext);
  return (
    <span
      className='btn outline-secondary w-btn-hover'
      onClick={() => {
        navigate('/app');
      }}
    >
      <IconsUI info={ui.misc.app} />
    </span>
  );
}
