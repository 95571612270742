import React, { useContext, useState } from 'react';
import { Badge, Dropdown } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaCircle } from 'react-icons/fa';
import { GlobalContext } from '../config/imports';

const PriorityMenu = ({ obj, prior, onPrioridadeChange, sizeFont = 'f09' }) => {
  const { a } = useContext(GlobalContext);
  const { prioridades } = a;
  const [priorityColor, setPriorityColor] = useState(
    prioridades[prior - 1].CODIGO,
  );
  const [priorityName, setPriorityName] = useState(prioridades[prior - 1].NOME);

  const handlePrioritySelect = async (priority) => {
    setPriorityColor(prioridades.find((item) => item.ID === priority).CODIGO);
    setPriorityName(prioridades.find((item) => item.ID === priority).NOME);
    onPrioridadeChange(priority);
  };

  return (
    <Dropdown className='m-0'>
      <Dropdown.Toggle
        variant=''
        size='sm'
        id={`drop_prioridades_menu_${obj.section}`}
        className='drop-prioridades'
      >
        <span
          variant={priorityColor}
          size='sm'
          className={`softxt ${sizeFont} d-flex flex-row align-items-center `}
        >
          <FaCircle className={`fc-${priorityColor} svg12`} />
          <OverlayTrigger
            placement='top'
            id={`tt_drop_prioridade`}
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip>Prioridade</Tooltip>}
          >
            <span className='mx-1'>{priorityName}</span>
          </OverlayTrigger>
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu
        size='sm'
        className='drop-menu-out'
      >
        {prioridades.map((priority, index) => (
          <Dropdown.Item
            key={`drop_prioridades_sect__${obj.section}_${priority.ID}_${index}`}
          >
            <Badge
              bg={priority.CODIGO}
              key={`drop_prioridades_sect_${obj.section}_${priority.ID}_${index}_badg`}
              onClick={() => handlePrioritySelect(priority.ID)}
            >
              {priority.NOME}
            </Badge>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default PriorityMenu;
