import React, { useContext, useEffect, useState } from 'react';
import { FaPersonShelter, FaUser } from 'react-icons/fa6';
import { HiMiniAdjustmentsVertical } from 'react-icons/hi2';
import { MdOutlineRealEstateAgent } from 'react-icons/md';
import { TbTimeline } from 'react-icons/tb';

import {
  GlobalContext,
  Loading,
  NewsCharts,
  NewsTimelineItem,
  api,
  conf,
  end,
} from '../../config/imports';

function News() {
  const { checkLogin, setShowSection } = useContext(GlobalContext);
  const { latest, monthes, numbs } = end;
  const [logged, setLogged] = useState(false);

  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [numbers, setNumbers] = useState([]);
  const [months, setMonths] = useState([]);

  useEffect(() => {
    const logged = checkLogin();
    setLogged(logged);

    const fetchData = async () => {
      setLoading(true);
      try {
        const [latestResponse, numbsResponse, monthesResponse] =
          await Promise.all([
            api.post(latest, { brand: conf.brand }),
            api.post(numbs, { brand: conf.brand }),
            api.post(monthes, { brand: conf.brand }),
          ]);

        setNews(latestResponse.data);
        setNumbers(numbsResponse.data[0]);
        setMonths(monthesResponse.data);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };

    if (logged) {
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (!logged) return null;

  return (
    <>
      <p>Seus Números</p>

      <div className='gocol1180 mb-4'>
        <div className='row align-items-center justify-content-center flex-wrap f08 mb-4 mx-1'>
          <div
            className='card-numbs'
            onClick={() => setShowSection('simulacoes')}
          >
            <div className='card bg-info text-white h-100'>
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='me-3 text-center'>
                    <div className='text-white-75 small'>Simulações</div>
                    <div className='text-lg fw-bold'>{numbers.SIMULACOES}</div>
                  </div>
                  <HiMiniAdjustmentsVertical className='svg20' />
                </div>
              </div>
            </div>
          </div>

          <div
            className='card-numbs'
            onClick={() => setShowSection('processos')}
          >
            <div className='card bg-primary text-white h-100'>
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='me-3 text-center'>
                    <div className='text-white-75 small'>Processos</div>
                    <div className='text-lg fw-bold'>{numbers.PROCESSOS}</div>
                  </div>
                  <TbTimeline className='svg20' />
                </div>
              </div>
            </div>
          </div>

          <div className='card-numbs'>
            <div className='card bg-success text-white h-100'>
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='me-3 text-center'>
                    <div className='text-white-75 small'>Crédito gerado</div>
                    <div className='text-lg fw-bold'>
                      {parseFloat(numbers.TOTAL_IMOVEL_VALOR).toLocaleString(
                        'pt-BR',
                        { style: 'currency', currency: 'BRL' },
                      )}
                    </div>
                  </div>
                  <TbTimeline className='svg20' />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row align-items-center justify-content-center flex-wrap f08 mb-4 mx-1'>
          <div
            className='card-numbs'
            onClick={() => setShowSection('clientes')}
          >
            <div className='card bg-warning text-white h-100'>
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='me-3 text-center'>
                    <div className='text-white-75 small'>Clientes</div>
                    <div className='text-lg fw-bold'>{numbers.CLIENTES}</div>
                  </div>
                  <FaUser className='svg20' />
                </div>
              </div>
            </div>
          </div>

          <div
            className='card-numbs'
            onClick={() => setShowSection('imobiliarias')}
          >
            <div className='card bg-danger text-white h-100'>
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='me-3 text-center'>
                    <div className='text-white-75 small'>Imobiliárias</div>
                    <div className='text-lg fw-bold'>
                      {numbers.IMOBILIARIAS}
                    </div>
                  </div>
                  <MdOutlineRealEstateAgent className='svg20' />
                </div>
              </div>
            </div>
          </div>

          <div
            className='card-numbs'
            onClick={() => setShowSection('corretores')}
          >
            <div className='card bg-secondary text-white h-100'>
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='me-3 text-center'>
                    <div className='text-white-75 small'>Corretores</div>
                    <div className='text-lg fw-bold'>{numbers.CORRETORES}</div>
                  </div>
                  <FaPersonShelter className='svg20' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p className='softxt'>Últimas atualizações</p>
      <ul className='fc-regular li-news mb-3'>
        {news &&
          news.map((item, index) => (
            <NewsTimelineItem
              item={item}
              key={`news_${item.CODIGO}_${index}`}
            />
          ))}
      </ul>

      <div className='my-5'>
        <p className='softxt'>Seus números</p>
        {months.length > 0 && <NewsCharts data={months} />}
      </div>
    </>
  );
}

export default News;
