import { IconsUI, links, ui } from '../../config/imports';

export default function LoginButton() {
  return (
    <a
      href={links.login}
      target='_blank'
      rel='noreferrer'
      className='btn outline-secondary w-btn-hover'
    >
      <IconsUI info={ui.user.lock} />
    </a>
  );
}
