import React from 'react';

export default function Loading(class_op) {
  return (
    <div className={`text-center w100 p-3 ${class_op}`}>
      <div
        className='spinner-border text-primary'
        role='status'
      >
        <span className='visually-hidden'>Loading...</span>
      </div>
    </div>
  );
}
