import { auth } from './endpoints';
import { post } from './requests';

export default async function reset(email, cpf, password, otp) {
  const response = await post(auth.reset, {
    email,
    cpf,
    password,
    otp,
  });

  return response;
}
