import Carousel from 'react-bootstrap/Carousel';

export default function CarouselCaption({ label = '', sub = '' }) {
  return (
    <Carousel.Caption>
      <div className='carousel-caption-back'>
        <h2 className='my-3'>{label}</h2>
        <p>{sub}</p>
      </div>
    </Carousel.Caption>
  );
}
