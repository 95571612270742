import Carousel from 'react-bootstrap/Carousel';

import {
  CarouselCaption,
  CarouselImage,
  SimuleButton,
  img01,
  img03,
  img05,
  img06,
  img07,
  img11,
} from '../config/imports';

export default function HomeCarousel() {
  return (
    <div className='w-carousel my-4'>
      <Carousel fade>
        <Carousel.Item>
          <CarouselImage image={img01} />
          <CarouselCaption
            label='A sua casa nova a um clique de distância'
            sub={<SimuleButton />}
          />
        </Carousel.Item>
        <Carousel.Item>
          <CarouselImage image={img03} />
          <CarouselCaption
            label='Escolha onde VIVER'
            sub={<SimuleButton />}
          />
        </Carousel.Item>
        <Carousel.Item>
          <CarouselImage image={img06} />
          <CarouselCaption
            label='Ou mesmo financiar seu próprio projeto'
            sub={<SimuleButton />}
          />
        </Carousel.Item>
        <Carousel.Item>
          <CarouselImage image={img05} />
          <CarouselCaption
            label='Nós cuidaremos de tudo'
            sub={<SimuleButton />}
          />
        </Carousel.Item>

        <Carousel.Item>
          <CarouselImage image={img07} />
          <CarouselCaption
            label='Para que você tenha as melhores lembranças'
            sub={<SimuleButton />}
          />
        </Carousel.Item>

        <Carousel.Item>
          <CarouselImage image={img11} />
          <CarouselCaption
            label='Cuidamos de toda a documentação'
            sub={<SimuleButton />}
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
