import { setup } from '../config/imports';

export default function CarouselImage({ image, alt = setup.title }) {
  return (
    <img
      className='d-block w-100 w-carousel-img'
      src={image}
      alt={alt}
    />
  );
}
