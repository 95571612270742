import React, { useContext, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaLock, FaRegEye } from 'react-icons/fa';
import { GoBellFill } from 'react-icons/go';
import { IoEyeOffOutline } from 'react-icons/io5';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { ProgressBar } from 'react-loader-spinner';

import {
  AndamentoIcon,
  api,
  BlobViewer,
  end,
  GlobalContext,
  helpers,
  PriorityDropdown,
  StatusDropdown,
} from '../config/imports';

function AndamentosLine({ and, upd, c = false }) {
  const { post } = api;
  const { fileIcon } = helpers;
  const { a } = useContext(GlobalContext);
  const { prioridades, status } = a;

  const [loading, setLoading] = useState(false);

  const handlePrioridadeChange = async (novaPrioridade) => {
    // setPrioridade(novaPrioridade);
    setLoading(true);
    try {
      await post(end.and.update.prioridade, {
        codigo: and.CODIGO,
        prioridade_id: novaPrioridade,
      });
      upd();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleStatusChange = async (novoStatus) => {
    // setStatus(novoStatus);
    setLoading(true);
    try {
      await post(end.and.update.status, {
        codigo: and.CODIGO,
        status: novoStatus,
      });
      upd();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await post(end.and.delete, {
        codigo: and.CODIGO,
      });
      upd();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleUpdateNotificar = async () => {
    setLoading(true);
    try {
      await post(end.and.update.notificar, {
        codigo: and.CODIGO,
        notificar_escritorio: !and.NOTIFICAR_ESCRITORIO,
      });
      upd();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleUpdateVisivel = async () => {
    setLoading(true);
    try {
      await post(end.and.update.visivel, {
        codigo: and.CODIGO,
        visivel_cliente: !and.VISIVEL_CLIENTE,
      });
      upd();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  // const fileIcon = (type) => {
  //   if (type.includes('image'))
  //     return <FaRegFileImage className="svg09 fc-green" />;
  //   if (type.includes('pdf'))
  //     return <FaRegFilePdf className="svg09 fc-green" />;
  //   return <FaRegFile className="svg09 fc-green" />;
  // };

  return (
    <div
      key={`andamento_${and.ETAPA_ID}_${and.CODIGO}`}
      className='my-3'
    >
      <div className='d-flex flex-column w100'>
        <div className='d-flex flex-row justify-content-between head-and'>
          <div className='d-flex flex-row align-items-center '>
            <span className='me-2 fc-blue'>
              <AndamentoIcon and={and.ANDAMENTO_ID} />
            </span>
            <span className='f07 me-2'>
              {new Date(and.UPDATED_AT).toLocaleString('pt-BR').split(',')[0]}
            </span>
            {c ? (
              ''
            ) : (
              <PriorityDropdown
                prioridades={prioridades}
                prior={and.PRIORIDADE_ID}
                processo_etapa_id={and.ETAPA_ID}
                onPrioridadeChange={handlePrioridadeChange}
              />
            )}

            {(and.ANDAMENTO_ID === 1 || and.ANDAMENTO_ID === 2) &&
            c === false ? (
              <>
                <span>
                  <OverlayTrigger
                    placement='top'
                    id={`and_tt_notificar_${and.ETAPA_ID}`}
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip>
                        {and.NOTIFICAR_ESCRITORIO ? '' : 'Não '}Notificar
                        Escritório
                      </Tooltip>
                    }
                  >
                    <span
                      className={`ms-1 ${
                        and.NOTIFICAR_ESCRITORIO ? 'c-green' : 'c-grey'
                      }`}
                    >
                      <GoBellFill
                        onClick={() => {
                          handleUpdateNotificar();
                        }}
                      />
                    </span>
                  </OverlayTrigger>
                </span>
                <span>
                  <OverlayTrigger
                    placement='top'
                    id={`and_tt_visivel_${and.ETAPA_ID}`}
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip>
                        {and.VISIVEL_CLIENTE ? '' : 'Não '}Visível ao Cliente
                      </Tooltip>
                    }
                  >
                    <span
                      className={`ms-1 ${
                        and.VISIVEL_CLIENTE ? 'c-green' : 'c-grey'
                      }`}
                      onClick={() => {
                        handleUpdateVisivel();
                      }}
                    >
                      {and.VISIVEL_CLIENTE ? <FaRegEye /> : <IoEyeOffOutline />}
                    </span>
                  </OverlayTrigger>
                </span>
              </>
            ) : (
              ''
            )}
            {and.ANDAMENTO_ID === 3 && c === false ? (
              <StatusDropdown
                status={status}
                stat={and.STATUS_ID}
                processo_etapa_id={and.ETAPA_ID}
                onStatusChange={handleStatusChange}
              />
            ) : (
              ''
            )}
            <span className='ms-3 f07 fc-grey'>por {and.CREATED_BY_NOME}</span>
          </div>
          <div>
            {loading ? (
              <ProgressBar
                visible={true}
                height='40'
                width='130'
                barColor='#C41000'
                borderColor='#6B2C2B'
                ariaLabel='progress-bar-loading'
                wrapperStyle={{}}
                wrapperClass=''
              />
            ) : c ? (
              ''
            ) : (
              <span>
                <OverlayTrigger
                  placement='top'
                  id={`and_tt_del_${and.ETAPA_ID}`}
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>Remover Andamento</Tooltip>}
                >
                  <span className={`ms-1 c-red`}>
                    <RiDeleteBin6Fill onClick={() => handleDelete()} />
                  </span>
                </OverlayTrigger>
              </span>
            )}
          </div>
        </div>
        <div className='d-flex flex-row align-items-center'>
          <div className='d-flex flex-column w100'>
            {and.TITLE !== null ? (
              <span>
                <span className='f09 txt-500 mb-0 fc-black'>{and.TITLE}</span>
                {and.PRAZO !== null ? (
                  <span className='f08 ms-2'>
                    {'prazo: '}
                    {new Date(and.PRAZO).toLocaleString('pt-BR').split(',')[0]}
                  </span>
                ) : (
                  ''
                )}
              </span>
            ) : (
              ''
            )}
            {and.ANDAMENTO !== null ? (
              <span className='f08 mb-0 fc-regular'>
                <span className='sp-and-content'>{and.ANDAMENTO}</span>
              </span>
            ) : (
              ''
            )}
            {and.NOTA !== null && and.NOTA !== '' ? (
              <>
                <hr className='m-0 mt-1 wp-150 fc-grey' />
                <div className='f08 fc-grey d-flex flex-row align-items-baseline'>
                  <span className='me-2 h10'>
                    <FaLock />
                  </span>
                  <span className='sp-and-content'>{and.NOTA}</span>
                </div>
              </>
            ) : (
              ''
            )}
            {and.FILES_TYPE ? (
              <div className='col-12'>
                <BlobViewer
                  blobIcon={fileIcon(and.FILES_TYPE)}
                  blobCode={and.CODIGO}
                  blobType={and.FILES_TYPE}
                  blobName={and.DOCUMENTO_TYPE}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AndamentosLine;
