import { IconsUI, links, ui } from '../../config/imports';

export default function WhatsappButton() {
  return (
    <a
      href={links.whatsapp}
      target='_blank'
      rel='noreferrer'
      className='btn outline-secondary w-btn-hover'
    >
      <IconsUI info={ui.social.whatsapp} />
    </a>
  );
}
