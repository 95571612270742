import React, { useContext, useEffect, useState } from 'react';
import { FaBuildingFlag } from 'react-icons/fa6';
import { RiShieldUserFill } from 'react-icons/ri';
import { VscAccount } from 'react-icons/vsc';

import { GlobalContext } from '../../config/imports';

function Account() {
  const { checkLogin, userData } = useContext(GlobalContext);
  const [isLogged, setIsLogged] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    const logged = checkLogin();
    setIsLogged(logged);

    async function data() {
      const res = await userData();
      setUser(res);
    }
    data();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLogged) {
    return (
      <div>
        <h4 className='d-flex flex-row align-items-center mb-5'>
          <VscAccount />
          <span className='ms-2'>Minha Conta</span>
        </h4>
        {/* <h5 className='mb-3'>Olá, {user}!</h5> */}
        <p className='mt-2 mb-1 f09'>Abaixo estão seus dados:</p>
        <table className='mt-2 mb-1 f09'>
          <tbody>
            <tr>
              <td className='py-1 px-3'>Nome</td>
              <td className='py-1 px-3'>{user.NOME}</td>
            </tr>
            <tr>
              <td className='py-1 px-3'>CPF</td>
              <td className='py-1 px-3'>{user.CPF}</td>
            </tr>
            <tr>
              <td className='py-1 px-3'>Email</td>
              <td className='py-1 px-3'>{user.EMAIL}</td>
            </tr>
            <tr>
              <td className='py-1 px-3'>Telefone</td>
              <td className='py-1 px-3'>{user.TELEFONE}</td>
            </tr>
            <tr>
              <td className='py-1 px-3'>Perfil</td>
              <td className='py-1 px-3'>{user.ROLE_DESCRIPTION}</td>
            </tr>
          </tbody>
        </table>

        <div className='my-5'>
          <h4>
            <RiShieldUserFill />
            <span className='ms-2'>Solicitar alteração de perfil</span>
          </h4>
          <p>
            {/* Atualmente o seu perfil é: <b>{user.role} </b> */}
            <br />
            Se você entende que é necessário modificar seu perfil, abra uma
            solicitação de suporte, utilizando o motivo "Alteração de perfil".
          </p>
        </div>

        {user.role_id === 6 ? (
          <div className='my-5'>
            <h4>
              <FaBuildingFlag />
              <span className='ms-2'>Minhas Imobiliárias</span>
            </h4>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default Account;
