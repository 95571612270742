import { auth } from './endpoints';
import { post } from './requests';

export default async function recover(email, cpf) {
  const response = await post(auth.recover, {
    email,
    cpf,
  });

  return response;
}
