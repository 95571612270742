import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { IMaskInput } from 'react-imask';
import '../../assets/css/app/financiamentos.css';
import {
  conf,
  faixas,
  financ,
  financiar,
  helpers,
  IconsUI,
  RendaGraph,
  ui,
  variacoesCEF,
} from '../../config/imports';

function Financiamentos({
  vr_renda = 0,
  vr_imovel = 0,
  vr_entrada = 0,
  dt_nascimento = '',
  fgts_3anos = false,
  beneficiado_mcmv = false,
  possui_imovel_reg = false,
  ismodal = false,
  full = false,
}) {
  const {
    calcularMeses,
    classTr,
    evaluateFaixas,
    taxaPoupanca,
    tooltipIcons,
    tooltipParcela,
    tooltip_faixa,
  } = financ;

  const { mascaraMoeda, numberFormat, numberReFormat } = helpers;

  const [variacoes, setVariacoes] = useState(variacoesCEF);
  const [linhas, setLinhas] = useState(faixas);
  const [moneyCurrency, setMoneyCurrency] = useState({
    imovel: mascaraMoeda(parseFloat(vr_imovel).toFixed(2)),
    entrada: mascaraMoeda(parseFloat(vr_entrada).toFixed(2)),
    renda: mascaraMoeda(parseFloat(vr_renda).toFixed(2)),
  });
  const [moneyValues, setMoneyValues] = useState({
    imovel: vr_imovel,
    entrada: vr_entrada,
    renda: vr_renda,
  });

  const parcelaMaxInicial = moneyValues.renda * 0.3;

  const [parcelas, setParcelas] = useState(360);
  const [nascimento, setNascimento] = useState(dt_nascimento);
  const [parcelaMax, setParcelaMax] = useState(parcelaMaxInicial);
  const [fgts, setFgts] = useState(fgts_3anos);
  const [beneficiado, setBeneficiado] = useState(beneficiado_mcmv);
  const [possuiImovel, setPossuiImovel] = useState(possui_imovel_reg);

  const [graphMaxRenda, setGraphMaxRenda] = useState(10000);
  const [graphMaxImovel, setGraphMaxImovel] = useState(700000);

  const handleMoney = (e) => {
    const { name } = e.target;
    const valueReformatted = mascaraMoeda(e.target.value);
    const valueNumber = numberReFormat(valueReformatted);
    // setMoneyCurrency({ ...moneyCurrency, [name]: valueReformatted });
    // setMoneyValues({ ...moneyValues, [name]: valueNumber });
    if (name === 'renda') {
      let max = valueNumber * 0.3;
      setParcelaMax(max);
      setMoneyCurrency({ ...moneyCurrency, renda: valueReformatted });
      setMoneyValues({ ...moneyValues, renda: valueNumber });
    } else if (name === 'imovel') {
      let entrada = valueNumber * 0.2;
      setMoneyCurrency({
        ...moneyCurrency,
        entrada: mascaraMoeda(entrada.toFixed(2)),
        imovel: valueReformatted,
      });
      setMoneyValues({ ...moneyValues, entrada, imovel: valueNumber });
    } else {
      setMoneyCurrency({ ...moneyCurrency, [name]: valueReformatted });
      setMoneyValues({ ...moneyValues, [name]: valueNumber });
    }
  };

  const handleRenda = (valor) => {
    const valueReformatted = mascaraMoeda(valor);
    setMoneyCurrency({ ...moneyCurrency, renda: valueReformatted });
    setMoneyValues({ ...moneyValues, renda: valor });
    let max = valor * 0.3;
    setParcelaMax(max);
  };

  const handleImovel = (valor) => {
    const valueReformatted = mascaraMoeda(valor);
    setMoneyCurrency({ ...moneyCurrency, imovel: valueReformatted });
    setMoneyValues({ ...moneyValues, imovel: valor });
    let entrada = valor * 0.2;
    setMoneyCurrency({
      ...moneyCurrency,
      imovel: valueReformatted,
      entrada: mascaraMoeda(entrada.toFixed(2)),
    });
    setMoneyValues({ ...moneyValues, entrada, imovel: valor });
  };

  const handleClick = () => {
    const { imovel, entrada } = moneyValues;
    const financiado = imovel - entrada;
    let results = [];
    let evaluation = evaluateFaixas(
      moneyValues.renda,
      moneyValues.imovel,
      faixas,
    );

    Object.keys(faixas).forEach((key) => {
      let thisTaxa = faixas[key]['taxa'];
      const variacao = faixas[key]['variacao'];
      if (variacao) {
        if (variacao === 'poup') {
          thisTaxa = taxaPoupanca(parseFloat(variacoes['tr'].value), variacoes);
        } else {
          thisTaxa = variacoes[variacao]['value'] + faixas[key]['taxa'];
        }
      }
      if (
        fgts &&
        (faixas[key]['name'].includes('MCMV') ||
          faixas[key]['name'].includes('SBPE')) &&
        !beneficiado &&
        !possuiImovel
      )
        thisTaxa -= 0.5;
      let resultVariacao = variacao
        ? `${variacoes[variacao]['name']}: ${variacoes[variacao]['value']} %`
        : '';

      let fin = new financiar(
        financiado,
        thisTaxa,
        parcelas,
        variacoes.seguro.value,
        variacoes.taxa_admin.value,
        'anual',
        variacoes.maxSac.value,
        variacoes.maxPrice.value,
      );

      let result = fin.result();
      const compativel = result.sacInicial <= parcelaMax;
      const compativelPrice = result.price <= parcelaMax;
      const ev = evaluation[key];
      results.push({
        tabela: key,
        variacao: resultVariacao,
        compativel,
        compativelPrice,
        evaluate: ev,
        tx: parseFloat(thisTaxa),
        ...result,
      });
    });

    return (
      <>
        <>
          {results.map((result) => {
            return (
              <tr
                key={result.tabela}
                className={classTr(result, beneficiado, possuiImovel)}
                data-toggle='tooltip'
                title={
                  classTr(result, beneficiado, possuiImovel) ===
                  'bg-table-grey-light'
                    ? `Já foi beneficiado pelo MCMV ou possui imóvel registrado.
Não é possível utilizar essa faixa.`
                    : ''
                }
              >
                <td
                  className=''
                  data-toggle='tooltip'
                  title={tooltip_faixa(result, faixas)}
                >
                  {faixas[result.tabela]['name']}
                </td>
                <td className=''>
                  <span
                    data-toggle='tooltip'
                    title={tooltipIcons(result, 'renda', faixas, moneyValues)}
                  >
                    <IconsUI
                      info={ui.misc.money}
                      clsnm={`mx-1 svg14 ${
                        result.evaluate && result.evaluate.renda
                          ? 'fc-green'
                          : 'fc-grey'
                      }`}
                    />
                  </span>
                  <span
                    data-toggle='tooltip'
                    title={tooltipIcons(result, 'imovel', faixas, moneyValues)}
                  >
                    <IconsUI
                      info={ui.misc.house}
                      clsnm={`mx-1 svg14 ${
                        result.evaluate && result.evaluate.imovel
                          ? 'fc-green'
                          : 'fc-grey'
                      }`}
                    />
                  </span>
                  <span
                    data-toggle='tooltip'
                    title={tooltipIcons(
                      result,
                      'subsidio',
                      faixas,
                      moneyValues,
                    )}
                  >
                    <IconsUI
                      info={ui.misc.star}
                      clsnm={`mx-1 svg14 ${
                        result.evaluate && result.evaluate.subsidio
                          ? 'fc-green'
                          : 'fc-grey'
                      }`}
                    />
                  </span>
                </td>
                <td
                  className={`hide-600 ${ismodal ? 'div-hide' : ''}`}
                >{`${parseFloat(faixas[result.tabela].taxa).toFixed(2)} %`}</td>
                <td className={`hide-600 ${ismodal ? 'div-hide' : ''}`}>
                  {result.variacao}
                </td>
                <td className=''>{`${parseFloat(result.tx).toFixed(4)} %`}</td>
                <td className={`hide-600 ${ismodal ? 'div-hide' : ''}`}>
                  {result.encargos}
                </td>
                <td
                  data-toggle='tooltip'
                  title={tooltipParcela(result, 'SAC', parcelaMax)}
                >
                  <span
                    className={
                      result.evaluate &&
                      result.evaluate.success &&
                      result.compativel
                        ? 'fc-green-dark'
                        : result.evaluate &&
                          result.evaluate.success &&
                          !result.compativel
                        ? 'fc-danger'
                        : ''
                    }
                  >
                    {result.primeiraParcelaSac}
                  </span>
                  <br />
                  <span
                    className={parcelaMax < result.sacFinal ? 'fc-danger' : ''}
                  >
                    {result.ultimaParcelaSac}
                  </span>
                </td>
                <td
                  data-toggle='tooltip'
                  title={tooltipParcela(result, 'PRICE', parcelaMax)}
                  className={`${
                    result.evaluate &&
                    result.evaluate.success &&
                    result.compativelPrice
                      ? 'fc-green-dark'
                      : result.evaluate &&
                        result.evaluate.success &&
                        !result.compativelPrice
                      ? 'fc-danger'
                      : ''
                  } ${
                    classTr(result, beneficiado, possuiImovel) ===
                      'bg-table-green-light' &&
                    !result.compativel &&
                    result.compativelPrice
                      ? 'single-td-green-light'
                      : ''
                  }`}
                >
                  {result.prestacaoPrice}
                </td>

                <td className=''>
                  <span className='fc-darker-grey'>{result.totalJurosSac}</span>
                  <br />
                  <span>{result.totalPagoSac}</span>
                </td>

                <td className=''>
                  <span className='fc-darker-grey'>
                    {result.totalJurosPrice}
                  </span>
                  <br />
                  <span>{result.totalPagoPrice}</span>
                </td>
              </tr>
            );
          })}
        </>
      </>
    );
  };

  return (
    <div
      className='container-simulador mt-5'
      id='App'
    >
      <header
        data-html2canvas-ignore={true}
        className={`info-simulador ${ismodal ? 'div-hide' : ''} text-center`}
      >
        <img
          src={conf.images.logotext512}
          className='logo-header'
          alt='logo'
        />
        <h5 className='mt-5'>Calculadora de Financiamento Imobiliário</h5>
        <p className={`info-simulador`}>
          Esse cálculo não inclui as taxas e seguros inclusos pelos bancos.
          <br />
          Para um cálculo mais detalhado, preencha o{' '}
          <a href='/simulador'>formulário de simulação</a>
        </p>
      </header>
      <div className='body'>
        <div className='mt-2 d-flex flex-row justify-content-between align-items-center'>
          <span className='f11'>Simulador de Financiamento</span>
          <IconsUI
            info={ui.action.expand}
            clsnm='svg14 fc-blue hov-green'
            click={() => {
              const form_el = document.getElementById('form-simulador');
              form_el.classList.toggle('div-hide');
            }}
          />
        </div>

        <form
          action=''
          id='form-simulador'
          data-html2canvas-ignore={true}
          className={`info-simulador ${ismodal ? 'div-hide' : ''} f09`}
        >
          <div className='bet gocol'>
            <div className='d-flex flex-row gorow1024'>
              <div className='gocol me-3 border-green p-3'>
                <h5 className='mt-1'>Dados do Financiamento</h5>
                <div className='gorow gocol600 justify-content-start'>
                  <span
                    className='sp-lab'
                    htmlFor='imovel'
                  >
                    Valor do Imóvel
                  </span>
                  <input
                    type='text'
                    className='form-control'
                    name='imovel'
                    id='imovel'
                    value={moneyCurrency.imovel}
                    onChange={(e) => handleMoney(e)}
                  />

                  <IconsUI
                    info={ui.action.add.circle}
                    clsnm='mx-2 svg14 fc-blue hov-green'
                    click={() => {
                      const value_imovel = moneyValues.imovel + 100;
                      setMoneyCurrency({
                        ...moneyCurrency,
                        imovel: numberFormat(value_imovel),
                      });
                      setMoneyValues({
                        ...moneyValues,
                        imovel: value_imovel,
                      });
                    }}
                  />
                  <IconsUI
                    info={ui.action.delete.circle}
                    clsnm='mx-2 svg14 fc-blue hov-green'
                    click={() => {
                      const new_value = moneyValues.imovel - 100;
                      setMoneyCurrency({
                        ...moneyCurrency,
                        imovel: numberFormat(new_value),
                      });
                      setMoneyValues({
                        ...moneyValues,
                        imovel: new_value,
                      });
                    }}
                  />
                  <span className='fc-grey'>100</span>
                </div>
                <div>
                  <span id='imovelExt'></span>
                </div>
                <div className='gorow gocol600 justify-content-start align-items-center'>
                  <span
                    className='sp-lab'
                    htmlFor='entrada'
                  >
                    Valor da Entrada
                  </span>
                  <input
                    type='text'
                    className='form-control'
                    name='entrada'
                    id='entrada'
                    value={moneyCurrency.entrada}
                    onChange={(e) => handleMoney(e)}
                  />

                  <IconsUI
                    info={ui.action.add.circle}
                    clsnm='mx-2 svg14 fc-blue hov-green'
                    click={() => {
                      const new_value = moneyValues.entrada + 100;
                      setMoneyCurrency({
                        ...moneyCurrency,
                        entrada: numberFormat(new_value),
                      });
                      setMoneyValues({
                        ...moneyValues,
                        entrada: new_value,
                      });
                    }}
                  />
                  <IconsUI
                    info={ui.action.delete.circle}
                    clsnm='mx-2 svg14 fc-blue hov-green'
                    click={() => {
                      const new_value = moneyValues.entrada - 100;
                      setMoneyCurrency({
                        ...moneyCurrency,
                        entrada: numberFormat(new_value),
                      });
                      setMoneyValues({
                        ...moneyValues,
                        entrada: new_value,
                      });
                    }}
                  />
                  <span className='fc-grey'>100</span>
                </div>
                <span
                  className='par-info'
                  id='info-entrada'
                  onClick={() => {
                    const value_entrada = Number(
                      (moneyValues.imovel / 5).toFixed(2),
                    );
                    setMoneyCurrency({
                      ...moneyCurrency,
                      entrada: numberFormat(value_entrada),
                    });
                    setMoneyValues({
                      ...moneyValues,
                      entrada: value_entrada,
                    });
                  }}
                >
                  {moneyValues.imovel > 0 ? (
                    <span>
                      <span className='f09 fc-grey'>
                        A entrada mínima deve ser de 20% do valor do imóvel:{' '}
                        {numberFormat(moneyValues.imovel / 5)}
                      </span>
                      <br />
                      <span className='hov-green'>
                        Clique aqui para definir esse valor.
                      </span>
                    </span>
                  ) : (
                    ''
                  )}
                </span>
                <div className='gocol'>
                  <div className='gorow gocol600 justify-content-start'>
                    {' '}
                    <span
                      className='sp-lab'
                      htmlFor='renda'
                    >
                      Renda
                    </span>
                    <input
                      type='text'
                      className='form-control'
                      name='renda'
                      id='renda'
                      value={moneyCurrency.renda}
                      onChange={(e) => handleMoney(e)}
                    />
                    <IconsUI
                      info={ui.action.add.circle}
                      clsnm='mx-2 svg14 fc-blue hov-green'
                      click={() => {
                        const new_value = moneyValues.renda + 100;
                        setMoneyCurrency({
                          ...moneyCurrency,
                          renda: numberFormat(new_value),
                        });
                        setMoneyValues({
                          ...moneyValues,
                          renda: new_value,
                        });
                      }}
                    />
                    <IconsUI
                      info={ui.action.delete.circle}
                      clsnm='mx-2 svg14 fc-blue hov-green'
                      click={() => {
                        const new_value = moneyValues.renda - 100;
                        setMoneyCurrency({
                          ...moneyCurrency,
                          renda: numberFormat(new_value),
                        });
                        setMoneyValues({
                          ...moneyValues,
                          renda: new_value,
                        });
                      }}
                    />
                    <span className='fc-grey'>100</span>
                  </div>
                  <span
                    className='par-info'
                    id='info-renda'
                  >
                    {moneyValues.renda > 0 ? (
                      <span>
                        <span className='f09 fc-grey'>
                          A parcela não poderá exceder 30% de sua renda.
                        </span>
                        <br />
                        Sua parcela máxima será de{' '}
                        {numberFormat(moneyValues.renda * 0.3)}
                      </span>
                    ) : (
                      ''
                    )}
                  </span>
                </div>
                <div className='gorow gocol600 justify-content-start'>
                  <span
                    className='sp-lab'
                    htmlFor='nascimento'
                  >
                    Nascimento
                  </span>
                  <Form.Control
                    type='text'
                    as={IMaskInput}
                    mask='00/00/0000'
                    id='nascimento'
                    name='nascimento'
                    required
                    value={nascimento}
                    onChange={(e) => {
                      setNascimento(e.target.value);
                      setParcelas(calcularMeses(e.target.value));
                    }}
                  />
                </div>

                <div className='gorow gocol600 justify-content-start'>
                  <span
                    className='sp-lab'
                    htmlFor='parcelas'
                  >
                    Parcelas (meses)
                  </span>
                  <input
                    type='number'
                    name='parcelas'
                    className='form-control'
                    id='parcelas'
                    value={parcelas}
                    min='12'
                    max='420'
                    maxLength={3}
                    onChange={(e) => setParcelas(e.target.value)}
                  />

                  <IconsUI
                    info={ui.action.add.circle}
                    clsnm='mx-2 svg14 fc-blue hov-green'
                    click={() => {
                      const new_value = parcelas + 12;
                      setParcelas(new_value);
                    }}
                  />
                  <IconsUI
                    info={ui.action.delete.circle}
                    clsnm='mx-2 svg14 fc-blue hov-green'
                    click={() => {
                      const new_value = parcelas - 12;
                      setParcelas(new_value);
                    }}
                  />
                  <span className='fc-grey'>12</span>
                </div>
                <div className='my-2'>
                  <Form.Check // prettier-ignore
                    type='checkbox'
                    id='check_3anos_fgts'
                    label='Possui 3 anos de FGTS'
                    checked={fgts}
                    onChange={(e) => setFgts(e.target.checked)}
                  />
                </div>
                <div className='my-2'>
                  <Form.Check // prettier-ignore
                    type='checkbox'
                    id='beneficiado'
                    label='Já foi beneficiado pelo MCMV'
                    checked={beneficiado}
                    onChange={(e) => setBeneficiado(e.target.checked)}
                  />
                </div>
                <div className='my-2'>
                  <Form.Check // prettier-ignore
                    type='checkbox'
                    id='possui_imovel'
                    label='Possui imóvel registrado'
                    checked={possuiImovel}
                    onChange={(e) => setPossuiImovel(e.target.checked)}
                  />
                </div>
              </div>
              <div className='gocol p-3'>
                <div className='d-flex flex-row align-items-center justify-content-between'>
                  <h5 className='m-1'>Dados Variáveis</h5>
                  <IconsUI
                    info={ui.action.expand}
                    clsnm='svg14 fc-blue  hov-green'
                    click={() => {
                      const div_el = document.getElementById(
                        'div_dados_variaveis',
                      );
                      div_el.classList.toggle('div-hide');
                    }}
                  />
                </div>
                <div
                  className='d-flex flex-wrap ms-3 div-hide'
                  id='div_dados_variaveis'
                >
                  {Object.keys(variacoes).map((key) => {
                    return (
                      <div
                        key={`div_${variacoes[key].id}`}
                        className='gorow mx-2  gocol600'
                      >
                        <span
                          className='sp-lab wp-100'
                          htmlFor={`input_${variacoes[key].id}`}
                        >
                          {variacoes[key].name}
                        </span>
                        <input
                          type='number'
                          step='0.01'
                          className='form-control wp-90'
                          name={`input_${variacoes[key].id}`}
                          id={`input_${variacoes[key].id}`}
                          value={variacoes[key].value}
                          onChange={(e) => {
                            setVariacoes({
                              ...variacoes,
                              [key]: {
                                ...variacoes[key],
                                value: parseFloat(e.target.value),
                              },
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className='p-3'>
              <div className='d-flex flex-row align-items-center justify-content-between'>
                <h5 className='m-1'>Taxas Anuais de Juros %</h5>
                <IconsUI
                  info={ui.action.expand}
                  clsnm='svg14 fc-blue  hov-green'
                  click={() => {
                    const div_el = document.getElementById('div_dados_taxas');
                    div_el.classList.toggle('div-hide');
                  }}
                />
              </div>
              <div
                className='d-flex flex-wrap p-3 gocol600 gocol900 div-hide'
                id='div_dados_taxas'
              >
                {Object.keys(linhas).map((key) => {
                  return (
                    <div
                      className='gorow me-3 ms-5  gocol600'
                      key={key}
                    >
                      <label
                        className='wp-120'
                        htmlFor={key}
                      >
                        {linhas[key].name}
                      </label>
                      <input
                        type='number'
                        step={0.1}
                        className='form-control wp-80 f09 me-2'
                        size='sm'
                        id={key}
                        name='taxa'
                        value={linhas[key].taxa}
                        onChange={(e) => {
                          setLinhas({
                            ...linhas,
                            [key]: {
                              ...linhas[key],
                              taxa: parseFloat(e.target.value),
                            },
                          });
                        }}
                      />
                    </div>
                  );
                })}
                <div className='gorow gocol600 justify-content-start'></div>
              </div>
            </div>

            <span
              className='par-info'
              id='info-taxa'
            ></span>
          </div>
        </form>
      </div>
      <div className={full ? 'd-flex flex-row gocol600' : 'gocol'}>
        <div className={full ? 'mx-2' : ''}>
          <div className='mt-2 d-flex flex-row align-items-center justify-content-between'>
            <span className='f11 fw400'>Análise de Possibilidades</span>
            <IconsUI
              info={ui.action.expand}
              clsnm='svg14 fc-blue  hov-green'
              click={() => {
                const div_el = document.getElementById(
                  'div_financiamentos_result',
                );
                div_el.classList.toggle('div-hide');
              }}
            />
          </div>
          <div id='div_financiamentos_result'>
            <table
              id='resultado'
              className={`table table-striped table-hover table-sm ${
                window.innerWidth < 992 ? ' f08' : ''
              }`}
            >
              <thead>
                <tr>
                  <td className=''>Tabela</td>
                  <td className=''></td>
                  <td className={`hide-600 ${ismodal ? 'div-hide' : ''}`}>
                    Taxa
                  </td>
                  <td className={`hide-600 ${ismodal ? 'div-hide' : ''}`}>
                    Variação
                  </td>
                  <td className=''>Taxa Calc</td>
                  <td className={`hide-600 ${ismodal ? 'div-hide' : ''}`}>
                    Encargos
                  </td>
                  <td className=''>Parcela SAC</td>
                  <td className=''>Parcela Price</td>

                  <td className=''>Total Sac</td>

                  <td className=''>Total Price</td>
                </tr>
              </thead>
              <tbody className='f09'>{handleClick()}</tbody>
            </table>
          </div>
        </div>
        <div className={full ? 'mx-2' : ''}>
          <div className='f09 d-flex flex-row justify-content-between'>
            <div className='gorow gocol600 justify-content-start'>
              <span
                className='sp-lab f08 wp-180'
                htmlFor='maxValorImovel'
              >
                Valor Máximo do Imóvel
              </span>
              <Form.Control
                type='number'
                size='sm'
                className='form-control wp-150'
                name='maxValorImovel'
                id='maxValorImovel'
                value={graphMaxImovel}
                onChange={(e) => setGraphMaxImovel(e.target.value)}
              />
            </div>
            <div className='gorow gocol600 justify-content-start'>
              <span
                className='sp-lab f08 wp-180'
                htmlFor='maxValorRenda'
              >
                Valor Máximo da Renda
              </span>
              <Form.Control
                type='number'
                size='sm'
                className='form-control wp-150'
                name='maxValorRenda'
                id='maxValorRenda'
                value={graphMaxRenda}
                onChange={(e) => setGraphMaxRenda(e.target.value)}
              />
            </div>
          </div>

          <RendaGraph
            defineRenda={(v) => handleRenda(v)}
            defineImovel={(v) => handleImovel(v)}
            maxImovel={graphMaxImovel}
            maxRenda={graphMaxRenda}
          />
        </div>
      </div>
    </div>
  );
}

export default Financiamentos;
