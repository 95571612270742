const roles = [
  {
    id: 2,
    role: 'admin',
    description: 'Administrador',
  },
  {
    id: 3,
    role: 'gestor',
    description: 'Gestor',
  },
  {
    id: 4,
    role: 'corresp',
    description: 'Correspondente',
  },
  {
    id: 5,
    role: 'interno',
    description: 'Interno',
  },
  {
    id: 6,
    role: 'corretor',
    description: 'Corretor',
  },
  {
    id: 7,
    role: 'cliente',
    description: 'Cliente',
  },
];

export default roles;
