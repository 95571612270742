import React, { useEffect, useState } from 'react';
// import React, { useContext, useEffect, useState } from 'react';
import { AiOutlineCheckCircle, AiOutlineCloudUpload } from 'react-icons/ai';
import { MdClear } from 'react-icons/md';
import '../assets/css/app/dragdrop.css';
// import GlobalContext from '../context/GlobalContext';
// import SelectOptions from './SelectOptions';

const DragAndDrop = ({ onFilesSelected, filesSt, docsSt = [] }) => {
  const [files, setFiles] = useState(filesSt);
  // const [docsType, setDocsType] = useState(docsSt);

  // const { a } = useContext(GlobalContext);

  // const { documentos } = a;

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const newFiles = Array.from(selectedFiles);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const newFiles = Array.from(droppedFiles);
      const acceptedFiles = newFiles.filter(
        (file) => file.type.includes('pdf') || file.type.includes('image'),
      );
      if (acceptedFiles.length > 0) {
        setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
      }
      const rejectedFiles = newFiles.filter(
        (file) => !file.type.includes('pdf') && !file.type.includes('image'),
      );
      if (rejectedFiles.length > 0) {
        alert('Apenas arquivos PDF ou imagens são permitidos');
      }
    }
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    onFilesSelected(files);
  }, [files, onFilesSelected]);

  useEffect(() => {
    setFiles(filesSt);
  }, [filesSt]);

  return (
    <section className='mt-4 drag-drop'>
      <div
        className={`document-uploader ${
          files.length > 0 ? 'upload-box active' : 'upload-box'
        }`}
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
      >
        <>
          <div className='upload-info'>
            <AiOutlineCloudUpload />
            <div>
              <p className='softxt'>Arraste seus arquivos aqui</p>
            </div>
          </div>
          <input
            type='file'
            hidden
            id='browse'
            onChange={handleFileChange}
            accept='.pdf,.png,.jpg,.jpeg'
            multiple
          />
          <label
            htmlFor='browse'
            className='browse-btn f08 fc-grey'
          >
            Selecione os arquivos
          </label>
        </>

        {files.length > 0 && (
          <div className='file-list'>
            <div className='file-list__container'>
              {files.map((file, index) => (
                <div
                  className='file-item my-2'
                  key={index}
                >
                  <div className='file-info'>
                    <p>{file.name}</p>
                    {/* <div className='d-flex flex-row align-items-center'> */}
                    {/* <select
                        size='sm'
                        name={`form_files_drag_sim`}
                        id={`form_files_drag_sim`}
                        className='form-control f08'
                        onChange={() => {
                          const selected =
                            document.getElementById(
                              `form_files_drag_sim`,
                            ).value;
                          setDocsType(() => {
                            const newStates = [...docsType];
                            newStates.push(selected);
                            return newStates;
                          });
                        }}
                      >
                        <SelectOptions
                          docs={documentos}
                          prop='NOME'
                          id={`form_files_drag_sim_op`}
                        />
                      </select> */}
                    {/* </div> */}
                  </div>
                  <div className='file-actions ms-1'>
                    <MdClear onClick={() => handleRemoveFile(index)} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {files.length > 0 && (
          <div className='success-file'>
            <AiOutlineCheckCircle className='fc-success me-2' />
            <p>
              {files.length > 1
                ? `${files.length} arquivos selecionados`
                : `${files.length} arquivo selecionado`}{' '}
            </p>
          </div>
        )}
      </div>
    </section>
  );
};

export default DragAndDrop;
