// import { useState, useContext } from 'react';
// import GlobalContext from '../../context/GlobalContext';

const Manager = () => {
  // const { isLogged } = useContext(GlobalContext);

  // if (isLogged) return (
  //   <>
  //     <p>Logged</p>
  //   </>
  // );

  return (
    <>
      <p>Not Logged Manager</p>
    </>
  );
};

export default Manager;
