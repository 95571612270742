import '../../assets/css/app/formheader.css';

import { conf } from '../../config/imports';

export default function FormHeader({formname}) {
  return (
    <div>
      <a href='/'>
        {' '}
        <img
          src={conf.images.logotext512}
          className='form-logo-header'
          alt='logo'
        />
      </a>
      <p className='mt-5 mb-3 form-login-title'>{formname}</p>
    </div>
  );
}
