// import { useState, useContext } from 'react';
// import GlobalContext from '../../context/GlobalContext';

const Correspondente = () => {
  return (
    <>
      <p>Logged Admin</p>
    </>
  );
};

export default Correspondente;
