import React, { useState } from 'react';
import { Badge, Dropdown } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaCircle } from 'react-icons/fa';

const StatusDropdown = ({
  status,
  stat,
  processo_etapa_id,
  onStatusChange,
}) => {
  const [statusColor, setStatusColor] = useState(status[stat - 1].CODIGO);
  const [statusName, setStatusName] = useState(status[stat - 1].NOME);

  const handleStatusSelect = (st) => {
    setStatusColor(status.find((item) => item.ID === st).CODIGO);
    setStatusName(status.find((item) => item.ID === st).NOME);
    onStatusChange(st);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant=''
        size='sm'
        id={`drop_status_${processo_etapa_id}`}
        className='drop-status'
      >
        <span
          variant={statusColor}
          size='sm'
          className='softxt f08 d-flex flex-row align-items-center '
        >
          <FaCircle className={`fc-${statusColor} svg12`} />
          <OverlayTrigger
            placement='top'
            id={`tt_drop_status_${processo_etapa_id}`}
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip>Status</Tooltip>}
          >
            <span className='mx-1'>{statusName}</span>
          </OverlayTrigger>
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu
        size='sm'
        className='drop-menu-out'
      >
        {status.map((st, index) => (
          <Dropdown.Item
            key={`drop_status_${processo_etapa_id}_${st.ID}_${index}`}
          >
            <Badge
              bg={st.CODIGO}
              key={`drop_status_${processo_etapa_id}_${st.ID}_${index}_bad`}
              onClick={() => handleStatusSelect(st.ID)}
            >
              {st.NOME}
            </Badge>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default StatusDropdown;
