import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { FaPen } from 'react-icons/fa';

import { FaWhatsapp } from 'react-icons/fa';
import { MdMailOutline } from 'react-icons/md';

function MensagemModal({ proc_etapa_id, show, setShow }) {
  const [enviar, setEnviar] = useState('whats');
  return (
    <>
      <Modal
        show={show}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        onHide={setShow}
      >
        <Modal.Header closeButton>
          <Modal.Title
            className={`softxt ${enviar === 'whats' ? 'c-green' : 'c-blue'}`}
          >
            <span
              className={`me-1 softxt ${
                enviar === 'whats' ? 'fc-green' : 'fc-blue'
              }`}
            >
              {enviar === 'whats' ? 'WhatsApp' : 'E-mail'}
            </span>
            <span
              className={`ms-1 ${enviar === 'whats' ? 'c-green' : 'c-blue'}`}
            >
              {enviar === 'whats' ? (
                <FaWhatsapp className='svg14' />
              ) : (
                <MdMailOutline className='svg14' />
              )}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex flex-row justify-content-end f08'>
            <Form.Check
              checked={enviar === 'mail'}
              className='ms-3 f08'
              type='radio'
              name='enviar'
              label={`E-mail`}
              id={`enviar_mail_${proc_etapa_id}`}
              onClick={() => setEnviar('mail')}
            />

            <Form.Check
              checked={enviar === 'whats'}
              className='ms-3 f08'
              type='radio'
              name='enviar'
              label={`WhatsApp`}
              id={`enviar_whats_${proc_etapa_id}`}
              onClick={() => setEnviar('whats')}
            />

            <span className='ms-1'></span>
            <span className='ms-1'></span>
            <span className='ms-1'></span>
          </div>
          <div>
            <Form.Group
              className='mb-3'
              id={`new_andamento_${proc_etapa_id}`}
            >
              <Form.Label>
                <span className='me-1'>
                  <FaPen />
                </span>
                <span className='f08'>Texto da Mensagem</span>
              </Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
              />
            </Form.Group>
          </div>
          {/* {proc_etapa_id} */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            size='sm'
            variant='outline-info'
          >
            Enviar{' '}
            {enviar === 'whats' ? (
              <FaWhatsapp className='svg14' />
            ) : (
              <MdMailOutline className='svg14' />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MensagemModal;
