import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/esm/Spinner';

import { BiTask } from 'react-icons/bi';
import {
  GlobalContext,
  PriorityDropdown,
  StatusDropdown,
  api,
  end,
} from '../../config/imports';

function TarefaModal({ proc_etapa_id, show, setShow, updated }) {
  const [prazo, setPrazo] = useState('');
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [stats, setStatus] = useState(1);
  const [andamento, setAndamento] = useState('');
  const [prioridade, setPrioridade] = useState(3);
  const { a, checkLogin } = useContext(GlobalContext);
  const { prioridades, status } = a;

  const handlePrioridade = (novaPrioridade) => {
    setPrioridade(novaPrioridade);
  };

  const handleStatus = (novoStatus) => {
    setStatus(novoStatus);
  };

  const handleSave = async () => {
    checkLogin();
    setLoading(true);
    try {
      setLoading(true);
      await api.post(end.and.create.task, {
        processo_etapa_id: proc_etapa_id,
        andamento_id: 3,
        prioridade_id: prioridade,
        status_id: stats,
        prazo: prazo,
        title: title,
        andamento: andamento,
      });
    } catch (error) {
      console.error(error);
    }
    setLoading(false);

    updated();
    setTimeout(() => {
      setLoading(false);
      setShow(false);
    }, 1000);
  };
  return (
    <>
      <Modal
        show={show}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        onHide={setShow}
      >
        <Modal.Header closeButton>
          <Modal.Title className='softxt'>To Do</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='align-items-center gocol991'>
            <Form.Control
              required
              type='text'
              id='title'
              value={title}
              placeholder='Título'
              className='form-control input-cur w-100 my-1'
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
            <div className='gocol500 align-items-center'>
              <div className='d-flex flex-row  align-items-center'>
                <span className='f08 mx-1'>Prazo</span>
                <Form.Control
                  type='date'
                  className='f08'
                  size='sm'
                  value={prazo}
                  id={`new_prazo_task_${proc_etapa_id}`}
                  onChange={(e) => {
                    setPrazo(e.target.value);
                  }}
                />
              </div>
              <PriorityDropdown
                prioridades={prioridades}
                prior={prioridade}
                processo_etapa_id={proc_etapa_id}
                onPrioridadeChange={handlePrioridade}
                showName={true}
              />
              <StatusDropdown
                status={status}
                stat={stats}
                processo_etapa_id={proc_etapa_id}
                onStatusChange={handleStatus}
              />
            </div>
          </div>
          <div>
            <Form.Label>
              <span className='me-1'>
                <BiTask className='fc-green' />
              </span>
              <span className='f08 softxt'>Task</span>
            </Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              value={andamento}
              id={`new_andamento_${proc_etapa_id}`}
              onChange={(e) => {
                setAndamento(e.target.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <Spinner
              animation='border'
              variant='success'
              size='sm'
            />
          ) : (
            <>
              <Button
                size='sm'
                variant='primary'
                onClick={handleSave}
              >
                Adicionar Tarefa
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TarefaModal;
