import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { IMaskInput } from 'react-imask';
import { Link } from 'react-router-dom';

import recover from '../services/recover';
import reset from '../services/reset';

import { FormHeader, Loading } from '../config/imports';

export default function Recover() {
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [inputType, setInputType] = useState('password');

  const [requested, setRequested] = useState(false);

  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');

  const reseter = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await reset(email, cpf, password, otp);
      const data = response.data;
      if (data.success) setRequested(false);
      setMessage(data.message);
    } catch (error) {
      setMessage(error.response.data.message);
    }

    setLoading(false);
  };

  const recovery = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await recover(email, cpf);
      const data = response.data;
      const success = data.success;
      setMessage(data.message);
      if (success) setRequested(true);
    } catch (error) {
      setMessage(error.response.data.message);
      setRequested(false);
    }
    setLoading(false);
  };

  return (
    <div className='wrapper'>
      <header className='form-header text-center my-5'>
        <FormHeader formname={'Recupere seu acesso'} />
      </header>
      <form
        className='w60 mw600'
        action=''
      >
        <div className='w-100'>
          <Form.Label
            htmlFor='cpf'
            className='input-label'
          >
            CPF
          </Form.Label>
          <Form.Control
            required
            type='text'
            as={IMaskInput}
            mask='000.000.000-00'
            id='cpf'
            name='cpf'
            autoComplete='cpf'
            aria-describedby='cpf mask-cpf'
            onChange={({ target: { value } }) => setCpf(value)}
            className='mb-2'
          />

          <Form.Label
            htmlFor='email'
            className='input-label'
          >
            E-Mail
          </Form.Label>
          <Form.Control
            required
            type='text'
            id='email'
            name='email'
            autoComplete='email'
            aria-describedby='email'
            onChange={({ target: { value } }) => setEmail(value)}
            className='mb-2'
          />

          <p className='my-3 text-center fc-red fw-500'>{message}</p>

          {loading ? (
            <Loading class_op='mt-5' />
          ) : requested ? null : (
            <div className='d-flex flex-column align-items-center  justify-content-around my-2'>
              <Button
                className='mb-2'
                variant='primary'
                onClick={(event) => recovery(event)}
              >
                Recuperar
              </Button>
              <Link to='/login'>
                <Button
                  className='no_under'
                  variant='link'
                >
                  Lembrou a senha? Faça login
                </Button>
              </Link>
            </div>
          )}
        </div>

        {requested ? (
          <div className='w-100'>
            <p className='my-3 text-center fc-green fw-500'>
              O e-mail com o código de recuperação pode estar na caixa de spam,
              ou demorar alguns minutos para chegar.
            </p>
            <Form.Label
              htmlFor='otp'
              className='input-label'
            >
              Código OTP recebido por e-mail
            </Form.Label>
            <Form.Control
              required
              type='text'
              id='otp'
              name='otp'
              autoComplete='otp'
              aria-describedby='otp'
              onChange={({ target: { value } }) => setOtp(value)}
              className='mb-2'
            />

            <Form.Label
              htmlFor='password'
              className='input-label'
            >
              Nova Senha
            </Form.Label>
            <div className='d-flex flex-row align-items-start '>
              <Form.Control
                type={inputType}
                id='password'
                autoComplete='new-password'
                onChange={({ target: { value } }) => setPassword(value)}
              />
              {inputType === 'password' ? (
                <FaRegEye
                  className='ms-2 mt-2 svg14'
                  onClick={() => setInputType('text')}
                />
              ) : (
                <FaRegEyeSlash
                  className='ms-2 mt-2 svg14'
                  onClick={() => setInputType('password')}
                />
              )}
            </div>
            {requested ? (
              <div className='d-flex flex-column align-items-center justify-content-around my-5'>
                <Button
                  className='mb-2'
                  variant='primary'
                  onClick={(event) => reseter(event)}
                >
                  Redefinir minha senha
                </Button>
                <Link to='/login'>
                  <Button
                    className='no_under'
                    variant='link'
                  >
                    Lembrou a senha? Faça login
                  </Button>
                </Link>
              </div>
            ) : null}
          </div>
        ) : null}
      </form>
    </div>
  );
}
