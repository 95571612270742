import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import { IMaskInput } from 'react-imask';
import { useNavigate } from 'react-router-dom';

import {
  api,
  end,
  GlobalContext,
  helpers,
  IconsUI,
  SimulacaoModal,
  ui,
  utils,
} from '../../config/imports';

function ProcessoModal({ obj, on, changeActive, load, nobj }) {
  const { createSelectProp, curr, float } = utils;
  const { proc, user } = end;
  const { post } = api;
  const { mascaraMoeda } = helpers;

  const navigate = useNavigate();
  const { a } = useContext(GlobalContext);
  const {
    bancos,
    operacoes,
    tabelas,
    faixas,
    imobiliarias,
    corretores,
    correspondentes,
  } = a;

  const [processo, setProcesso] = useState();

  const [loadings, setLoadings] = useState({
    nome: false,
    cpf: false,
    email: false,
    whats: false,
    processo: false,
  });

  const [data, setData] = useState({
    user_codigo: obj.SIM_USER_CODIGO,
    nome: obj.proc.user.nome,
    cpf: obj.proc.user.cpf,
    email: obj.proc.user.email,
    telefone: obj.proc.user.whatsapp,
    correspondente_codigo: obj.SIM_CORRESPONDENTE_CODIGO || '',
    corretor_codigo: obj.SIM_CORRETOR_CODIGO || '',
    imobiliaria_codigo: obj.SIM_IMOBILIARIA_CODIGO || '',
    banco_id: obj.proc.fin.banco,
    operacao: obj.proc.fin.operacao,
    tabela: obj.proc.fin.tabela,
    linha: obj.proc.fin.linha,
    taxa: obj.proc.fin.taxa,
    headline: obj.proc.fin.headline || '',
    informacoes: obj.proc.fin.informacoes || '',
    beneficiado: obj.proc.perfil.beneficiado,
    servidor_publico: obj.proc.perfil.servidor_publico,
    anos_fgts: obj.proc.perfil.anos_fgts,
    dependentes: obj.proc.perfil.dependentes,
    clt: obj.proc.perfil.clt,
    possui_imovel: obj.proc.perfil.possui_imovel,
    possui_imovel_cidade: obj.proc.perfil.possui_imovel_cidade || '',
    valor_imovel: obj.proc.imovel.valor,
    valor_avaliacao: obj.proc.imovel.avaliacao,
    valor_entrada: obj.proc.resumo.entrada,
    valor_entrada_fgts: obj.proc.resumo.fgts,
    valor_entrada_recursos: obj.proc.resumo.recursos,
    valor_financiado: obj.proc.resumo.financiado,
    cidade_imovel: obj.proc.imovel.cidade,
    valor_renda: obj.proc.resumo.rendas,
    mais_velho: obj.proc.perfil.mais_velho,
    compartilhar_corretor: obj.proc.fin.compartilhar,
  });

  const [dataMask, setDataMask] = useState({
    valor_imovel: mascaraMoeda(parseFloat(obj.proc.imovel.valor).toFixed(2)),
    valor_avaliacao: mascaraMoeda(
      parseFloat(obj.proc.imovel.avaliacao).toFixed(2),
    ),
    valor_entrada: mascaraMoeda(parseFloat(obj.proc.resumo.entrada).toFixed(2)),
    valor_entrada_fgts: mascaraMoeda(
      parseFloat(obj.proc.resumo.fgts).toFixed(2),
    ),
    valor_entrada_recursos: mascaraMoeda(
      parseFloat(obj.proc.resumo.recursos).toFixed(2),
    ),
    valor_financiado: mascaraMoeda(
      parseFloat(obj.proc.resumo.financiado).toFixed(2),
    ),
    valor_renda: mascaraMoeda(parseFloat(obj.proc.resumo.rendas).toFixed(2)),
  });

  useEffect(() => {
    bancos && createSelectProp(bancos, 'proc-banco', 'NOME', true, 'ID');
    operacoes && createSelectProp(operacoes, 'proc-operacao', 'NOME');
    tabelas && createSelectProp(tabelas, 'proc-tabela', 'NOME');
    faixas && createSelectProp(faixas, 'proc-linha', 'NOME');
    faixas && createSelectProp(faixas, 'proc-taxa', 'VALUE');

    imobiliarias &&
      createSelectProp(
        imobiliarias,
        'proc-imobiliaria',
        'NOME',
        true,
        'CODIGO',
      );
    corretores &&
      createSelectProp(corretores, 'proc-corretor', 'NOME', true, 'COD');
    correspondentes &&
      createSelectProp(
        correspondentes,
        'proc-correspondente',
        'NOME',
        true,
        'CODIGO',
      );
  }, [
    bancos,
    operacoes,
    tabelas,
    faixas,
    imobiliarias,
    corretores,
    correspondentes,
    createSelectProp,
  ]);

  const handleFilterCorretores = () => {
    let imobiliaria_cod = document.getElementById('proc-imobiliaria').value;
    let correts =
      corretores && corretores.filter((c) => c.CODIGO === imobiliaria_cod);
    correts && createSelectProp(correts, 'proc-corretor', 'NOME', false, 'COD');
  };

  const handleValor = (event) => {
    let entrada;
    let financiado;
    if (event.target.name === 'valor_entrada_recursos') {
      entrada = float(event.target.value) + float(data.valor_entrada_fgts);
      financiado = float(data.valor_imovel) - entrada;
    } else if (event.target.name === 'valor_entrada_fgts') {
      entrada = float(data.valor_entrada_recursos) + float(event.target.value);
      financiado = float(data.valor_imovel) - entrada;
    } else if (event.target.name === 'valor_imovel') {
      entrada =
        float(data.valor_entrada_recursos) + float(data.valor_entrada_fgts);
      financiado = float(event.target.value) - entrada;
    } else {
      entrada =
        float(data.valor_entrada_recursos) + float(data.valor_entrada_fgts);
      financiado = float(data.valor_imovel) - entrada;
    }
    setData({
      ...data,
      valor_entrada: entrada,
      valor_financiado: financiado,
      [event.target.name]: event.target.value,
    });
    setDataMask({
      ...dataMask,
      valor_entrada: mascaraMoeda(entrada),
      valor_financiado: mascaraMoeda(financiado),
      [event.target.name]: mascaraMoeda(event.target.value),
    });
  };

  const handleLoadings = (field, option = false) => {
    setLoadings({
      ...loadings,
      [field]: option,
    });
  };

  const handleSetData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSetDataChecked = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.checked,
    });
  };

  const handleSave = async (event, field, endpt) => {
    event.preventDefault();
    let codigo = obj.SIM_USER_CODIGO;
    let value = data[field];
    try {
      handleLoadings(field, true);
      await post(endpt, {
        codigo,
        [field]: value,
      });
      if (field === 'telefone') {
        obj.proc.user.whatsapp = value;
      } else {
        obj.proc.user[field] = value;
      }
      nobj(obj);
      handleLoadings(field, false);
    } catch (error) {
      console.error(error);
    }
    handleLoadings(field, false);
  };

  const handleSaveNome = (event) => handleSave(event, 'nome', user.update.nome);
  const handleSaveCpf = (event) => handleSave(event, 'cpf', user.update.cpf);
  const handleSaveEmail = (event) =>
    handleSave(event, 'email', user.update.email);
  const handleSaveTelefone = (event) =>
    handleSave(event, 'telefone', user.update.telefone);

  const handleCreateProcesso = async (event) => {
    event.preventDefault();

    let user_codigo = obj.SIM_USER_CODIGO;
    let simulacao_codigo = obj.SIM_CODIGO;

    // setData({
    //   ...data,
    //   correspondente_codigo: document.getElementById('proc-correspondente')
    //     .value,
    //   corretor_codigo: document.getElementById('proc-corretor').value,
    //   imobiliaria_codigo: document.getElementById('proc-imobiliaria').value,
    //   banco_id: document.getElementById('proc-banco').value,
    //   operacao: document.getElementById('proc-operacao').value,
    //   tabela: document.getElementById('proc-tabela').value,
    //   linha: document.getElementById('proc-linha').value,
    //   taxa: document.getElementById('proc-taxa').value,
    //   cidade_imovel: document.getElementById('proc-imovel_cidade').value,
    //   headline: document.getElementById('proc-headline').value,
    //   informacoes: document.getElementById('proc-informacoes').value,
    //   beneficiado: document.getElementById('proc-beneficiado').checked,
    //   servidor_publico: document.getElementById('proc-servidor_publico')
    //     .checked,
    //   anos_fgts: document.getElementById('proc-anos_fgts').checked,
    //   dependentes: document.getElementById('proc-dependentes').checked,
    //   clt: document.getElementById('proc-clt').checked,
    //   possui_imovel: document.getElementById('proc-possui_imovel').checked,

    //   valor_imovel: float(document.getElementById('proc-valor_imovel').value),
    //   valor_avaliacao: float(
    //     document.getElementById('proc-valor_avaliacao').value,
    //   ),
    //   valor_entrada:
    //     float(data.valor_entrada_recursos) + float(data.valor_entrada_fgts),
    //   valor_financiado:
    //     float(data.valor_imovel) -
    //     (float(data.valor_entrada_recursos) + float(data.valor_entrada_fgts)),
    // });

    try {
      setLoadings({
        ...loadings,
        processo: true,
      });
      const response = await post(proc.create, {
        user_codigo,
        simulacao_codigo,
        correspondente_codigo: data.correspondente_codigo,
        corretor_codigo: data.corretor_codigo,
        imobiliaria_codigo: data.imobiliaria_codigo,
        banco_id: data.banco_id,
        operacao: data.operacao,
        tabela: data.tabela,
        linha: data.linha,
        taxa: data.taxa,
        cidade_imovel: data.cidade_imovel,
        headline: data.headline,
        informacoes: data.informacoes,
        beneficiado: data.beneficiado,
        servidor_publico: data.servidor_publico,
        anos_fgts: data.anos_fgts,
        dependentes: data.dependentes,
        clt: data.clt,
        possui_imovel: data.possui_imovel,
        possui_imovel_cidade: data.possui_imovel_cidade,
        valor_imovel: data.valor_imovel,
        valor_avaliacao: data.valor_avaliacao,
        valor_entrada: data.valor_entrada,
        valor_entrada_fgts: data.valor_entrada_fgts,
        valor_entrada_recursos: data.valor_entrada_recursos,
        valor_financiado: data.valor_financiado,
        valor_renda: data.valor_renda,
        mais_velho: data.mais_velho,
        compartilhar_corretor: data.compartilhar_corretor,
      });

      const dt = response.data;
      // if (error) {
      //   console.error(error_message);
      //   Swal.fire({
      //     title: 'Ooops!',
      //     text: error_message,
      //     icon: 'error',
      //     confirmButtonText: 'Ok',
      //   });
      //   return;
      // } else {
      //   Swal.fire({
      //     title: 'Uhul!!',
      //     text: 'Deu Certo! Aguarde mais um pouquinho...',
      //     icon: 'success',
      //     confirmButtonText: 'Cool',
      //   });
      // }
      const processo_codigo = dt.data;
      setProcesso(processo_codigo);
    } catch (error) {
      console.error(error);
    }

    setLoadings({
      ...loadings,
      processo: false,
    });
  };

  if (processo) navigate(`/processo/${processo}`);

  return (
    <>
      <p className='mt-2'>Resumo da Simulação</p>
      <SimulacaoModal
        obj={obj}
        on={on}
        changeActive={changeActive}
        load={load}
      />
      <hr />
      <p className='mt-3'>Criar novo Processo</p>

      <div className='gorow justify-content-around  gocol995'>
        <div className='p-2 d-flex flex-column col-6 col12-995'>
          <div className='gorow align-content-center align-items-center mb-1'>
            <span className='me-1 wp-100 softxt txt-300 f08'>Nome</span>
            <span className='wp-200 wp300-995 gorow align-items-center'>
              <Form.Control
                required
                type='text'
                id='proc-user_nome'
                name='nome'
                value={data.nome}
                onChange={(e) => handleSetData(e)}
                className='input-cur'
              />
              <span className='ms-1 wp-40 d-flex flex-row justify-content-between hov-green'>
                {loadings.nome ? (
                  <Spinner
                    animation='border'
                    variant='success'
                    size='sm'
                  />
                ) : (
                  <IconsUI
                    info={ui.action.save}
                    click={handleSaveNome}
                  />
                )}
                <IconsUI
                  info={ui.action.close}
                  click={() =>
                    (document.getElementById('proc-user_nome').value =
                      obj.proc.user.nome)
                  }
                />
              </span>
            </span>
          </div>

          <div className='gorow align-content-center align-items-center mb-1'>
            <span className='me-1 wp-100 softxt txt-300 f08'>CPF</span>
            <span className='wp-200 wp300-995 gorow align-items-center'>
              <Form.Control
                required
                type='text'
                as={IMaskInput}
                mask='000.000.000-00'
                id='proc-user_cpf'
                name='cpf'
                value={data.cpf}
                onChange={(e) => handleSetData(e)}
                className='input-cur'
              />
              <span className='ms-1 wp-40 d-flex flex-row justify-content-between hov-green'>
                {loadings.cpf ? (
                  <Spinner
                    animation='border'
                    variant='success'
                    size='sm'
                  />
                ) : (
                  <IconsUI
                    info={ui.action.save}
                    click={handleSaveCpf}
                  />
                )}

                <IconsUI
                  info={ui.action.close}
                  click={() =>
                    (document.getElementById('proc-user_cpf').value =
                      obj.proc.user.cpf)
                  }
                />
              </span>
            </span>
          </div>
        </div>
        <div className='p-2 d-flex flex-column col-6 col12-995'>
          <div className='gorow align-content-center align-items-center mb-1'>
            <span className='me-1 wp-100 softxt txt-300 f08'>Whatsapp</span>
            <span className='wp-200 wp300-995 gorow align-items-center'>
              <Form.Control
                as={IMaskInput}
                mask='(00) 00000-0000'
                required
                type='text'
                id='proc-user_telefone'
                name='telefone'
                value={data.telefone}
                onChange={(e) => handleSetData(e)}
                className='input-cur'
              />
              <span className='ms-1 wp-40 d-flex flex-row justify-content-between hov-green'>
                {loadings.whats ? (
                  <Spinner
                    animation='border'
                    variant='success'
                    size='sm'
                  />
                ) : (
                  <IconsUI
                    info={ui.action.save}
                    click={handleSaveTelefone}
                  />
                )}

                <IconsUI
                  info={ui.action.close}
                  click={() =>
                    (document.getElementById('proc-user_telefone').value =
                      obj.proc.user.whatsapp)
                  }
                />
              </span>
            </span>
          </div>

          <div className='gorow align-content-center align-items-center mb-1'>
            <span className='me-1 wp-100 softxt txt-300 f08'>E-mail</span>
            <span className='wp-200 wp300-995 gorow align-items-center'>
              <Form.Control
                required
                type='text'
                id='proc-user_email'
                value={data.email}
                name='email'
                onChange={(e) => handleSetData(e)}
                className='input-cur'
              />
              <span className='ms-1 wp-40 d-flex flex-row justify-content-between hov-green'>
                {loadings.email ? (
                  <Spinner
                    animation='border'
                    variant='success'
                    size='sm'
                  />
                ) : (
                  <IconsUI
                    info={ui.action.save}
                    click={handleSaveEmail}
                  />
                )}

                <IconsUI
                  info={ui.action.close}
                  click={() =>
                    (document.getElementById('proc-user_email').value =
                      obj.proc.user.email)
                  }
                />
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className='gorow justify-content-around gocol995'>
        <div className='p-2 d-flex flex-column col-6 col12-995'>
          <p className='mt-3 mb-0 hc-cp-title hc-blue'>Imóvel</p>

          <div className='gorow align-content-center align-items-center mb-1'>
            <span className='me-1 wp-100 softxt txt-300 f08'>
              Valor de Compra
            </span>
            <span className='wp-150 gorow align-items-center'>
              <Form.Control
                step={'0.1'}
                id='proc-valor_imovel'
                name='valor_imovel'
                type='text'
                required
                value={dataMask.valor_imovel}
                onChange={(e) => handleValor(e)}
                onKeyUp={(e) => handleValor(e)}
              />
            </span>
          </div>

          <div className='gorow align-content-center align-items-center mb-1'>
            <span className='me-1 wp-100 softxt txt-300 f08'>
              Valor de Avaliação
            </span>
            <span className='wp-150 gorow align-items-center'>
              <Form.Control
                step={'0.1'}
                id='proc-valor_avaliacao'
                name='valor_avaliacao'
                value={dataMask.valor_avaliacao}
                type='text'
                required
                onChange={(e) => handleValor(e)}
                onKeyUp={(e) => handleValor(e)}
              />
            </span>
          </div>

          <div className='gorow align-content-center align-items-center mb-1'>
            <span className='me-1 wp-100 softxt txt-300 f08'>Cidade</span>
            <span className='wp-150 gorow align-items-center'>
              <Form.Control
                required
                type='text'
                id='proc-imovel_cidade'
                name='imovel_cidade'
                className='input-cur'
                value={data.cidade_imovel}
                onChange={(e) => handleSetData(e)}
              />
            </span>
          </div>

          <p className='mt-3 mb-0 hc-cp-title hc-blue'>Entrada</p>

          <div className='gorow align-content-center align-items-center mb-1'>
            <span className='me-1 wp-100 softxt txt-300 f08'>FGTS</span>
            <span className='wp-150 gorow align-items-center'>
              <Form.Control
                step={'0.1'}
                id='proc-valor_fgts'
                name='valor_entrada_fgts'
                type='text'
                required
                value={dataMask.valor_entrada_fgts}
                onChange={(e) => handleValor(e)}
                onKeyUp={(e) => handleValor(e)}
              />
            </span>
          </div>

          <div className='gorow align-content-center align-items-center mb-1'>
            <span className='me-1 wp-100 softxt txt-300 f08'>
              Recursos Próprios
            </span>

            <span className='wp-150 gorow align-items-center'>
              <Form.Control
                step={'0.1'}
                id='proc-valor_recursos'
                name='valor_entrada_recursos'
                value={dataMask.valor_entrada_recursos}
                type='text'
                required
                onChange={(e) => handleValor(e)}
                onKeyUp={(e) => handleValor(e)}
              />
            </span>
          </div>
          <div className='gorow align-content-center align-items-center mb-1'>
            <span className='me-1 wp-100 softxt txt-500'>Total</span>
            <span
              className='wp-150 gorow align-items-center'
              id='proc-valor_entrada'
            >
              {curr(data.valor_entrada)}
            </span>
          </div>
          <span
            id='proc-entrada-percent'
            className='f08 mb-1 mt-0'
          ></span>

          <p className='mt-3 mb-0 hc-cp-title hc-blue'>Financiamento</p>

          <div className='gorow align-content-center align-items-center mb-1'>
            <span className='me-1 wp-100 softxt txt-300 f08'>Financiado</span>
            <span
              className='wp-150 gorow align-items-center'
              id='proc-valor_financiado'
            >
              {curr(data.valor_financiado)}
            </span>
          </div>
          <div className='gorow align-content-center align-items-center mb-1'>
            <span className='me-1 wp-100 softxt txt-300 f08'>Renda</span>
            <span className='wp-150 gorow align-items-center'>
              <Form.Control
                step={'0.1'}
                id='proc-valor_renda'
                name='proc-valor_renda'
                type='text'
                required
                value={dataMask.valor_renda}
                onChange={(e) => handleValor(e)}
                onKeyUp={(e) => handleValor(e)}
              />
            </span>
          </div>
          <div className='gorow align-content-center align-items-center mb-1'>
            <span className='me-1 wp-100 softxt txt-300 f08'>Mais Velho</span>
            <span className='wp-150 gorow align-items-center'>
              <Form.Control
                required
                type='text'
                value={data.mais_velho}
                id='proc-idade_velho'
                name='mais_velho'
                className='input-cur'
                onChange={(e) => handleSetData(e)}
              />
            </span>
          </div>

          <p className='mt-3 mb-0 hc-cp-title hc-blue'>Detalhes</p>

          <div className='gorow align-content-center align-items-center mb-1'>
            <span className='me-1 wp-100 softxt txt-300 f08'>Banco</span>
            <span className='wp-200 wp300-995 gorow align-items-center'>
              <select
                id='proc-banco'
                name='banco_id'
                value={data.banco_id}
                className='form-control input-cur'
                onChange={(e) => handleSetData(e)}
              ></select>
            </span>
          </div>

          <div className='gorow align-content-center align-items-center mb-1'>
            <span className='me-1 wp-100 softxt txt-300 f08'>Operação</span>
            <span className='wp-200 wp300-995 gorow align-items-center'>
              <select
                name='operacao'
                id='proc-operacao'
                value={data.operacao}
                className='form-control input-cur'
                onChange={(e) => handleSetData(e)}
              ></select>
            </span>
          </div>

          <div className='gorow align-content-center align-items-center mb-1'>
            <span className='me-1 wp-100 softxt txt-300 f08'>Tabela</span>
            <span className='wp-200 wp300-995 gorow align-items-center'>
              <select
                name='tabela'
                id='proc-tabela'
                value={data.tabela}
                className='form-control input-cur'
                onChange={(e) => handleSetData(e)}
              ></select>
            </span>
          </div>

          <div className='gorow align-content-center align-items-center mb-1'>
            <span className='me-1 wp-100 softxt txt-300 f08'>Linha</span>
            <span className='wp-200 wp300-995 gorow align-items-center'>
              <select
                id='proc-linha'
                name='linha'
                value={data.linha}
                className='form-control input-cur'
                onChange={(e) => {
                  handleSetData(e);
                  const tx = faixas.find((f) => f.NOME === e.target.value);
                  setData({
                    ...data,
                    taxa: tx.VALUE,
                  });
                }}
              ></select>
            </span>
          </div>

          <div className='gorow align-content-center align-items-center mb-1'>
            <span className='me-1 wp-100 softxt txt-300 f08'>Taxa</span>
            <span className='wp-200 wp300-995 gorow align-items-center'>
              <select
                name='taxa'
                id='proc-taxa'
                value={data.taxa}
                className='form-control input-cur wp-60 text-center'
                onChange={(e) => handleSetData(e)}
              ></select>
              <span className='ms-1 f08'>%</span>
            </span>
          </div>

          <div className='gorow align-content-center align-items-center mb-1'></div>
        </div>

        <div className='p-2 d-flex flex-column col-6 col12-995'>
          <p className='mt-3 mb-1 hc-cp-title hc-blue'>Variáveis</p>

          <div className='gorow align-items-center mb-1'>
            <span className='wp-250 gorow align-items-center'>
              <Form.Check
                type='checkbox'
                onChange={(e) => handleSetDataChecked(e)}
                id='proc-beneficiado'
                name='beneficiado'
                checked={data.beneficiado}
                label='Já foi Beneficiado MCMV'
                className='softxt txt-300 f08'
              />
            </span>
          </div>
          <div className='gocol align-content-center align-items-center mb-1'>
            <div className='gorow w100 align-items-center mb-0'>
              <span className='wp-250 gorow align-items-center'>
                <Form.Check
                  type='checkbox'
                  id='proc-possui_imovel'
                  name='possui_imovel'
                  onChange={(e) => handleSetDataChecked(e)}
                  checked={data.possui_imovel}
                  label='Possui Imóvel Registrado'
                  className='softxt txt-300 f08'
                />
              </span>
            </div>

            {data.possui_imovel ? (
              <div className='d-flex flex-column w100'>
                <span className='f08'>
                  Informe a cidade em que possui o imóvel
                </span>
                <Form.Control
                  required
                  type='text'
                  id='proc-possui_imovel_cidade'
                  name='possui_imovel_cidade'
                  value={data.possui_imovel_cidade}
                  placeholder='Cidade'
                  className='form-control  input-cur w100'
                  onChange={(e) => handleSetData(e)}
                />
              </div>
            ) : null}
          </div>

          <div className='gorow align-content-center align-items-center mb-1'>
            <span className='wp-150 gorow align-items-center'>
              <Form.Check
                type='checkbox'
                id='proc-servidor_publico'
                name='servidor_publico'
                onChange={(e) => handleSetDataChecked(e)}
                checked={data.servidor_publico}
                label='Servidor Público'
                className='softxt txt-300 f08'
              />
            </span>
          </div>

          <div className='gorow align-content-center align-items-center mb-1'>
            <span className='wp-150 gorow align-items-center'>
              <Form.Check
                type='checkbox'
                id='proc-anos_fgts'
                name='anos_fgts'
                checked={data.anos_fgts}
                onChange={(e) => handleSetDataChecked(e)}
                label='Possui 3 anos de FGTS'
                className='softxt txt-300 f08'
              />
            </span>
          </div>

          <div className='gorow align-content-center align-items-center mb-1'>
            <span className='wp-150 gorow align-items-center'>
              <Form.Check
                type='checkbox'
                id='proc-dependentes'
                name='dependentes'
                onChange={(e) => handleSetDataChecked(e)}
                checked={data.dependentes}
                label='Possui Dependentes'
                className='softxt txt-300 f08'
              />
            </span>
          </div>

          <div className='gorow align-content-center align-items-center mb-1'>
            <span className='wp-150 gorow align-items-center'>
              <Form.Check
                type='checkbox'
                id='proc-clt'
                name='clt'
                checked={data.clt}
                onChange={(e) => handleSetDataChecked(e)}
                label='Atualmente registrado CLT'
                className='softxt txt-300 f08'
              />
            </span>
          </div>

          <div className='my-2'>
            <div className='gorow align-content-center align-items-center mb-1'>
              <span className='me-1 wp-100 softxt txt-300 f08'>
                Correspondente
              </span>
              <span className='wp-200 wp300-995 gorow align-items-center'>
                <select
                  id='proc-correspondente'
                  name='correspondente_codigo'
                  value={data.correspondente_codigo}
                  className='form-control input-cur'
                  onChange={(e) => handleSetData(e)}
                ></select>
              </span>
            </div>

            <div className='gorow align-content-center align-items-center mb-1'>
              <span className='me-1 wp-100 softxt txt-300 f08'>
                Imobiliária
              </span>
              <span className='wp-200 wp300-995 gorow align-items-center'>
                <select
                  id='proc-imobiliaria'
                  name='imobiliaria_codigo'
                  value={data.imobiliaria_codigo}
                  className='form-control input-cur'
                  onChange={(e) => {
                    handleSetData(e);
                    handleFilterCorretores();
                  }}
                ></select>
              </span>
            </div>

            <div className='gorow align-content-center align-items-center mb-1'>
              <span className='me-1 wp-100 softxt txt-300 f08'>Corretor</span>
              <span className='wp-200 wp300-995 gorow align-items-center'>
                <select
                  name='corretor_codigo'
                  id='proc-corretor'
                  value={data.corretor_codigo}
                  className='form-control input-cur'
                  onChange={(e) => handleSetData(e)}
                ></select>
              </span>
            </div>

            <div className='gorow align-content-center align-items-center mb-1'>
              <span className='wp-200 wp300-995 gorow align-items-center'>
                <Form.Check
                  type='checkbox'
                  id='proc-compartilhar'
                  name='compartilhar_corretor'
                  checked={data.compartilhar_corretor}
                  onChange={(e) => handleSetDataChecked(e)}
                  label='Compartilhar Informações com Corretor'
                  className='softxt txt-300 f08'
                />
              </span>
            </div>

            <div className='gorow align-content-center align-items-center mb-1'>
              <span className='me-1 wp-100 softxt txt-300 f08'>
                Outras Informações
              </span>
              <span className='wp-200 wp300-995 gorow align-items-center'>
                <Form.Control
                  required
                  as='textarea'
                  rows={4}
                  id='proc-informacoes'
                  name='informacoes'
                  className='form-control input-cur'
                  onChange={(e) => handleSetData(e)}
                  value={data.informacoes}
                />
              </span>
            </div>

            <div className='gorow align-content-center align-items-center mb-1'>
              <span className='me-1 wp-100 softxt txt-300 f08'>
                Breve Descrição
              </span>
              <span className='wp-200 wp300-995 gorow align-items-center'>
                <Form.Control
                  required
                  as='textarea'
                  rows={2}
                  id='proc-headline'
                  name='headline'
                  value={data.headline}
                  className='form-control input-cur'
                  onChange={(e) => handleSetData(e)}
                />
              </span>
            </div>
          </div>

          {loadings.processo ? (
            <Spinner
              animation='border'
              variant='success'
              size='sm'
            />
          ) : (
            <Button
              variant='primary'
              size='sm'
              onClick={handleCreateProcesso}
            >
              Criar Processo
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

ProcessoModal.propTypes = {
  obj: PropTypes.object.isRequired,
};

export default ProcessoModal;
